import ReviewPlaceholderImage from '@app/assets/icons/user.svg?react';
import { truncate } from 'lodash';
import { BaseModal } from '../BaseModal';
import { useState } from 'react';

interface ReviewCardProps {
  id?: string;
  review: string;
  fullName: string;
  rating: string;
  date: string;
  room?: string;
  disableShowMore?: boolean;
  disablePadding?: boolean;
  disableTruncate?: boolean;
}

export const ReviewCard = ({
  review,
  fullName,
  rating,
  date,
  room,
  disableShowMore,
  disablePadding,
  disableTruncate,
}: ReviewCardProps) => {
  const [viewFull, setViewFull] = useState(false);
  return (
    <>
      <div className={`bg-white rounded-2xl p-6 space-y-4 ${disablePadding ? '!p-0' : ''}`}>
        <div className="flex items-center gap-4 justify-between">
          <div className="rounded-full overflow-hidden relative w-[72px] h-[72px]">
            <ReviewPlaceholderImage width={72} height={72} className="absolute inset-0 object-cover object-center" />
          </div>
          <div className="mr-auto flex flex-col">
            <span className="font-bold text-2xl">{fullName}</span>
            <span className="text-dorrus-text-secondary ">
              {date} {room && room}
            </span>
          </div>
          <div className="bg-dorrus-background-secondary rounded-xl h-10 w-10 flex items-center justify-center text-white">
            {rating}
          </div>
        </div>
        <div>
          <p style={{ whiteSpace: 'pre-line' }}>{truncate(review, { length: disableTruncate ? 999 : 240 })}</p>
        </div>
        <div>
          {!disableShowMore && review.length > 240 && (
            <button className="font-bold" onClick={() => setViewFull(!viewFull)}>
              Show more
            </button>
          )}
        </div>
      </div>
      {!disableShowMore && (
        <BaseModal open={viewFull} onClose={() => setViewFull(!viewFull)}>
          <div className="space-y-4 text-left">
            <div className="flex items-center gap-4 justify-between">
              <div className="rounded-full overflow-hidden relative w-[72px] h-[72px]">
                <ReviewPlaceholderImage
                  width={72}
                  height={72}
                  className="absolute inset-0 object-cover object-center"
                />
              </div>
              <div className="mr-auto flex flex-col">
                <span className="font-bold text-2xl">{fullName}</span>
                <span className="text-dorrus-text-secondary ">
                  {date} {room && room}
                </span>
              </div>
              <div className="bg-dorrus-background-secondary rounded-xl h-10 w-10 flex items-center justify-center text-white">
                {rating}
              </div>
            </div>
            <div>
              <p className="text-justify">{review}</p>
            </div>
          </div>
        </BaseModal>
      )}
    </>
  );
};
