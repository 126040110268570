import { ButtonTypes } from './Button.interfaces';

export const getButtonClassesByType = (type?: ButtonTypes, disabled?: boolean) => {
  let buttonClass;
  switch (type) {
    case 'primary':
      buttonClass =
        'border border-dorrus-button-border text-dorrus-text-primary bg-dorrus-background-primary rounded-full w-full px-4 py-3 font-light';
      break;
    case 'secondary':
      buttonClass =
        'border border-dorrus-button-border bg-dorrus-background-secondary text-white rounded-full w-full px-4 py-3 font-light';
      break;
    case 'tertiary':
      buttonClass = 'bg-white rounded-full px-4 py-3 font-light';
      break;
    case 'pill':
      buttonClass =
        'border border-dorrus-border-primary rounded-full w-10 h-10 font-bold flex items-center justify-center text-dorrus-text-secondary';
      break;
    default:
      buttonClass =
        'border border-dorrus-button-border bg-transparent text-dorrus-text-primary rounded-full w-full px-4 py-3 font-light';
      break;
  }

  if (disabled) {
    buttonClass = `${buttonClass} `;
  }
  return buttonClass;
};

export const addDisabledClass = (disabled?: boolean) => {
  if (disabled) return 'opacity-80 cursor-not-allowed';
  return '';
};

export const loadingByButtonClass = (type?: ButtonTypes) => {
  switch (type) {
    case 'primary':
      return 'primary';
    case 'secondary':
      return 'secondary';
    default:
      return 'primary';
  }
};
