import * as React from "react";
const SvgBabysitting = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", width: "800px", height: "800px", viewBox: "0 0 32 32", id: "svg5", ...props }, /* @__PURE__ */ React.createElement("defs", { id: "defs2" }), /* @__PURE__ */ React.createElement("g", { id: "layer1", transform: "translate(-60,-100)" }, /* @__PURE__ */ React.createElement("path", { d: "m 86,122 c -2.197289,0 -4,1.80271 -4,4 0,2.19729 1.802711,4 4,4 2.197289,0 4,-1.80271 4,-4 0,-2.19729 -1.802711,-4 -4,-4 z m 0,2 c 1.116416,0 2,0.88358 2,2 0,1.11642 -0.883584,2 -2,2 -1.116416,0 -2,-0.88358 -2,-2 0,-1.11642 0.883584,-2 2,-2 z", id: "path6726", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "m 66,122 c -2.197289,0 -4,1.80271 -4,4 0,2.19729 1.802711,4 4,4 2.197289,0 4,-1.80271 4,-4 0,-2.19729 -1.802711,-4 -4,-4 z m 0,2 c 1.116416,0 2,0.88358 2,2 0,1.11642 -0.883584,2 -2,2 -1.116416,0 -2,-0.88358 -2,-2 0,-1.11642 0.883584,-2 2,-2 z", id: "circle6728", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "m 63,102 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3.53125 l 16.755859,20.10547 a 1,1 0 0 0 1.408203,0.12891 1,1 0 0 0 0.126954,-1.40821 L 67.767578,102.35938 A 1.0001,1.0001 0 0 0 67,102 Z", id: "path6747", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "m 68,118 a 1,1 0 0 0 -0.707031,0.29297 l -2,2 A 1.0001,1.0001 0 0 0 65,121 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -1.58594 l 1.707031,-1.70703 a 1,1 0 0 0 0,-1.41406 A 1,1 0 0 0 68,118 Z", id: "path6755", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "m 76,102 c -2.942218,0 -5.5682,1.41824 -7.208984,3.60938 a 1,1 0 0 0 0.201171,1.40039 1,1 0 0 0 1.400391,-0.20118 C 71.669967,105.10275 73.699116,104 76,104 c 2.821144,0 5.183876,1.69537 6.294922,4.08594 l -6.001953,6.00195 a 1,1 0 0 0 0,1.41406 1,1 0 0 0 1.414062,0 l 6.523438,-6.52343 a 1.0001,1.0001 0 0 0 0.232422,-1.04883 C 83.206887,104.46762 79.883314,102 76,102 Z", id: "path6757", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} }), /* @__PURE__ */ React.createElement("path", { d: "m 68,104 a 1,1 0 0 0 -1,1 v 12 c 0,3.30186 2.698138,6 6,6 h 9.519531 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 H 73 c -2.220981,0 -4,-1.77902 -4,-4 v -12 a 1,1 0 0 0 -1,-1 z", id: "path6749", style: {
  color: "#000000",
  fill: "#000000",
  fillRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 4.1,
  InkscapeStroke: "none"
} })));
export default SvgBabysitting;
