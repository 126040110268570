import Logo from '@app/assets/dorrus-logo.svg?react';
import { getCurrentYear } from '@app/utils/utils';
import { Link } from 'react-router-dom';

export const Footer = () => {
  return (
    <footer className="mt-10 bg-white rounded-2xl p-6 min-h-96 flex items-center print:hidden">
      <div className="grid grid-cols-1 lg:grid-cols-2 w-full h-full gap-6">
        <div className="flex justify-between flex-col  ">
          <div className="flex gap-4 flex-col">
            <Logo />
            <p className="font-bold text-2xl">Emotions that you&apos;ll want to share.</p>
            <div>
              <p>Registered in the United States</p>
              <p>©{getCurrentYear()} Dorrus™, Inc. All rights reserved.</p>
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-6 justify-evenly">
          <ul>
            <li className="mb-4">
              <h6 className="font-bold">COMPANY</h6>
            </li>
            <li className="mb-2">About Us</li>
            <li className="mb-2">
              <Link to="/contact-us">Contact Us</Link>
            </li>
          </ul>
          <ul>
            <li className="mb-4">
              <h6 className="font-bold">TERMS & PRIVACY</h6>
            </li>
            <li className="mb-2">
              <Link to="/legal/terms-and-conditions">Our Terms and Conditions</Link>
            </li>
            <li className="mb-2">
              <Link to="/legal/privacy-policy">Our Privacy Policy</Link>
            </li>
            <li className="mb-2">
              <Link to="/legal/privacy-policy#cookies">Our Cookie Policy</Link>
            </li>
          </ul>
          <ul>
            <li className="mb-4">
              <h6 className="font-bold">FOLLOW US</h6>
            </li>
            <li className="mb-2">
              <Link to="https://twitter.com/DorrusBeyond" target="_blank">
                Twitter / X
              </Link>
            </li>
            <li className="mb-2">
              <Link to="https://www.instagram.com/DorrusBeyond" target="_blank">
                Instagram
              </Link>
            </li>
            <li className="mb-2">
              <Link to="https://www.facebook.com/dorrusbeyond" target="_blank">
                Facebook
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};
