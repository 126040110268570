import { useUser } from '@app/context/user';
import { LodgingBookingInterface, OrderInterface, ServiceInterface } from '@app/models/booking';
import { useEffect, useState } from 'react';

export const useBookingCard = (order: OrderInterface) => {
  const [lodgingInformation, setLodgingInformation] = useState<LodgingBookingInterface>();
  const [servicesInformation, setServicesInformation] = useState<ServiceInterface[]>([]);
  const { getPriceFormat } = useUser();

  useEffect(() => {
    if (order?.orderItems && order.orderItems.length > 0) {
      const lodgingBookings = order.orderItems[0].lodgingBookings;
      const services = order.orderItems[0].services;
      if (lodgingBookings) setLodgingInformation(lodgingBookings[0]);
      if (services) setServicesInformation(services);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { servicesInformation, lodgingInformation, getPriceFormat };
};
