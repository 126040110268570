import { useContext } from 'react';

import { BookingsStateContext } from './utils';

const useBooking = () => {
  const context = useContext(BookingsStateContext);
  if (context === undefined) {
    throw new Error('useBooking must be used within an BookingProvider');
  }
  return context;
};

export { useBooking };

export { BookingProvider } from './booking';
