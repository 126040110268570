import { useUser } from '@app/context/user';
import { HotelBookingInterface } from '@app/models/orders';
import { getBookingById } from '@app/services/booking';
import { FeedbackInterface, postFeedback } from '@app/services/feedback';
import { feedbackValidation } from '@app/utils/validations';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useBookingReview = () => {
  const navigate = useNavigate();
  const formHook = useForm({ resolver: feedbackValidation });
  const [successModal, setSuccessModal] = useState(false);
  const { user } = useUser();
  const { id, uuid } = useParams();
  const {
    data: bookingDetails,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ['hotel-details'],
    queryFn: () =>
      getBookingById(id as string, uuid)
        .then((res) => res.data as HotelBookingInterface | undefined)
        .catch((err) => {
          toast.error(err?.response?.data?.error ?? err?.data?.error ?? 'Error while retrieving booking information.');
          toast.clearWaitingQueue();
        }),
    enabled: Boolean(id),
    retry: false,
  });

  const feedbackSubmit = (values: FeedbackInterface) => {
    if (bookingDetails?.confirmationID) {
      postFeedback(String(bookingDetails?.order?.orderID), {
        hotelId: bookingDetails?.hotels?.[0]?.propertyId,
        ...values,
      })
        .then(() => {
          setSuccessModal(true);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.error ?? err?.data?.error ?? 'Error while posting your feedback, try again later.',
          );
          toast.clearWaitingQueue();
        });
    }
  };

  const handleChangeDateModal = () => {
    setSuccessModal(false);
    navigate('/my-trips');
  };

  if (!id || isError) {
    navigate('/my-trips');
  }
  return {
    isLoading: isLoading || isFetching,
    bookingDetails,
    user,
    formHook,
    feedbackSubmit,
    navigate,
    handleChangeDateModal,
    successModal,
  };
};
