import Spinner from '@app/assets/spinner.svg?react';

export const Loading = ({
  width,
  height,
  className,
  spinnerClass = '',
  type,
}: {
  width?: number;
  height?: number;
  className?: string;
  spinnerClass?: string;
  type?: 'primary' | 'secondary';
}) => {
  const getType = () => {
    switch (type) {
      case 'primary':
      default:
        return `${spinnerClass} [&>circle:nth-of-type(2)]:stroke-[black] animate-spin`;
      case 'secondary':
        return `${spinnerClass} [&>circle:nth-of-type(1)]:stroke-[white] [&>circle:nth-of-type(2)]:stroke-[white] animate-spin`;
    }
  };

  return (
    <div className={className}>
      <Spinner width={width} height={height} className={getType()} />
    </div>
  );
};
