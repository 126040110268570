import { Button } from '../Button';
import { RawRange } from '../Fields';
import { HookedForm } from '../HookedForm';
import { FieldValues, useForm } from 'react-hook-form';
import { useState } from 'react';
import HowIcon from '@app/assets/icons/how.svg?react';
import BackgroundEffect from '@app/assets/bg-effect.svg?react';
import BackgroundEffect2 from '@app/assets/bg-effect-2.svg?react';

const calculateRewards = (annualSpend: number) => {
  let totalPoints = 0;

  if (annualSpend > 16000) {
    // Diamond tier
    totalPoints += (annualSpend - 16000) * 9;
    annualSpend = 16000;
  }
  if (annualSpend > 12000) {
    // Platinum tier
    totalPoints += (annualSpend - 12000) * 7;
    annualSpend = 12000;
  }
  if (annualSpend > 6000) {
    // Gold tier
    totalPoints += (annualSpend - 6000) * 5;
    annualSpend = 6000;
  }
  if (annualSpend > 3000) {
    // Silver tier
    totalPoints += (annualSpend - 3000) * 4;
    annualSpend = 3000;
  }
  // Base tier
  totalPoints += annualSpend * 2;

  // Calculate free nights based on points (10,000 points per night)
  let freeNights = Math.floor(totalPoints / 10000);

  // Return the result as an object
  return {
    totalPoints: totalPoints,
    freeNights: freeNights,
  };
};

export const PointsCalculator = () => {
  const [rewards, setRewards] = useState<{ totalPoints: number; freeNights: number } | undefined>();
  const calculatorForm = useForm();

  const goToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const handleCalculator = (values: FieldValues) => {
    if (!values) return;

    const result = calculateRewards(values.numberOfNights[0]);
    setRewards(result);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
      <div className="space-y-8">
        <h3 className="font-bold text-3xl md:text-5xl">See how many points you can earn with Beyond</h3>
        <HookedForm formHook={calculatorForm} onSubmit={handleCalculator} className="space-y-10">
          <div>
            <p className="text-dorrus-text-secondary text-sm">Select annual hotel spend</p>
            <RawRange max={200000} name="numberOfNights" />
          </div>
          <Button kind="secondary" type="submit" className="!w-fit">
            Calculate
          </Button>
        </HookedForm>
      </div>
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-6">
          <div className="flex gap-4 flex-col col-span-5 lg:col-span-3 bg-dorrus-background-secondary rounded-2xl p-6 text-white relative overflow-hidden">
            <BackgroundEffect className="absolute right-0 bottom-0" />
            <div className="flex items-center gap-2">
              <span className="text-3xl md:text-5xl font-bold">
                {new Intl.NumberFormat('en-US', { maximumFractionDigits: 0 }).format(rewards?.totalPoints ?? 0)}
              </span>
              <p className="text-dorrus-text-secondary">Points towards future travel</p>
            </div>
            <hr className="border-dorrus-text-secondary/30" />
            <div className="flex gap-2">
              <p className="text-dorrus-text-secondary">
                <div className="w-16">Redeem for up to</div>
              </p>
              <span className="text-3xl md:text-6xl font-bold">{rewards?.freeNights ?? 0}</span>
              <p className="text-dorrus-text-secondary">award nights at participating locations</p>
            </div>
          </div>
          <div className="col-span-5 lg:col-span-2 bg-dorrus-background-secondary p-6 rounded-2xl relative overflow-hidden">
            <BackgroundEffect2 className="absolute right-0 bottom-0 z-10 w-full" />
            <div className="relative z-20 flex justify-between flex-col h-full">
              <h5 className="font-bold text-xl text-white">Ready to book?</h5>
              <button type="button" onClick={() => goToTop()} className="w-fit rounded-full bg-white px-4 py-2">
                Book a stay
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white rounded-xl p-6 space-y-4">
          <div className="flex gap-2 items-center">
            <span className="h-11 w-11 bg-dorrus-border-primary rounded-lg flex items-center justify-center">
              <HowIcon width={24} height={24} />
            </span>
            <p className="text-lg">How to earn and use points:</p>
          </div>
          <ul className="space-y-4">
            <li className="font-bold flex items-center gap-2">
              <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary">
                01
              </span>
              Reserve one of 60,000+ hotels
            </li>
            <li>
              <hr />
            </li>
            <li className="font-bold flex items-center gap-2">
              <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary">
                02
              </span>
              Earn Dorrus Beyond points
            </li>
            <li>
              <hr />
            </li>
            <li className="font-bold flex items-center gap-2">
              <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary">
                03
              </span>
              Redeem or transfer points for travel and gift cards
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
