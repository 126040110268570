import { Controller, useFormContext } from 'react-hook-form';
import MaskedTextInput, { Mask } from 'react-text-mask';
import 'react-datepicker/dist/react-datepicker.css';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import Chevron from '@app/assets/icons/chevron.svg?react';
import { generateYearsFromYear } from '@app/utils/utils';
import { dateMask, months } from '@app/consts/consts';
import { format, getMonth, getYear } from 'date-fns';

export const DatePicker = ({
  name,
  label,
  placeholder,
  mask,
  ...rest
}: {
  name: string;
  label?: string;
  placeholder?: string;
  mask?: string | RegExp[];
} & Partial<ReactDatePickerProps>) => {
  const {
    getFieldState,
    control,

    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty, defaultValues },
  } = useFormContext();

  const { error } = getFieldState(name);
  const years = generateYearsFromYear(
    rest.minDate?.getFullYear() ?? 1800,
    rest.maxDate ? rest.maxDate?.getFullYear() : undefined,
  );

  return (
    <div className="w-full">
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, name } }) => {
          return (
            <ReactDatePicker
              showPopperArrow={false}
              name={name}
              placeholderText={placeholder ?? 'MM/DD/YYYY'}
              wrapperClassName="w-full"
              popperClassName="z-50"
              className="rounded-lg !w-full p-4 border-dorrus-button-border"
              calendarClassName="customDatePicker bg-white border-0 rounded-lg p-6 shadow-lg font-gilroy flex gap-6"
              renderCustomHeader={({ date, changeMonth, decreaseMonth, increaseMonth, changeYear }) => (
                <div className="flex mb-2">
                  <button type="button" aria-label="Previous Month" onClick={decreaseMonth}>
                    <span className="react-datepicker__navigation-icon--previous">
                      <Chevron />
                    </span>
                  </button>
                  <span className="mx-auto react-datepicker__current-month font-medium text-lg">
                    <select
                      value={months[getMonth(date)]}
                      onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                      className="border-0 outline-none focus:outline-none focus:ring-0"
                    >
                      {months.map((month) => {
                        return (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        );
                      })}
                    </select>
                    <select
                      value={getYear(date)}
                      onChange={({ target: { value } }) => changeYear(Number(value))}
                      className="border-0 outline-none focus:outline-none focus:ring-0"
                    >
                      {years.map((year) => {
                        return (
                          <option key={year} value={year}>
                            {year}
                          </option>
                        );
                      })}
                    </select>
                  </span>
                  <button type="button" aria-label="Next Month" onClick={increaseMonth}>
                    <span className="react-datepicker__navigation-icon--next">
                      <Chevron className="rotate-180" />
                    </span>
                  </button>
                </div>
              )}
              onChange={(e) => {
                onChange(e ? format(e, 'MM/dd/yyyy') : null);
              }} // send value to hook form
              selected={value}
              customInput={
                <MaskedTextInput
                  className="focus:outline-none focus:ring-0"
                  placeholder="mm/DD/yyyy"
                  type="text"
                  mask={(mask ?? dateMask) as Mask}
                />
              }
              {...rest}
            />
          );
        }}
      />

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
