import { BookingCard } from '@app/components/BookingCard';
import { Tabs } from '@app/components/Tabs';
import { useMyTrips } from './MyTrips.hooks';
import { Button } from '@app/components/Button';
import { useNavigate } from 'react-router-dom';
import { BaseModal } from '@app/components/BaseModal';
import { HookedForm } from '@app/components/HookedForm';
import { DateRange } from '@app/components/Fields';
import { RoomCard } from '@app/components/RoomCard';
import { Loading } from '@app/components/Loading/Loading';
import { HotelRoomInterface } from '@app/models/search';
import SearchIcon from '@app/assets/icons/search.svg?react';

export const MyTrips = () => {
  const {
    bookings,
    pastBookings,
    canceledBookings,
    bookAgainModal,
    handleBookAgain,
    handleBookAgainModal,
    handleBookNewRate,
    newRatesAvailable,
    isPending,
    bookingAgainForm,
  } = useMyTrips();
  const navigate = useNavigate();

  return (
    <>
      <div>
        <h3 className="text-5xl font-bold">My Trips</h3>
        <Tabs tabs={['Upcoming bookings', 'Past bookings', 'Canceled bookings']}>
          <div className="flex gap-4 flex-col">
            {bookings && bookings.length > 0 ? (
              bookings.map((_, i) => <BookingCard key={i} order={_} />)
            ) : (
              <div className="flex items-center gap-8 flex-col">
                <p>
                  It looks like you have no upcoming reservations. Browse our destinations and get inspired for your
                  next trip!
                </p>
                <Button onClick={() => navigate('/')} kind="secondary" className="max-w-[200px]">
                  Start now
                </Button>
              </div>
            )}
          </div>
          <div className="flex gap-4 flex-col">
            {pastBookings && pastBookings.length > 0 ? (
              pastBookings.map((_, i) => <BookingCard key={i} order={_} bookAgainAction={handleBookAgainModal} past />)
            ) : (
              <div className="flex items-center gap-8 flex-col">
                <p>
                  It seems you have no past reservations. Start booking with Dorrus to enjoy exclusive benefits and
                  rewards!
                </p>
                <Button onClick={() => navigate('/')} kind="secondary" className="max-w-[200px]">
                  Start now
                </Button>
              </div>
            )}
          </div>
          <div className="flex gap-4 flex-col">
            {canceledBookings && canceledBookings.length > 0 ? (
              canceledBookings.map((_, i) => <BookingCard key={i} order={_} />)
            ) : (
              <div className="flex items-center gap-8 flex-col">
                <p>
                  We cannot find any cancelled bookings for your account. Start booking with Dorrus to enjoy exclusive
                  benefits and rewards!
                </p>
                <Button onClick={() => navigate('/')} kind="secondary" className="max-w-[200px]">
                  Start now
                </Button>
              </div>
            )}
          </div>
        </Tabs>
      </div>
      <BaseModal
        header="Book trip again"
        size="max-w-4xl"
        open={Boolean(bookAgainModal)}
        onClose={() => handleBookAgainModal()}
      >
        {!newRatesAvailable.length && (
          <div className="space-y-4">
            <p>Enter new check in and check out dates</p>
            <div className="shadow-md p-4 rounded-xl">
              <HookedForm formHook={bookingAgainForm} onSubmit={handleBookAgain} className="flex justify-between">
                <DateRange label="Check-in" secondLabel="Check-out" name="newCheckIn" secondName="newCheckOut" />
                <Button
                  loading={isPending}
                  type="submit"
                  kind="secondary"
                  className="!w-fit px-6"
                  leftNode={<SearchIcon />}
                >
                  Search
                </Button>
              </HookedForm>
            </div>
          </div>
        )}
        {newRatesAvailable.length > 0 && (
          <>
            <p>We found this rates on your new dates</p>
            <div className="grid grid-cols-1 gap-4">
              {newRatesAvailable?.map((roomsAvailable: HotelRoomInterface, index: number) => (
                <div key={index} className="rounded-xl shadow-md">
                  <RoomCard
                    simplified
                    image={undefined}
                    handleClick={handleBookNewRate}
                    roomName={roomsAvailable.roomName}
                    inDate={roomsAvailable.inDate}
                    outDate={roomsAvailable.outDate}
                    ratesAvailable={roomsAvailable.ratesAvail}
                  />
                </div>
              ))}
            </div>
          </>
        )}
        {isPending && (
          <div className="flex items-center justify-center">
            <Loading />
            <p>Getting available rates...</p>
          </div>
        )}
      </BaseModal>
    </>
  );
};
