import { ComponentProps, ComponentType } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const RateRadio = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  id?: string;
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  containerClassName?: string;
  labelClassName?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number;
  startNumber?: number;
  endNumber?: number;
}) => {
  const {
    register,
    getFieldState,
    watch,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);
  const selected = watch(componentProps.name);

  return (
    <>
      <table className="border-collapse">
        <tr>
          {Array.from(
            { length: componentProps.endNumber ?? 10 - (componentProps.startNumber ?? 0) },
            (_, i) => i + (componentProps.startNumber ?? 0),
          ).map((i) => (
            <td
              key={i}
              className={`${selected === String(i + 1) ? 'bg-dorrus-background-secondary text-white border-dorrus-text-primary' : 'bg-transparent text-dorrus-background-secondary'} cursor-pointer relative w-12 h-10 text-center border focus:outline-none focus:ring-transparent ${componentProps.labelClassName ?? ''}`}
            >
              <label htmlFor={componentProps.id}>
                <input
                  className="w-full h-full opacity-0 absolute inset-0 cursor-pointer"
                  {...componentProps}
                  {...register(componentProps.name, {
                    ...(i && { value: i }),
                    ...(componentProps.isRequired && { required: 'This field is required' }),
                    ...(componentProps.validation && { pattern: componentProps.validation }),
                  })}
                  value={i + 1}
                  type="radio"
                />
                {i + 1}
              </label>
            </td>
          ))}
        </tr>
      </table>
      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </>
  );
};
