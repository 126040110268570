import { BaseModal } from '@app/components/BaseModal';
import { Card } from '@app/components/Card';
import { LoyaltyPill } from '@app/components/LoyaltyPill';
import { useRedeemLoyalty } from './RedeemLoyalty.hooks';
import { HookedForm } from '@app/components/HookedForm';
import { RadioButton } from '@app/components/Fields/RadioButton';
import { Button } from '@app/components/Button';

export const RedeemLoyalty = () => {
  const {
    handleGiftCardModal,
    viewGiftCard,
    getPriceFormat,
    formHook,
    selectedReward,
    loyaltyGiftCards,
    user,
    handleRedeem,
    successRedeem,
    setSuccessRedeem,
  } = useRedeemLoyalty();

  return (
    <div>
      <div className="space-y-10">
        <section className="space-y-6">
          <h3 className="text-5xl font-bold">Redeem</h3>
          <p>
            Browse our redemption catalogue for an ever growing option of gift cards. We&apos;re continuously adding new
            providers; transfer partners are coming soon!
          </p>
          <div className="max-w-[200px]">
            <LoyaltyPill
              title="Available Points"
              points={Number(user?.balance?.redeemAvailPoints ?? 0)}
              description="Points never expire"
            />
          </div>
        </section>
        <section className="space-y-6">
          <h3 className="text-5xl font-bold">Gift cards</h3>
          {loyaltyGiftCards?.length === 0 && <h5>No gift cards available at the moment.</h5>}
          {loyaltyGiftCards?.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
              {loyaltyGiftCards.map((catalogItem, _index) => (
                <Card key={_index} {...catalogItem} action={() => handleGiftCardModal(catalogItem.catalogKey)} />
              ))}
            </div>
          )}
        </section>
        {/* <section className="space-y-6">
          <h3 className="text-5xl font-bold">Other options</h3>
          <Button kind="tertiary">Redeem for cash to PayPal</Button>
          <Button kind="tertiary">Redeem for cash to Venmo</Button>
        </section> */}
      </div>
      <BaseModal
        size="max-w-2xl"
        open={Boolean(viewGiftCard)}
        onClose={() => handleGiftCardModal(undefined)}
        header={!successRedeem ? viewGiftCard?.brandName : 'Congratulations'}
      >
        {!successRedeem && (
          <div className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div className="space-y-4">
                <p>Choose an amount</p>
                <HookedForm
                  id="redeemCatalogItemForm"
                  formHook={formHook}
                  onSubmit={handleRedeem}
                  className="grid grid-cols-2 gap-2"
                >
                  {viewGiftCard?.items?.map((reward) => (
                    <RadioButton
                      defaultChecked={String(reward.entryID) === formHook.watch('reward')}
                      key={reward.entryID}
                      name="reward"
                      value={reward.entryID}
                      label={getPriceFormat(reward.productCurrencyValue ?? 0, true)}
                    />
                  ))}
                </HookedForm>
              </div>
              <div className="shadow-md rounded-2xl p-6 flex flex-col justify-between">
                <div className="flex justify-between items-center">
                  <p className="text-sm">Status Points YTD</p>
                  <span>{getPriceFormat(Number(user?.balance?.redeemAvailPoints ?? 0))}</span>
                </div>
                <div className="flex justify-between items-center">
                  <p className="text-sm">To be redeemed</p>
                  <span>
                    -
                    {getPriceFormat(
                      viewGiftCard?.items?.find((x) => String(x.entryID) === selectedReward)?.productPtsValue ?? 0,
                    )}{' '}
                    points
                  </span>
                </div>
                <hr />
                <div className="flex justify-between font-bold items-center">
                  <p className="text-sm">Remaining balance</p>
                  <span>
                    {getPriceFormat(
                      Number(user?.balance?.redeemAvailPoints ?? 0) -
                        Number(
                          viewGiftCard?.items?.find((x) => String(x.entryID) === selectedReward)?.productPtsValue ?? 0,
                        ),
                    )}{' '}
                    points
                  </span>
                </div>
              </div>
            </div>
            <p className="text-sm text-dorrus-text-secondary">
              {viewGiftCard?.items?.find((x) => String(x.entryID) === selectedReward)?.legalDisclaimer}
            </p>
            <div className="flex gap-4">
              <Button className="border-0" kind="primary" onClick={() => handleGiftCardModal(undefined)}>
                Cancel
              </Button>
              <Button
                type="submit"
                form="redeemCatalogItemForm"
                kind="secondary"
                disabled={
                  Number(user?.balance?.redeemAvailPoints ?? 0) <
                  Number(viewGiftCard?.items?.find((x) => String(x.entryID) === selectedReward)?.productPtsValue)
                }
              >
                Redeem
              </Button>
            </div>
          </div>
        )}
        {successRedeem && (
          <div className="space-y-6">
            <p>
              You have successfully exchanged your points for <b>{viewGiftCard?.brandName} Gift Card</b>.
            </p>
            {successRedeem && JSON.parse(successRedeem ?? '')?.url?.length > 0 && (
              <iframe
                title="redemption card"
                className="w-full h-[300px]"
                src={JSON.parse(successRedeem ?? '')?.url?.[0]}
              />
            )}
            <small>
              In case you can&apos;t see your reedemption details,{' '}
              <a
                href={JSON.parse(successRedeem ?? '')?.url?.[0]}
                className="underline"
                target="_blank"
                rel="noreferrer"
              >
                click here.
              </a>
            </small>
            <div className="flex justify-center">
              <Button
                className="border-0 !w-[200px]"
                kind="secondary"
                onClick={() => {
                  setSuccessRedeem(undefined);
                  handleGiftCardModal(undefined);
                }}
              >
                OK
              </Button>
            </div>
          </div>
        )}
      </BaseModal>
    </div>
  );
};
