import { createContext } from 'react';

import type { TravellerContextInterface, State } from './types';

export const TravellersStateContext = createContext<TravellerContextInterface | undefined>(undefined);

export const initialValues = {
  travellers: [],
  fetchingTravellers: true,
} as unknown as State;
