import { Button } from '../Button';
import { getAddressFromFields, getGuestsTextFromRoom, sourceRatingToStars } from '@app/utils/utils';
import MapPinIcon from '@app/assets/icons/pin-map.svg?react';
import { useNavigate } from 'react-router-dom';
import { OrderInterface } from '@app/models/booking';
import { useBookingCard } from './BookingCard.hooks';
import { format } from 'date-fns';

export const BookingCard = ({
  order,
  past,
  bookAgainAction,
}: {
  order: OrderInterface;
  bookAgainAction?: (order: OrderInterface) => void;
  past?: boolean;
}) => {
  const { lodgingInformation, getPriceFormat, servicesInformation } = useBookingCard(order);
  const navigate = useNavigate();

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      onClick={() => navigate(`/my-trips/${order.OrderIDText}/${order?.orderUUID}`)}
      className="bg-white rounded-3xl flex flex-col md:flex-row justify-between gap-4 p-6 cursor-pointer"
    >
      <img
        src={lodgingInformation?.hotelImageUrl ?? '/images/placeholder-hotel.png'}
        alt="hotel name"
        className="w-80 h-48 object-cover object-center rounded-xl"
      />
      <div className="mr-auto flex flex-col justify-between  w-full">
        <div className="flex flex-row justify-between items-center">
          <div className="space-y-1">
            <div>
              <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
                <h5 className="text-2xl font-bold capitalize">{lodgingInformation?.propertyName}</h5>
              </div>
              {lodgingInformation?.propertyQuality && (
                <span>{sourceRatingToStars(JSON.parse(lodgingInformation?.propertyQuality ?? ''))}</span>
              )}
            </div>
            <span className="flex gap-1 text-dorrus-text-secondary text-sm items-center">
              <MapPinIcon width={16} height={16} className="mb-auto" />
              {getAddressFromFields(order?.address?.[0])}
            </span>
          </div>
          <div className="flex gap-2">
            {past && bookAgainAction && (
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  bookAgainAction(order);
                }}
                kind="primary"
                className="!w-fit mb-auto md:mb-0 md:mt-auto border-0"
              >
                Book again
              </Button>
            )}
            <Button
              onClick={() => navigate(`/my-trips/${order.OrderIDText}`)}
              kind="secondary"
              className="!w-fit mb-auto md:mb-0 md:mt-auto"
            >
              View
            </Button>
          </div>
        </div>

        {lodgingInformation && (
          <div className="my-auto">
            <span className="text-dorrus-text-secondary text-sm">
              <p>{getGuestsTextFromRoom(servicesInformation?.[0]?.lodgingRooms?.[0])}</p>
              <span className="font-bold">
                {format(lodgingInformation?.checkInUTC ?? '', 'E, MMM dd, yyyy ')} –{' '}
                {format(lodgingInformation?.checkOutUTC ?? '', 'E, MMM dd, yyyy ')}
              </span>
            </span>
            <p className="flex items-center gap-2">
              <span className="font-bold text-2xl">
                {getPriceFormat(order?.totalPrice?.totalAmount ?? 0, true, order?.totalPrice?.baseAmountCurrencyCode)}
              </span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
