import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { useRegistration } from './Registration.hooks';
import { Input } from '@app/components/Fields';
import { Button } from '@app/components/Button';
import { FormPasswordInitialState } from './Registration.interfaces';

export const RegistrationPassword = () => {
  const { formHookPassword, handleRegistrationPhone } = useRegistration();

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="rounded-3xl md:bg-white md:w-[670px]  p-4 md:p-10 space-y-6">
        <div className="max-w-[70%] mx-auto space-y-6">
          <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">
            Join to unlock the best of Dorrus.
          </h1>
          <p className="text-center text-lg uppercase">Step 3/3</p>
          <p className="text-lg text-dorrus-text-primary text-center">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut.
          </p>
        </div>
        <div className="space-y-4 flex items-center flex-col justify-center md:w-2/3 mx-auto">
          <HookedForm<FormPasswordInitialState>
            formHook={formHookPassword}
            onSubmit={handleRegistrationPhone}
            className="w-full space-y-4"
          >
            <Input
              placeholder="Password"
              name="password"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              placeholder="Confirm Password"
              name="confirmPassword"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />

            <Button type="submit" kind="secondary">
              Confirm
            </Button>
          </HookedForm>
        </div>
      </div>
    </div>
  );
};
