import { Button } from '@app/components/Button';
import { useUser } from '@app/context/user';
import { useNavigate } from 'react-router-dom';
import HotelIcon from '@app/assets/icons/hotel.svg?react';
import LuggageIcon from '@app/assets/icons/lugagge.svg?react';
import LocationsIcon from '@app/assets/icons/locations.svg?react';
import BottomBlur from '@app/assets/bottom-blur.svg?react';
import ComparisonBackground from '@app/assets/comparison-background.svg?react';
import { BenefitsTable } from '@app/components/BenefitsTable/BenefitsTable';

export const levelsBenefitsHeader = [
  <div></div>,
  <div>
    <p className="font-bold">Member</p>
    <span className="text-dorrus-text-secondary">2 points</span>
  </div>,
  <div>
    <p className="font-bold">Silver</p>
    <span className="text-dorrus-text-secondary">3 points</span>
  </div>,
  <div>
    <p className="font-bold">Gold</p>
    <span className="text-dorrus-text-secondary">5 points</span>
  </div>,
  <div>
    <p className="font-bold">Platinum</p>
    <span className="text-dorrus-text-secondary">7 points</span>
  </div>,
  <div>
    <p className="font-bold">Diamond</p>
    <span className="text-dorrus-text-secondary">9 points</span>
  </div>,
];

export const levelsBenefits = [
  { feature: 'Points per $1 spent', includes: [true, true, true, true, true] },
  { feature: 'VIP Customer Service', includes: [false, true, true, true, true] },
  { feature: 'Exclusive Offers', includes: [false, true, true, true, true] },
  { feature: 'Rollover EQP', includes: [false, true, true, true, true] },
  { feature: 'Annual Elite Bonuses', includes: [false, false, true, true, true] },
  { feature: 'Partner Benefits', includes: [false, false, false, 'Priority Access', 'Premium Access'] },
  { feature: 'Points Transfer to Partners', includes: [false, false, true, true, true] },
  { feature: 'Monthly Booking Promotions', includes: [true, true, true, true, true] },
  { feature: 'Referral Program Access', includes: [true, true, true, true, true] },
  { feature: 'Early Access to New Features', includes: [false, false, false, true, true] },
  { feature: 'VIP Event Invitations', includes: [false, false, false, false, true] },
];

export const DorrusBeyond = () => {
  const { loyaltyLevels, user } = useUser();
  const navigate = useNavigate();

  return (
    <div className="space-y-16">
      <section className="grid grid-cols-1 md:grid-cols-2 gap-10">
        <div className="space-y-4">
          <h3 className="text-4xl md:text-5xl font-bold">Discover Dorrus Beyond - Loyalty</h3>
          <p className="text-dorrus-text-secondary">
            Experience the ultimate in travel rewards with <b>Dorrus Beyond!</b>
            <br />
            Every booking brings you closer to exclusive perks, from discounts and free nights to unique offers tailored
            just for you. Begin earning points today and unlock a world of benefits on your future journeys. Join now
            and make every trip more rewarding!
          </p>
          <Button
            onClick={() => (user ? navigate('/loyalty') : navigate('/login'))}
            kind="secondary"
            className="!w-fit"
          >
            Start earning
          </Button>
        </div>
        <div className="mt-auto">
          <div className="bg-white rounded-xl flex p-6 items-center gap-2 w-fit ml-auto">
            <span className="mb-auto text-dorrus-text-secondary">Up to</span>
            <span className="inline-flex text-5xl md:text-[80px] font-bold">25%</span>
            <div>
              <img src="/images/hotel-places.png" />
              <p className="text-dorrus-text-secondary">back on eligible stays</p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="overflow-hidden rounded-xl w-full h-[460px] relative">
          <img
            src="/images/full-hotel-placeholder.jpeg"
            className="absolute h-full w-full object-cover object-center"
          />
        </div>
      </section>
      <section className="space-y-16">
        <div className="flex items-center justify-between ">
          <h3 className="text-4xl md:text-5xl font-bold">Enjoy Rewards in Just Three Easy Steps</h3>
          <p className="text-dorrus-text-secondary max-w-[600px]">
            Maximize your travel experience in just three simple steps! Quick, rewarding, and crafted to elevate your
            next adventure, our streamlined process makes enjoying exclusive benefits easier than ever.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          <div className="bg-white rounded-xl p-6 flex gap-6 items-center justify-between flex-col min-h-[500px] text-center">
            <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary mr-auto ">
              01
            </span>
            <div className="relative w-full flex items-center justify-center">
              <LocationsIcon />
              <BottomBlur className="absolute" />
            </div>
            <p className="text-3xl">
              <b>Discover</b> – Browse through a curated selection of over 60,000 hotels worldwide and find the perfect
              stay.
            </p>
          </div>
          <div className="bg-white rounded-xl p-6 flex gap-6 items-center justify-between flex-col min-h-[500px] text-center">
            <p className="text-3xl">
              <b>Earn</b> – Collect points with every booking and watch your rewards multiply.
            </p>
            <div className="relative w-full flex items-center justify-center">
              <HotelIcon />
              <BottomBlur className="absolute" />
            </div>
            <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary mr-auto">
              02
            </span>
          </div>
          <div className="bg-white rounded-xl p-6 flex gap-6 items-center justify-between flex-col min-h-[500px] text-center">
            <span className="h-10 w-10 rounded-full border flex items-center justify-center text-dorrus-text-secondary mr-auto">
              03
            </span>
            <div className="relative w-full flex items-center justify-center">
              <LuggageIcon />
              <BottomBlur className="absolute " />
            </div>
            <p className="text-3xl">
              <b>Redeem</b> – Use your points to unlock discounts, free nights, and exclusive offers on your next
              adventure.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="bg-dorrus-background-secondary py-10 px-6 rounded-xl">
          <h3 className="text-4xl md:text-5xl font-bold text-center mb-10 text-white">Unlock Enhanced Experiences</h3>
          {loyaltyLevels.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-6">
              {loyaltyLevels
                .filter((level) => level?.levelCode !== 'BM')
                .map((level) => (
                  <div
                    key={level?.levelGroupID}
                    className="bg-dorrus-background-secondary rounded-2xl shadow-sm p-6 border border-dorrus-button-border relative overflow-hidden space-y-4"
                  >
                    <h5 className="font-bold text-2xl text-white">{level.displayName}</h5>
                    <small className="text-dorrus-text-secondary">
                      {new Intl.NumberFormat().format(Number(level.requiredEQP ?? 0))} EQP
                    </small>
                    <p className="text-lg text-dorrus-text-secondary">{level.description}</p>
                    <svg
                      className="absolute inset-0 w-full h-full"
                      width="327"
                      height="383"
                      viewBox="0 0 327 383"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g filter="url(#filter0_f_101_10442)">
                        <ellipse cx="163.5" cy="286.5" rx="109.5" ry="96.5" fill="white" fill-opacity="0.2" />
                      </g>
                      <defs>
                        <filter
                          id="filter0_f_101_10442"
                          x="-136"
                          y="0"
                          width="599"
                          height="573"
                          filterUnits="userSpaceOnUse"
                          color-interpolation-filters="sRGB"
                        >
                          <feFlood flood-opacity="0" result="BackgroundImageFix" />
                          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                          <feGaussianBlur stdDeviation="95" result="effect1_foregroundBlur_101_10442" />
                        </filter>
                      </defs>
                    </svg>
                  </div>
                ))}
            </div>
          )}
        </div>
      </section>
      <section>
        <div className="bg-white rounded-lg p-6 relative overflow-hidden flex items-center justify-center flex-col">
          <ComparisonBackground className="absolute inset-0 w-full z-10" />
          <h3 className="text-4xl md:text-5xl font-bold my-10">Benefits</h3>
          <div className="w-full flex justify-center">
            <BenefitsTable header={levelsBenefitsHeader} tableBody={levelsBenefits} />
          </div>
        </div>
      </section>
    </div>
  );
};
