import { initialValues } from './utils';

import type { Action, State } from './types';

export function bookingsReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setOrders': {
      return {
        ...state,
        bookings: action.bookings,
        fetchingBookings: true,
      };
    }
    case 'setPastOrders': {
      return {
        ...state,
        pastBookings: action.pastBookings,
        fetchingBookings: true,
      };
    }
    case 'setCanceledOrders': {
      return {
        ...state,
        canceledBookings: action.canceledBookings,
        fetchingBookings: true,
      };
    }
    case 'setCurrentBooking': {
      return { ...state, currentBooking: action.booking };
    }
    case 'setCurrentHotel': {
      return { ...state, currentHotel: action.hotel };
    }
    case 'logout': {
      return {
        ...initialValues,
      };
    }
    case 'setFetching': {
      return {
        ...state,
        fetchingBookings: action.fetching,
      };
    }
    default: {
      throw new Error('Error - unknown action type');
    }
  }
}
