import { ComponentProps, ComponentType, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const TextArea = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  description?: string;
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number;
  disabled?: boolean;
  hidden?: boolean;
  maxlength?: number;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  format?: (value: string) => string;
}) => {
  const {
    register,
    getFieldState,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex flex-col">
      {componentProps.label && (
        <label htmlFor={componentProps.name} className="font-bold">
          {componentProps.label}
        </label>
      )}
      <textarea
        {...(componentProps.onFocus && { onFocus: componentProps.onFocus })}
        {...componentProps}
        {...register(componentProps.name, {
          ...(componentProps.value && { value: componentProps.value }),
          ...(componentProps.isRequired && { required: 'This field is required' }),
          ...(componentProps.validation && { pattern: componentProps.validation }),
          ...(componentProps.onBlur && { onBlur: componentProps.onBlur }),
          ...(componentProps.format && { onChange: (e) => (e.target.value = componentProps.format!(e.target.value)) }),
        })}
        type={componentProps.type ?? 'text'}
        {...(componentProps.className && {
          className: `${componentProps.className} focus:outline-none focus:ring-0 disabled:bg-gray-200/50 disabled:border-dorrus-text-secondary disabled:text-dorrus-text-secondary`,
        })}
        {...(componentProps.maxlength && { maxLength: componentProps.maxlength })}
        rows={5}
        style={{ whiteSpace: 'pre-wrap' }}
      />
      {componentProps.description && (
        <small className="text-dorrus-text-secondary ">{componentProps.description}</small>
      )}
      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
