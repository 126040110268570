import { Button } from '@app/components/Button';
import { Input, Phone, Select, TextArea } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm';
import { useContactUs } from './ContactUs.hooks';

export const ContactUs = () => {
  const { bookings, formHook, pastBookings, submitContactUs, user, isPending } = useContactUs();

  return (
    <div className="space-y-4 max-w-xl mx-auto">
      <section>
        <h3 className="text-4xl md:text-5xl font-bold px-6 mb-2">Get in Touch</h3>
        <div className="bg-white rounded-2xl p-6 flex flex-col gap-4">
          <HookedForm formHook={formHook} onSubmit={submitContactUs} className="space-y-4">
            <div className="flex gap-2">
              <Input
                placeholder="First Name"
                name="firstName"
                containerClass="w-full"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <Input
                placeholder="Last Name"
                name="lastName"
                containerClass="w-full"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
            </div>
            <Input
              name="email"
              placeholder="Email"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Phone
              name="phoneNumber"
              placeholder="Phone Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Select
              name="category"
              placeholder="Select a category"
              options={[
                { label: 'Compliment', value: 'compliment' },
                { label: 'Complaint', value: 'complaint' },
                { label: 'Feedback', value: 'feedback' },
              ]}
            />
            {!user && (
              <Input
                name="orderNumber"
                placeholder="Order Number (if applicable)"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
            )}
            {user && (
              <Select
                name="orderNumber"
                placeholder="Select an order"
                options={[...(pastBookings ?? []), ...(bookings ?? [])].map((order) => ({
                  label: `${order?.OrderIDText} - ${order?.orderItems?.[0]?.lodgingBookings?.[0]?.propertyName}`,
                  value: order?.OrderIDText,
                }))}
              />
            )}
            <TextArea name="message" className="border border-dorrus-button-border rounded-lg w-full p-4" />
            <div className="g-recaptcha" data-sitekey={import.meta.env.VITE_APP_GOOGLE_CAPTCHA_KEY}></div>
            <Button disabled={isPending} type="submit" kind="secondary" className="w-full">
              Send
            </Button>
          </HookedForm>
        </div>
      </section>
    </div>
  );
};
