import { QueryLoader } from '@app/components/QueryLoader/QueryLoader';
import { ResultCard } from '@app/components/ResultCard';
import { HotelInterface } from '@app/models/search';

export const ResultsList = ({ results }: { results?: HotelInterface[] }) => {
  return (
    <div className="flex gap-4 flex-col">
      {results?.map((hotel, index) => <ResultCard key={index} {...hotel} />)}
      <QueryLoader />
    </div>
  );
};
