import { hotelCodes } from '@app/consts/consts';
import { useUser } from '@app/context/user';
import { deleteExternalLoyalty, postExternalLoyalty, updateExternalLoyalty } from '@app/services/externalLoyalties';
import { addExternalLoyaltyValidation, updateExternalLoyaltyValidation } from '@app/utils/validations';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useExternalLoyaltyList = () => {
  const { user, externalLoyalties, userDispatch } = useUser();
  const [addNewExternalLoyaltyModal, setAddNewExternalLoyaltyModal] = useState(false);
  const [deleteExternalLoyaltyModal, setDeleteExternalLoyaltyModal] = useState<string | undefined>();
  const [updateExternalLoyaltyModal, setUpdateExternalLoyaltyModal] = useState<string | undefined>();
  const [addExternalLoyaltyError, setAddExternalLoyaltyError] = useState<string | undefined>();
  const [isSavingExternalLoyalty, setIsSavingExternalLoyalty] = useState(false);
  const formHookExternalLoyalty = useForm({ resolver: addExternalLoyaltyValidation });
  const formHookUpdateExternalLoyalty = useForm({ resolver: updateExternalLoyaltyValidation });

  const showExternalLoyaltyModal = () => {
    if (!addNewExternalLoyaltyModal) formHookExternalLoyalty.reset();
    setAddExternalLoyaltyError(undefined);

    setAddNewExternalLoyaltyModal(!addNewExternalLoyaltyModal);
  };

  const showDeleteExternalLoyaltyModal = (id?: string) => {
    setDeleteExternalLoyaltyModal(id);
  };

  const showUpdateExternalLoyaltyModal = (id?: string) => {
    setUpdateExternalLoyaltyModal(id);
  };

  const handleDeleteExternalLoyalty = () => {
    if (!deleteExternalLoyaltyModal) return;
    deleteExternalLoyalty(deleteExternalLoyaltyModal)
      .then(() => {
        userDispatch({ type: 'setFetching', fetching: true });
        showDeleteExternalLoyaltyModal();
      })
      .catch((e) => console.error(e));
  };

  const handleAddExternalLoyalty = (values: FieldValues) => {
    setIsSavingExternalLoyalty(true);
    setAddExternalLoyaltyError(undefined);
    const providerCode = Object.keys(hotelCodes).find((key) => key === values.providerCode) as string;

    postExternalLoyalty({
      providerAccountValue: values.providerAccountValue,
      providerCode,
      providerTypeCode: values.providerTypeCode,
    })
      .then(() => {
        userDispatch({ type: 'setFetchingLoyalties', fetching: true });
        showExternalLoyaltyModal();
      })
      .catch((e) => {
        setAddExternalLoyaltyError(
          e?.response?.data?.error ?? 'There was an error adding this membership. Check its values and try again.',
        );

        console.error(e);
      })
      .finally(() => setIsSavingExternalLoyalty(false));
  };

  const handleUpdateExternalLoyalty = (values: { providerAccountValue: string }) => {
    if (!updateExternalLoyaltyModal) return;
    updateExternalLoyalty(updateExternalLoyaltyModal, values)
      .then(() => {
        userDispatch({ type: 'setFetchingLoyalties', fetching: true });
      })
      .catch((e) => {
        toast.error(
          e?.response?.data?.error ?? 'There was an error updating this membership. Check its values and try again.',
        );

        console.error(e);
      })
      .finally(() => showUpdateExternalLoyaltyModal());
  };

  const watchProviderName = formHookExternalLoyalty.watch('providerCode');

  useEffect(() => {
    if (watchProviderName) {
      const providerKey = Object.keys(hotelCodes).find(
        (provider) => hotelCodes[provider as keyof typeof hotelCodes] === watchProviderName,
      );
      formHookExternalLoyalty.setValue('providerTypeCode', providerKey ?? watchProviderName ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProviderName]);

  useEffect(() => {
    if (updateExternalLoyaltyModal) {
      formHookUpdateExternalLoyalty.reset({
        providerAccountValue: externalLoyalties.find(
          (loyalty) => loyalty.entryID === Number(updateExternalLoyaltyModal),
        )?.providerAccountValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateExternalLoyaltyModal]);

  return {
    externalLoyalties,
    user,
    formHookExternalLoyalty,
    showExternalLoyaltyModal,
    showDeleteExternalLoyaltyModal,
    deleteExternalLoyaltyModal,
    addNewExternalLoyaltyModal,
    handleAddExternalLoyalty,
    handleDeleteExternalLoyalty,
    addExternalLoyaltyError,
    isSavingExternalLoyalty,
    updateExternalLoyaltyModal,
    showUpdateExternalLoyaltyModal,
    formHookUpdateExternalLoyalty,
    handleUpdateExternalLoyalty,
  };
};
