import { AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useEffect, useRef, useState } from 'react';
import { Marker, MarkerClusterer } from '@googlemaps/markerclusterer';
import { SearchResultsInterface } from '@app/models/search';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@app/context/user';

type Props = { points: SearchResultsInterface[] };

const renderer = {
  render: ({
    count,
    position,
  }: {
    count: number;
    position: google.maps.LatLng | google.maps.LatLngLiteral | null | undefined;
  }) =>
    new google.maps.Marker({
      label: {
        text: `${String(count)} properties`,
        color: 'black',
        fontSize: '14px',
        className: 'bg-white rounded-full p-2',
      },
      position,
      icon: {
        path: 'M 0 0 L 0 0',
        fillOpacity: 0,
      },

      // adjust zIndex to be above other markers
      zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
    }),
};

export const MarkerCluster = ({ points }: Props) => {
  const { getPriceFormat } = useUser();
  const navigate = useNavigate();
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({
        map,
        renderer,
      });
    }
  }, [map]);

  // Update markers
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  return (
    <>
      {points.map((point) => (
        <AdvancedMarker
          key={point.propertyName}
          ref={(marker) => setMarkerRef(marker, point.propertyName)}
          position={{ lat: Number(point.location?.lat) ?? 0, lng: Number(point.location?.lng) ?? 0 }}
          onClick={() => {
            navigate(`/details/rate?id=${point?.lowestRateInfo?.sourceRateCode}`);
          }}
        >
          <div className="w-fit bg-white shadow-md p-2 text-sm rounded-full">
            {getPriceFormat(point?.lowestRateInfo?.originalRate?.rateWithoutTax ?? 0, true)}
          </div>
        </AdvancedMarker>
      ))}
    </>
  );
};
