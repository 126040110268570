import { MAX_COMPANIONS } from '@app/consts/consts';
import { useTravellers } from '@app/context/travellers';
import { useUser } from '@app/context/user';
import { TravellerInterface } from '@app/models/traveller';
import { deleteTraveller, getTraveller, postTraveller, updateTraveller } from '@app/services/traveller';
import { deleteUser, updatePasswordWithoutToken, updateUser, verifyOTP } from '@app/services/user';
import {
  addTravellerValidation,
  changePasswordValidation,
  deleteUserValidation,
  editProfileValidation,
  editTravellerValidation,
} from '@app/utils/validations';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useAccount = () => {
  const navigate = useNavigate();
  const { user, userDispatch } = useUser();
  const { travellers, travellersDispatch, fetchingTravellers } = useTravellers();
  const [addTravellerModal, setAddTravellerModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editProfileModal, setEditProfileModal] = useState(false);
  const [changePasswordModal, setChangePasswordModal] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState<string | undefined>();
  const [editTravellerModal, setEditTravellerModal] = useState<number | undefined>(undefined);
  const [hasPhoneChange, setHasPhoneChange] = useState(false);
  const [showOtp, setShowOtp] = useState(true);

  const formHookEditProfile = useForm({
    resolver: editProfileValidation,
  });
  const formHookOTP = useForm({});
  const formHookAddTraveler = useForm({ resolver: addTravellerValidation });
  const formHookDelete = useForm({
    resolver: deleteUserValidation,

    defaultValues: { whyDelete: undefined },
  });
  const formHookChangePassword = useForm({ resolver: changePasswordValidation });
  const formHookEditTraveller = useForm({
    resolver: editTravellerValidation,
  });

  const showDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const showAddTravelerModal = () => {
    if (!addTravellerModal) {
      formHookAddTraveler.reset();
    }
    setAddTravellerModal(!addTravellerModal);
  };

  const showEditProfileModal = () => {
    if (!editProfileModal) {
      formHookEditProfile.reset();
    }
    setEditProfileModal(!editProfileModal);
  };

  const showChangePasswordModal = () => {
    if (!changePasswordModal) {
      formHookChangePassword.reset();
      setChangePasswordError(undefined);
    }
    setChangePasswordModal(!changePasswordModal);
  };

  const showEditTravellerModal = (id?: number) => {
    if (!id) {
      formHookEditTraveller.reset();
    }
    setEditTravellerModal(id ?? undefined);
  };

  const handleEditTraveller = async (values: FieldValues) => {
    updateTraveller({ id: editTravellerModal, ...values } as TravellerInterface)
      .then(() => {
        travellersDispatch({ type: 'setFetching', fetching: true });
        showEditTravellerModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error ?? err?.data?.error ?? 'Error while updating companion information.');
        toast.clearWaitingQueue();
      });
  };

  const handleAddTraveller = async (values: FieldValues) => {
    if (travellers.length >= MAX_COMPANIONS) return;
    if (!values.email) return;

    postTraveller(values as TravellerInterface)
      .then(() => {
        travellersDispatch({ type: 'setFetching', fetching: true });
        showAddTravelerModal();
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error ?? err?.data?.error ?? 'Error while adding a new companion.');
        toast.clearWaitingQueue();
      });
  };

  const handleDeleteTraveller = (id: number) => {
    if (!id) return;

    deleteTraveller(id)
      .then(() => travellersDispatch({ type: 'setFetching', fetching: true }))
      .catch((err) => {
        toast.error(
          err?.response?.data?.error ??
            err?.data?.error ??
            `We couldn't delete the requested companion. Try again later.`,
        );
        toast.clearWaitingQueue();
      });
  };

  const handleDelete = (values: FieldValues) => {
    let deleteReason;
    if (values.whyDelete === 'Other') {
      deleteReason = values.whyDeleteNote;
    } else {
      deleteReason = values.whyDelete;
    }
    deleteUser(deleteReason)
      .then(() => {
        navigate('/login');
        userDispatch({ type: 'logout' });
        localStorage.removeItem('dtoken');
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error ?? err?.data?.error ?? 'Error while deleting your account. Try again later.',
        );
        toast.clearWaitingQueue();
      });
  };

  const handleProfileEdit = async (values: FieldValues) => {
    if (!values.middleName) delete values.middleName;
    await updateUser({ ...values, gender: values?.gender.value ?? '' })
      .then((res) => {
        if (res?.data?.phoneChanged) {
          setHasPhoneChange(true);
          setShowOtp(true);
        } else {
          showEditProfileModal();
          userDispatch({ type: 'setFetching', fetching: true });
        }
      })
      .catch((e) => {
        if (e?.response?.data?.error) {
          if (e?.response?.data?.error?.length > 0) {
            e?.response?.data?.error.map((err: { field: any; error: string }) => {
              formHookEditProfile.setError(err.field, {
                type: 'manual',
                message: err.error,
              });
            });
          }
        }
        console.error(e?.response?.data);
      });
  };

  const handleChangePassword = (values: { oldPassword: string; newPassword: string }) => {
    if (!values.oldPassword) return;
    if (!values.newPassword) {
      formHookChangePassword.setError('oldPassword', {
        type: 'manual',
        message: 'Passwords do not match',
      });
      return;
    }

    setChangePasswordError(undefined);

    updatePasswordWithoutToken(values?.oldPassword, values?.newPassword)
      .then(() => showChangePasswordModal())
      .catch((e) => {
        setChangePasswordError(e?.response?.data?.error);
        console.log(e);
      });
  };

  const handlePhoneRegistration = (values: FieldValues) => {
    if (!values.otp) {
      formHookOTP.setError('otp', { type: 'manual', message: 'OTP is required' });
      return;
    }
    verifyOTP(values.otp)
      .then(() => {
        setShowOtp(false);
        setHasPhoneChange(false);
        showEditProfileModal();
        toast.success('Phone number updated successfully');
        userDispatch({ type: 'setFetching', fetching: true });
      })
      .catch(() => formHookOTP.setError('otp', { type: 'manual', message: 'Invalid OTP' }));
  };

  const handleWrongPhone = () => {
    setHasPhoneChange(false);
    setShowOtp(false);
  };

  useEffect(() => {
    formHookEditProfile.reset({
      firstName: user?.firstName,
      middleName: user?.middleName,
      lastName: user?.lastName,
      phoneNumber: user?.phoneNumber,
      gender: user?.gender as { label?: string; value?: string },
      dob: user?.dob,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (editTravellerModal) {
      getTraveller(editTravellerModal)
        .then((res) => {
          formHookEditTraveller.reset({
            firstName: res.data.traveller.firstName,
            middleName: res.data.traveller.middleName,
            lastName: res.data.traveller.lastName,
            phoneNumber: res.data.traveller.phoneNumber,
            email: res.data.traveller.email,
          });
        })
        .catch((e) => console.error(e));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editTravellerModal]);

  return {
    user,
    addTravellerModal,
    deleteModal,
    changePasswordModal,
    editProfileModal,
    editTravellerModal,
    showDeleteModal,
    showEditProfileModal,
    showChangePasswordModal,
    showAddTravelerModal,
    showEditTravellerModal,
    handleAddTraveller,
    handleChangePassword,
    handleDelete,
    handleProfileEdit,
    handleEditTraveller,
    handleDeleteTraveller,
    travellers,
    fetchingTravellers,
    formHookAddTraveler,
    formHookChangePassword,
    formHookDelete,
    formHookEditProfile,
    formHookEditTraveller,
    changePasswordError,
    hasPhoneChange,
    formHookOTP,
    showOtp,
    handlePhoneRegistration,
    handleWrongPhone,
  };
};
