import { useContext } from 'react';

import { TravellersStateContext } from './utils';

const useTravellers = () => {
  const context = useContext(TravellersStateContext);
  if (context === undefined) {
    throw new Error('useTravellers must be used within an TravellersProvider');
  }
  return context;
};

export { useTravellers };

export { TravellersProvider } from './travellers';
