import { useSearch } from '@app/context/search';
import { HotelInterface } from '@app/models/search';
import { maxBy, minBy } from 'lodash';
import { FieldValues, useForm } from 'react-hook-form';

export const useFilters = (results: HotelInterface[], refetch?: () => void) => {
  const { filters, searchDispatch } = useSearch();
  const formHook = useForm({ defaultValues: { ...filters }, mode: 'onChange' });

  const updateFilters = async (e: FieldValues) => {
    searchDispatch({ type: 'setFetching', fetching: true });
    searchDispatch({ type: 'updateFilters', filters: { ...filters, ...e } });
  };

  const handleChange = async (e: FieldValues) => {
    if (e?.RateRange) {
      e.TotalPriceRange = { Min: Math.min(...e.RateRange), Max: Math.max(...e.RateRange) };
      delete e.RateRange;
    }

    if (e?.meals) {
      e.Meals = e.meals;
    }
    if (e?.Rating) {
      e.Rating = { Min: String(Number(e.Rating.value).toFixed(1)) };
    }

    await updateFilters(e);

    if (refetch) {
      refetch();
    }
  };
  return {
    formHook,
    handleChange,
    min: Math.round(
      minBy(results, (r) => r?.lowestRateInfo?.originalRate?.rateWithoutTax)?.lowestRateInfo?.originalRate
        ?.rateWithoutTax ?? 0,
    ),
    max: Math.round(
      maxBy(results, (r) => r?.lowestRateInfo?.originalRate?.rateWithoutTax)?.lowestRateInfo?.originalRate
        ?.rateWithoutTax ?? 0,
    ),
  };
};
