export enum StepNames {
  PersonalInformation = 'Personal Information',
  PhoneNumber = 'Phone Information',
  SecurityInformation = 'Security Information',
}

export enum StepSlugs {
  details = 'details',
  phone = 'phone',
  password = 'password',
}

export type FieldsNames =
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'email'
  | 'phoneNumber'
  | 'otp'
  | 'password'
  | 'confirmPassword';

export interface FormInitialState {
  firstName: string;
  middleName?: string;
  lastName: string;
}
export interface FormPhoneInitialState {
  phoneNumber?: string;
  otp?: string;
  skipPhone?: boolean;
}

export interface FormPasswordInitialState {
  password: string;
  confirmPassword: string;
}

export interface RouteParams {
  slug?: StepSlugs;
}
