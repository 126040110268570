export enum ApiRoutes {
  USER = 'users',
  TRAVELLER = 'traveller',
  BOOKING = 'booking',
  CARD = 'cards',
  FEEDBACK = 'feedback',
  EXTERNAL_LOYALTY = 'externalLoyalty',
  LOYALTY = 'loyalty',
  ALERTS = 'alerts',
}

export enum TokenizerRoutes {
  CARD = 'card',
}

export enum PaymentTypes {
  CASH = 'cash',
  HALF = 'half',
  POINTS = 'points',
}

export enum HotelImageCategories {
  EXTERIOR = 'Exterior view',
  LOBBY = 'Lobby view',
  POOL = 'Pool view',
  RESTAURANT = 'Restaurant',
  HEALTH_CLUB = 'Health club',
  GUEST_ROOM = 'Guest room',
  SUITE = 'Suite',
  MEETING_ROOM = 'Meeting room',
  BALLROOM = 'Ballroom',
  GOLF = 'Golf course',
  BEACH = 'Beach',
  SPA = 'Spa',
  BAR = 'Bar/Lounge',
  RECREATIONAL = 'Recreational facility',
  LOGO = 'Logo',
  BASICS = 'Basics',
  MAP = 'Map',
  PROMO = 'Promotional',
  HOT_NEWS = 'Hot news',
  MISC = 'Miscellaneous',
  GUEST_AMENITY = 'Guest room amenity',
  PROPERTY_AMENITY = 'Property amenity',
  BUSINESS_CENTER = 'Business center',
  NEARBY = 'Nearby attractions',
  ACCESSIBILITY = 'Accessibility',
  VIEW_PROPERTY = 'View from property',
  SHUTTLE = 'Shuttle/Transfer',
  CERTIFICATES = 'Certificates',
  SUSTAINABILITY = 'Sustainability',
  NO_CATEGORY = 'No category',
}

export const HotelImageCategoryCode = new Map([
  [HotelImageCategories.EXTERIOR, 1],
  [HotelImageCategories.LOBBY, 2],
  [HotelImageCategories.POOL, 3],
  [HotelImageCategories.RESTAURANT, 4],
  [HotelImageCategories.HEALTH_CLUB, 5],
  [HotelImageCategories.GUEST_ROOM, 6],
  [HotelImageCategories.SUITE, 7],
  [HotelImageCategories.MEETING_ROOM, 8],
  [HotelImageCategories.BALLROOM, 9],
  [HotelImageCategories.GOLF, 10],
  [HotelImageCategories.BEACH, 11],
  [HotelImageCategories.SPA, 12],
  [HotelImageCategories.BAR, 13],
  [HotelImageCategories.RECREATIONAL, 14],
  [HotelImageCategories.LOGO, 15],
  [HotelImageCategories.BASICS, 16],
  [HotelImageCategories.MAP, 17],
  [HotelImageCategories.PROMO, 18],
  [HotelImageCategories.HOT_NEWS, 19],
  [HotelImageCategories.MISC, 20],
  [HotelImageCategories.GUEST_AMENITY, 21],
  [HotelImageCategories.PROPERTY_AMENITY, 22],
  [HotelImageCategories.BUSINESS_CENTER, 23],
  [HotelImageCategories.NEARBY, 24],
  [HotelImageCategories.ACCESSIBILITY, 25],
  [HotelImageCategories.VIEW_PROPERTY, 26],
  [HotelImageCategories.SHUTTLE, 27],
  [HotelImageCategories.CERTIFICATES, 28],
  [HotelImageCategories.SUSTAINABILITY, 29],
]);
