import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { Ref, useRef } from 'react';

interface TooltipProps {
  children: React.ReactNode;
  content: React.ReactNode;
}
const timeoutDuration = 120;

export const Tooltip = ({ children, content }: TooltipProps) => {
  const triggerRef = useRef<HTMLButtonElement | null>();
  const timeOutRef = useRef<NodeJS.Timeout | undefined>();

  const handleEnter = (isOpen: boolean) => {
    clearTimeout(timeOutRef.current);
    if (!isOpen) {
      if (triggerRef.current) {
        triggerRef.current.click();
      }
    }
  };

  const handleLeave = (isOpen: boolean) => {
    timeOutRef.current = setTimeout(() => {
      isOpen && triggerRef.current?.click();
    }, timeoutDuration);
  };

  return (
    <Popover className="relative">
      {({ open }) => (
        <div onMouseEnter={() => handleEnter(open)} onMouseLeave={() => handleLeave(open)}>
          <PopoverButton ref={triggerRef as unknown as Ref<HTMLButtonElement> | null}>{children}</PopoverButton>
          <PopoverPanel
            anchor="bottom"
            className="flex flex-col bg-dorrus-background-secondary text-white p-2 rounded-lg ml-2 mt-1"
          >
            {content}
          </PopoverPanel>
        </div>
      )}
    </Popover>
  );
};
