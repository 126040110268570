import * as React from "react";
const SvgDiscover = (props) => /* @__PURE__ */ React.createElement("svg", { id: "Capa_1", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", viewBox: "0 0 512 512", width: 50, height: 40, xmlSpace: "preserve", fill: "#000000", ...props }, /* @__PURE__ */ React.createElement("g", { id: "SVGRepo_bgCarrier", strokeWidth: 0 }), /* @__PURE__ */ React.createElement("g", { id: "SVGRepo_tracerCarrier", strokeLinecap: "round", strokeLinejoin: "round" }), /* @__PURE__ */ React.createElement("g", { id: "SVGRepo_iconCarrier" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#34495E"
}, d: "M512,402.282c0,16.716-13.55,30.267-30.265,30.267H30.265C13.55,432.549,0,418.996,0,402.282V109.717 c0-16.716,13.55-30.266,30.265-30.266h451.469c16.716,0,30.265,13.551,30.265,30.266L512,402.282L512,402.282z" }), /* @__PURE__ */ React.createElement("path", { style: {
  opacity: 0.15,
  fill: "#202121",
  enableBackground: "new"
}, d: "M21.517,402.282V109.717 c0-16.716,13.552-30.266,30.267-30.266h-21.52C13.55,79.451,0,93.003,0,109.717v292.565c0,16.716,13.55,30.267,30.265,30.267h21.52 C35.07,432.549,21.517,418.996,21.517,402.282z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#F26E21"
}, d: "M309.389,255.801c0.041-9.636-3.572-19.286-10.843-26.558c-7.287-7.287-16.961-10.897-26.617-10.839 c-0.046,0-0.091-0.003-0.139-0.003c-20.968,0-37.6,16.628-37.6,37.602c0,20.767,16.837,37.599,37.6,37.599 c20.974,0,37.604-16.631,37.604-37.599C309.394,255.934,309.389,255.869,309.389,255.801z" }), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E7E8E3"
}, d: "M227.198,271.909c-5.62,5.626-10.807,7.824-16.394,7.943c-13.611-0.122-23.618-10.202-23.618-24.573 c0-7.234,2.739-13.163,7.078-18.228l0,0c4.069-3.863,9.311-6.359,15.339-6.359c6.507,0,11.571,2.169,17.352,7.954v-16.631 c-5.78-2.891-10.846-4.338-17.352-4.338c-9.192,0.657-17.859,4.371-24.507,10.203l0,0c-1.916,1.724-3.752,3.627-5.309,5.805 c-4.856,6.294-7.791,14.001-7.791,22.32c0,20.967,16.637,36.875,37.606,36.875c0.102,0,0.203-0.009,0.302-0.01 c0.141,0.002,0.28,0.01,0.42,0.01c5.784,0,10.85-1.443,17.357-4.336L227.198,271.909c-0.244,0.244,0.242,0.471,0,0.702V271.909z" }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E7E8E3"
}, points: "356.863,228.033 356.863,228.033 340.487,268.295 321.685,220.566 306.502,220.566 336.148,293.601 344.102,293.601 375.196,220.566 360.013,220.566 " }), /* @__PURE__ */ React.createElement("polygon", { style: {
  fill: "#E7E8E3"
}, points: "380.983,252.384 380.983,291.435 420.033,291.435 420.753,291.435 420.753,279.861 408.461,279.861 395.445,279.861 395.445,266.848 395.445,260.342 420.033,260.342 420.033,248.045 395.445,248.045 395.445,232.861 420.753,232.861 420.753,220.566 380.983,220.566 " }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E7E8E3"
}, d: "M54.135,220.566H33.884v70.869h20.25c10.845,0,18.798-2.895,25.306-7.957 c7.953-6.508,13.017-16.629,13.017-27.474C92.458,235.028,77.27,220.566,54.135,220.566z M70.765,274.08 c-4.339,3.614-10.124,5.781-18.802,5.781h-4.339V232.86h3.615c8.678,0,14.463,1.446,18.803,5.783 c5.061,4.336,7.955,10.848,7.955,17.358C78.72,262.509,75.828,269.737,70.765,274.08z" }), /* @__PURE__ */ React.createElement("rect", { x: 98.97, y: 220.56, style: {
  fill: "#E7E8E3"
}, width: 13.739, height: 70.867 }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E7E8E3"
}, d: "M147.415,248.045c-8.676-2.892-10.848-5.063-10.848-8.677c0-4.339,4.339-7.954,10.124-7.954 c4.339,0,7.954,1.447,11.57,5.786l7.233-9.4c-5.787-5.064-13.015-7.953-20.97-7.953c-12.296,0-22.42,8.678-22.42,20.244 c0,10.126,4.343,14.464,17.357,19.526c5.785,2.166,7.955,2.892,9.404,4.338c2.887,1.444,4.336,4.339,4.336,7.228 c0,5.786-4.336,10.126-10.848,10.126c-6.514,0-12.294-3.615-15.187-9.401l-8.678,8.678c6.511,9.4,14.465,13.738,24.589,13.738 c14.461,0,24.58-9.4,24.58-23.141C167.659,258.893,163.324,253.831,147.415,248.045z" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#E7E8E3"
}, d: "M459.804,261.783c10.843-2.166,16.63-9.4,16.63-20.244c0-13.014-9.402-20.973-25.308-20.973h-20.972 v70.869h13.739V263.23h2.172l19.519,28.205h16.634L459.804,261.783z M448.23,253.105h-4.336v-21.691h4.336 c8.678,0,13.742,3.614,13.742,10.85C461.972,249.492,456.909,253.105,448.23,253.105z" }))));
export default SvgDiscover;
