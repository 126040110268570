import { BaseModal } from '@app/components/BaseModal';
import { Button } from '@app/components/Button';
import { useUser } from '@app/context/user';
import { getEmailValidation, loginUser, registerUser } from '@app/services/user';
import { loginValidation } from '@app/utils/validations';
import { useModal } from 'mui-modal-provider';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useLogin = () => {
  const { userDispatch, fetchingUserData, token } = useUser();
  const { showModal } = useModal();
  const [isErrorModalOpen, setErrorModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const navigate = useNavigate();
  const [isEmailLogin, setIsEmailLogin] = useState(false);
  const [isTriggered, setIsTriggered] = useState(false);
  const [doesEmailExist, setEmailExist] = useState(false);
  const formHook = useForm({ defaultValues: { email: '', password: '' }, resolver: loginValidation });

  const handleErrorModal = () => {
    if (isErrorModalOpen) return;

    setErrorModalOpen(() => true);
    const modal = showModal(
      BaseModal,
      {
        header: 'Error',
        children: (
          <div className="space-y-4">
            <p>We had trouble logging you in. Please try again or follow the instructions sent by email to continue.</p>

            <Button
              onClick={() => {
                modal.hide();
                setErrorModalOpen(false);
              }}
              className="!w-fit min-w-48 mx-auto"
              kind="secondary"
            >
              OK
            </Button>
          </div>
        ),
        onClose: () => {
          modal.hide();
          setErrorModalOpen(false);
        },
      },
      { rootId: 'login-error-modal' },
    );
  };

  const handleStatusLogin = () => {
    if (isStatusModalOpen) return;

    setIsStatusModalOpen(() => true);
    const modal = showModal(
      BaseModal,
      {
        header: 'Error',
        children: (
          <div className="space-y-4">
            <p>We had trouble logging you in.</p>
            <p>
              You can go to our{' '}
              <a className="underline underline-offset-2" href="/contact-us">
                Contact Us
              </a>{' '}
              page and reach out to be able to login.
            </p>

            <Button
              onClick={() => {
                modal.hide();
                setIsStatusModalOpen(false);
              }}
              className="!w-fit min-w-48 mx-auto"
              kind="secondary"
            >
              OK
            </Button>
          </div>
        ),
        onClose: () => {
          modal.hide();
          setIsStatusModalOpen(false);
        },
      },
      { rootId: 'login-error-modal-status' },
    );
  };

  useEffect(() => {
    if (isTriggered && !formHook.formState.errors?.email && !formHook.formState.isValidating) {
      setIsEmailLogin(true);
      setIsTriggered(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formHook.formState.isValidating, formHook.formState.errors, isTriggered]);

  useEffect(() => {
    const email = formHook.getValues('email');
    if (email) {
      localStorage.setItem('email', email);
      checkEmail();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEmailLogin]);

  const checkEmail = async () => {
    const email = formHook.getValues('email');

    if (email) {
      localStorage.setItem('email', email);
      const res = await getEmailValidation(email)
        .then(() => {
          setEmailExist(true);
        })
        .catch((err) => {
          if (!err?.response?.data?.error.includes('not found')) {
            handleErrorModal();
            setIsEmailLogin(false);
            setIsTriggered(true);
          } else if (err?.response?.status === 409) {
            handleStatusLogin();
          }
          setEmailExist(false);
        });

      return res;
    }
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    if (doesEmailExist) {
      loginUser(data)
        .then((res) => {
          if (res?.data?.user?.emailVerified !== null) {
            res?.data?.token && localStorage.setItem('dtoken', res.data.token);

            userDispatch({ type: 'setData', user: res.data.user });
            navigate('/');
          } else {
            handleErrorModal();
          }
        })
        .catch((err) => {
          if (err?.response?.data?.error.includes('OAuth')) {
            formHook.setError('email', { type: 'manual', message: 'Please login with your social account' });
          } else if (err?.response?.status === 409) {
            handleStatusLogin();
          } else {
            handleErrorModal();
          }
        });
    } else {
      registerUser(data).finally(() => {
        handleErrorModal();
      });
    }
  };

  const cancelLogin = () => {
    setIsEmailLogin(false);
    formHook.reset();
  };

  return { doesEmailExist, fetchingUserData, isEmailLogin, formHook, onSubmit, setIsTriggered, token, cancelLogin };
};
