import { TokenizerRoutes } from '@app/consts/enums';
import { tokenizerInstance } from '@app/libs/api';

const TOKENIZER_PUBLIC_KEY = import.meta.env.VITE_APP_TOKENIZER_PUBLIC_KEY;

export const getAllCards = async (userId: number) => {
  return tokenizerInstance.get(`${TokenizerRoutes.CARD}/${userId}`);
};
export const getCardById = async (id: string | number) => {
  return tokenizerInstance.get(`${TokenizerRoutes.CARD}/${id}`);
};

export const addCard = async (data: {
  cardholderName: string;
  card: string;
  expDate: string;
  type: string;
  userId?: string;
}) => {
  return tokenizerInstance
    .post(
      `${TokenizerRoutes.CARD}/`,
      { ...data, cardSecurityCode: '333' },
      { headers: { 'Card-Public-Key': TOKENIZER_PUBLIC_KEY } },
    )
    .then((res) => res?.data);
};

export const deleteCard = async (id: string | number) => {
  return tokenizerInstance.delete(`${TokenizerRoutes.CARD}/${id}`);
};
