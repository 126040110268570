import type { Action, State } from './types';
import { initialValues } from './utils';

export function searchReducer(state: State, action: Action): State {
  switch (action.type) {
    case 'setData': {
      return {
        ...state,
        results: action.results,
        fetchResults: false,
      };
    }
    case 'setFetching': {
      return {
        ...state,
        fetchResults: action.fetching,
      };
    }
    case 'clearSearchParams': {
      return {
        ...state,
        searchParams: undefined,
      };
    }
    case 'updateSearchParams': {
      const updatedParams = { ...state.searchParams, ...action.updateSearchParams };
      if (
        state.searchParams?.ChildAges &&
        state.searchParams?.ChildAges?.length > 0 &&
        action.updateSearchParams.children === 0
      ) {
        updatedParams.ChildAges = undefined;
      }

      return {
        ...state,
        fetchResults: false,
        searchParams: updatedParams,
        ...(action.clearFilter && { filters: undefined }),
      };
    }
    case 'updateFilters': {
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.filters,
        },
      };
    }
    case 'logout': {
      return { ...initialValues };
    }

    default: {
      throw new Error('Error - unknown action type');
    }
  }
}
