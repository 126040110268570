import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormInitialState, FormPasswordInitialState, FormPhoneInitialState } from './Registration.interfaces';
import {
  registrationDetailsValidation,
  registrationPasswordValidation,
  registrationPhoneValidation,
} from '@app/utils/validations';
import { getOTP, updateUser, verifyOTP } from '@app/services/user';
import { matchPath, useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@app/context/user';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { registrationDetailsState, registrationPasswordState, registrationPhoneState, stepsInformation } from './utils';
import { toast } from 'react-toastify';

export const useRegistration = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [gettingOTP, setGettingOTP] = useState(false);
  const [showOtp, setShowOtp] = useState(false);
  const { userDispatch, user } = useUser();
  const formHookDetails = useForm<FormInitialState>({
    defaultValues: registrationDetailsState,
    resolver: registrationDetailsValidation,
  });
  const formHookPhone = useForm<FormPhoneInitialState>({
    defaultValues: registrationPhoneState,
    resolver: registrationPhoneValidation(showOtp),
  });
  const formHookPassword = useForm<FormPasswordInitialState>({
    defaultValues: registrationPasswordState,
    resolver: registrationPasswordValidation,
  });

  const skipPhone = formHookPhone.getValues('skipPhone');

  const handleRegistrationDetails = async () => {
    const firstName = formHookDetails.getValues('firstName');
    const middleName = formHookDetails.getValues('middleName');
    const lastName = formHookDetails.getValues('lastName');
    const updatedResponse = await updateUser({
      ...(firstName && { firstName }),
      ...(middleName && { middleName }),
      ...(lastName && { lastName }),
    });
    if (updatedResponse.data.success) {
      userDispatch({ type: 'setData', user: { ...user, firstName, lastName, middleName } });
      toast.success('Profile information saved.');
      navigate('/registration/phone');
    } else {
      console.error(updatedResponse?.data?.message);
    }
  };

  const handleRegistrationPhone = () => {
    const phoneNumber = formHookPhone.getValues('phoneNumber');
    const otpCode = formHookPhone.getValues('otp');

    if (skipPhone) {
      updateUser({ noPhoneNumber: 'true' })
        .then(() => {
          navigate('/');
        })
        .catch((e) => console.error(e));
    }

    if (phoneNumber) {
      if (!isValidPhoneNumber(phoneNumber)) {
        formHookPhone.setError('phoneNumber', { type: 'manual', message: 'Invalid phone number' });
      } else {
        if (!showOtp) {
          setGettingOTP(true);

          getOTP(phoneNumber)
            .then(() => setShowOtp(true))
            .catch((err) =>
              formHookPhone.setError('phoneNumber', {
                type: 'manual',
                message: err?.response?.data?.error ?? err?.data?.error ?? 'Error while retrieving OTP code.',
              }),
            )
            .finally(() => setGettingOTP(false));
        }
        if (showOtp && otpCode) {
          verifyOTP(otpCode)
            .then(() => {
              navigate('/');
              userDispatch({ type: 'setFetching', fetching: true });
            })
            .catch(() => formHookPhone.setError('otp', { type: 'manual', message: 'Invalid OTP code' }));
        }
      }
    }
  };

  const changeNumber = () => {
    if (skipPhone) return;
    formHookPhone.reset({ phoneNumber: '', otp: '', skipPhone: false });

    setShowOtp(false);
    setGettingOTP(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('dtoken');
    if (token) {
      userDispatch({ type: 'setFetching', fetching: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage]);

  useEffect(() => {
    if (searchParams.get('token')) {
      localStorage.setItem('dtoken', searchParams.get('token') ?? '');
      userDispatch({ type: 'setFetching', fetching: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  useEffect(() => {
    if (skipPhone) {
      formHookPhone.reset({ phoneNumber: '', otp: '', skipPhone: true });
      setShowOtp(false);
      setGettingOTP(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skipPhone]);

  useEffect(() => {
    if (matchPath(location.pathname, '/registration/details') && user.firstName) {
      navigate('/');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return {
    formHookDetails,
    formHookPhone,
    formHookPassword,

    handleRegistrationDetails,
    handleRegistrationPhone,
    stepsInformation,
    gettingOTP,
    showOtp,
    changeNumber,
  };
};
