import { RoomCardProps } from '@app/components/RoomCard/RoomCard.interfaces';
import { useBooking } from '@app/context/booking';
import { OrderInterface } from '@app/models/booking';
import { HotelRoomPlanInterface } from '@app/models/search';
import { getHotelRatesByDate } from '@app/services/booking';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

export const useMyTrips = () => {
  const navigate = useNavigate();
  const { bookings, pastBookings, canceledBookings, bookingsDispatch } = useBooking();
  const [bookAgainModal, setBookAgainModal] = useState<OrderInterface | undefined>();
  const [newRatesAvailable, setNewRatesAvailable] = useState([]);
  const bookingAgainForm = useForm();
  const { isPending, mutate } = useMutation({
    mutationKey: ['hotel-booking-again'],
    mutationFn: (values: FieldValues) => {
      const orderServices = values?.orderItems?.[0]?.services?.[0] ?? undefined;
      const orderLoadgingBookings = values?.orderItems?.[0]?.lodgingBookings?.[0] ?? undefined;
      return getHotelRatesByDate(orderLoadgingBookings?.sourcePropertyID as string, 'GLOBAL', true, {
        adults: Number(orderServices?.lodgingRooms?.[0]?.qtyAdults ?? 1),
        children: Number(orderServices?.lodgingRooms?.[0]?.qtyChildren ?? 0),
        inDate: format(values?.newCheckIn, 'yyyy-MM-dd'),
        outDate: format(values?.newCheckOut, 'yyyy-MM-dd'),
        ...(Number(orderServices?.lodgingRooms?.[0]?.qtyChildren) > 0 && {
          ChildAges: Array.from({ length: orderServices?.lodgingRooms?.[0]?.qtyChildren ?? 0 }).map(() => 1),
        }),
      })
        .then((res) => setNewRatesAvailable(res?.data?.rateResults?.roomsAvail ?? []))
        .catch((e) => console.error(e));
    },
    retry: false,
  });

  const handleBookAgainModal = (order?: OrderInterface) => {
    if (order) {
      setBookAgainModal(order);
      return;
    }
    bookingAgainForm.reset();
    setBookAgainModal(undefined);
    return;
  };

  const handleBookAgain = (values: FieldValues) => {
    mutate({ ...bookAgainModal, ...values });
  };

  const handleBookNewRate = async (rate?: Partial<HotelRoomPlanInterface & RoomCardProps>) => {
    const values = bookingAgainForm.getValues();
    bookingsDispatch({
      type: 'setCurrentBooking',
      booking: {
        ...rate,
        inDate: format(values?.newCheckIn, 'yyyy-MM-dd'),
        outDate: format(values?.newCheckOut, 'yyyy-MM-dd'),
        adult: Number(bookAgainModal?.orderItems?.[0]?.services?.[0]?.lodgingRooms?.[0]?.qtyAdults ?? 1),
        children: Number(bookAgainModal?.orderItems?.[0]?.services?.[0]?.lodgingRooms?.[0]?.qtyChildren ?? 0),
      },
    });
    return navigate(`/booking/payment?rateKey=${rate?.sourceRateKey}`);
  };

  return {
    bookings,
    pastBookings,
    canceledBookings,
    handleBookAgain,
    handleBookNewRate,
    handleBookAgainModal,
    bookAgainModal,
    isPending,
    newRatesAvailable,
    bookingAgainForm,
  };
};
