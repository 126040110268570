import { providerComparisonTable } from '@app/consts/consts';
import ComparisonCheck from '@app/assets/icons/comparison-check.svg?react';
import ComparisonNot from '@app/assets/icons/comparison-not.svg?react';
import BookingLogo from '@app/assets/comparison/booking.svg?react';
import ExpediaLogo from '@app/assets/comparison/expedia.svg?react';
import DorrusInverseLogo from '@app/assets/dorrus-logo-inverse.svg?react';

export const ComparisonTable = ({ highlightColumn }: { highlightColumn?: boolean }) => {
  return (
    <table
      cellSpacing={0}
      cellPadding={0}
      className="border-collapse !border-hidden block overflow-x-auto whitespace-nowrap z-50 relative"
    >
      <thead>
        <tr>
          <th></th>
          <th>
            <div className={`w-full flex justify-center p-4 ${highlightColumn ? 'bg-black rounded-t-xl' : ''}`}>
              <DorrusInverseLogo className="md:w-3/4 lg:w-full" />
            </div>
          </th>
          <th>
            <div className="w-full flex justify-center p-4">
              <BookingLogo height={40} width={40} />
            </div>
          </th>
          <th>
            <div className="w-full flex justify-center p-4">
              <ExpediaLogo className="md:w-[100px] lg:w-[140px]" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {providerComparisonTable.map((item, index) => (
          <tr className="group" key={index}>
            <td>
              <span className="font-bold pr-4">{item.feature}</span>
            </td>
            {item.includes.map((value, subIndex) => (
              <td className="lg:w-40 xl:w-64" key={subIndex}>
                <div
                  className={`group-last-of-type:rounded-b-xl w-full flex justify-center p-4 ${highlightColumn && subIndex === 0 ? 'bg-black' : ''}`}
                >
                  {value ? <ComparisonCheck /> : <ComparisonNot />}
                </div>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
