import { Controller, useFormContext } from 'react-hook-form';
import 'react-datepicker/dist/react-datepicker.css';
import Chevron from '@app/assets/icons/chevron.svg?react';
import { addDays, format, getDate } from 'date-fns';
import { useEffect, useRef, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useMediaQuery } from 'react-responsive';

export const DateRange = ({
  name,
  label,
  secondName,
  secondLabel,
  className,
}: {
  name: string;
  label?: string;
  secondName: string;
  secondLabel?: string;
  className?: string;
}) => {
  const {
    watch,
    getFieldState,
    control,
    setValue,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();
  const isTabletOrMobile = useMediaQuery({ maxWidth: 1224 });
  const firstCalendarRef = useRef<ReactDatePicker>();
  const secondCalendarRef = useRef<ReactDatePicker>();
  const firstDate = watch(name);
  const secondDate = watch(secondName);
  const [previousFirst, setPreviousFirst] = useState(firstDate ?? null);

  const { error: errorFirst } = getFieldState(name);
  const { error: errorSecond } = getFieldState(secondName);

  useEffect(() => {
    if (firstDate && previousFirst !== firstDate) {
      setValue(secondName, null);
      setPreviousFirst(firstDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstDate]);

  const renderDayContents = (_day: unknown, date: string | Date) => {
    return <span>{getDate(date)}</span>;
  };

  return (
    <div className="flex gap-10 flex-col lg:flex-row">
      <div className="flex flex-col w-full">
        {label && (
          <label htmlFor={name} className="font-bold">
            {label}
          </label>
        )}
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => {
            return (
              <ReactDatePicker
                popperClassName="z-50"
                ref={firstCalendarRef}
                minDate={new Date()}
                dateFormat="E, MMM dd, YYYY"
                showPopperArrow={false}
                selectsStart
                disabledKeyboardNavigation
                selected={value}
                className={`rounded-lg border-0 font-gilroy px-0 focus:outline-none focus:ring-0 min-w-fit ${className ?? ''}`}
                calendarClassName="customDatePicker bg-white border-0 rounded-lg p-4 shadow-lg font-gilroy flex  gap-6"
                renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                  <div className="pb-2 flex">
                    <button type="button" aria-label="Previous Month" onClick={decreaseMonth}>
                      <span className=" react-datepicker__navigation-icon--previous">
                        <Chevron />
                      </span>
                    </button>
                    <span className="mx-auto react-datepicker__current-month font-medium text-lg">
                      {monthDate.toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}
                    </span>
                    <button type="button" aria-label="Next Month" onClick={increaseMonth}>
                      <span className="react-datepicker__navigation-icon--next">
                        <Chevron className="rotate-180" />
                      </span>
                    </button>
                  </div>
                )}
                onChange={(e) => onChange(e ? format(e, 'MM/dd/yyyy') : null)}
                startDate={firstDate}
                {...(secondDate && { endDate: secondDate })}
                monthsShown={isTabletOrMobile ? 1 : 2}
                renderDayContents={renderDayContents}
                onSelect={() => {
                  firstCalendarRef.current?.setOpen(false);
                  setTimeout(() => {
                    secondCalendarRef.current?.setOpen(true);
                  }, 100);
                }}
                placeholderText="MM/DD/YYYY"
              />
            );
          }}
        />
        {errorFirst && <p className="font-semibold text-red-600">{errorFirst.message}</p>}
      </div>
      <div className="flex flex-col w-full min-w-fit">
        {secondLabel && (
          <label htmlFor={secondName} className="font-bold">
            {secondLabel}
          </label>
        )}
        <Controller
          control={control}
          name={secondName}
          render={({ field: { value, onChange } }) => (
            <ReactDatePicker
              popperClassName="z-50"
              renderDayContents={renderDayContents}
              ref={secondCalendarRef}
              minDate={firstDate ? addDays(firstDate, 1) : new Date()}
              {...(firstDate && { highlightDates: [new Date(firstDate)] })}
              disabledKeyboardNavigation
              placeholderText="MM/DD/YYYY"
              showPopperArrow={false}
              id={`calendar-${name}`}
              dateFormat="E, MMM dd, YYYY"
              selectsEnd
              selected={value}
              className={`rounded-lg border-0 font-gilroy px-0 focus:outline-none focus:ring-0 min-w-fit ${className ?? ''}`}
              calendarClassName="customDatePicker bg-white border-0 rounded-lg p-4 shadow-lg font-gilroy flex gap-6"
              renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
                <div className="pb-2 flex">
                  <button type="button" aria-label="Previous Month" onClick={decreaseMonth}>
                    <span className=" react-datepicker__navigation-icon--previous">
                      <Chevron />
                    </span>
                  </button>
                  <span className="mx-auto react-datepicker__current-month font-medium text-lg">
                    {monthDate.toLocaleString('en-US', {
                      month: 'long',
                      year: 'numeric',
                    })}
                  </span>
                  <button type="button" aria-label="Next Month" onClick={increaseMonth}>
                    <span className="react-datepicker__navigation-icon--next">
                      <Chevron className="rotate-180" />
                    </span>
                  </button>
                </div>
              )}
              onChange={(e) => {
                onChange(e ? format(e, 'MM/dd/yyyy') : null);
              }}
              {...(firstDate && { startDate: new Date(firstDate) })}
              monthsShown={isTabletOrMobile ? 1 : 2}
            />
          )}
        />
        {errorSecond && <p className="font-semibold text-red-600">{errorSecond.message}</p>}
      </div>
    </div>
  );
};
