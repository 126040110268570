import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';
import { UserInterface } from '@app/models/user';

export const registerUser = (data: { email: string; password: string }) => {
  return apiInstance.post(`${ApiRoutes.USER}/register`, data);
};

export const registerViaGoogle = () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE}${ApiRoutes.USER}/login/google`;
};

export const registerViaFacebook = () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE}${ApiRoutes.USER}/login/facebook`;
};

export const registerViaApple = () => {
  window.location.href = `${import.meta.env.VITE_APP_API_BASE}${ApiRoutes.USER}/login/apple`;
};

export const loginUser = (data: { email: string; password: string }) => {
  return apiInstance.post<{ token?: string; user: UserInterface }>(`${ApiRoutes.USER}/login`, data);
};

export const getUser = () => {
  return apiInstance.get<UserInterface>(`${ApiRoutes.USER}/getUser`);
};

export const getEmailValidation = (email: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/checkEmail`, { email });
};

export const getOTP = (phoneNumber: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/verify/phone`, { phoneNumber });
};

export const verifyOTP = (otp: string) => {
  return apiInstance.get(`${ApiRoutes.USER}/verify/phone/${otp}`);
};

export const verifyEmail = (token: string) => {
  return apiInstance.get(`${ApiRoutes.USER}/verify/email/${token}`);
};

export const getResetPassword = (email: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/forgetPassword`, { email });
};

export const updatePassword = (token: string, password: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/changePasswordWithCode`, { forgotPasswordCode: token, password });
};

export const updatePasswordWithoutToken = (oldPassword: string, newPassword: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/changePassword`, { oldPassword, newPassword });
};

export const addUserCard = async (cardId: string, lastDigits: string, expDate: string, cardType: string) => {
  return apiInstance.post(`${ApiRoutes.CARD}/`, { cardId, lastDigits, expDate, cardType });
};

export const postUserPasswordCode = async (forgotPasswordCode: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/verify-code`, { forgotPasswordCode });
};

export const getUserCards = async () => {
  return apiInstance.get(`${ApiRoutes.CARD}/`);
};

export const deleteUserCard = async (id: string) => {
  return apiInstance.delete(`${ApiRoutes.CARD}/${id}`);
};

export const updateUser = (data: {
  firstName?: string;
  middleName?: string;
  lastName?: string;
  dob?: string;
  gender?: string;
  phoneNumber?: string;
  noPhoneNumber?: string;
}) => {
  return apiInstance.put(`${ApiRoutes.USER}/`, data);
};

export const deleteUser = (deleteComment?: string) => {
  return apiInstance.post(`${ApiRoutes.USER}/disable`, { deleteComment });
};
