import axios from 'axios';

export const apiInstance = axios.create({ baseURL: import.meta.env.VITE_APP_API_BASE });
export const tokenizerInstance = axios.create({ baseURL: import.meta.env.VITE_APP_TOKENIZER_API_BASE });

apiInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('dtoken');
    if (token) {
      config.headers.set('Authorization', `Bearer ${token}`);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

apiInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  },
);
