import { CallToAction } from '@app/components/CallToAction';
import { LoyaltyPill } from '@app/components/LoyaltyPill';
import { useLoyalty } from './Loyalty.hooks';
import { LoyaltyTransactions } from '@app/components/LoyaltyTransactions';

export const Loyalty = () => {
  const { navigate, user, loyaltyLevels, userNextLevel, loyaltyTransactions, getPriceFormat } = useLoyalty();
  const requiredPointsToNext =
    Number(userNextLevel?.requiredEQP ?? 0) - Number(user?.balance?.qualPoints ?? 0) < 0
      ? 0
      : Number(userNextLevel?.requiredEQP ?? 0) - Number(user?.balance?.qualPoints ?? 0);
  return (
    <div className="space-y-10">
      <section className="space-y-6">
        <h1 className="font-bold text-5xl">Dorrus Beyond</h1>
        <p>View, manage and redeem your Dorrus Beyond points - points never expire!</p>

        <div className="flex gap-6 flex-col md:flex-row">
          <LoyaltyPill title="Available Points" points={Number(user?.balance?.redeemAvailPoints ?? 0)} />
          <LoyaltyPill
            title={`${new Date().getFullYear()} EQP`}
            points={Number(user?.balance?.qualPoints ?? 0)}
            description={
              userNextLevel
                ? `${getPriceFormat(Number(requiredPointsToNext))} to reach ${userNextLevel?.displayName}`
                : `${new Date().getFullYear() + 1} Status: Diamond`
            }
          />

          <LoyaltyPill title="Lifetime EQP" points={Number(user?.balance?.qualPoints ?? 0)} />
        </div>
        <div className="space-y-2">
          {userNextLevel ? (
            <p>
              <b>{getPriceFormat(Number(user?.balance?.qualPoints ?? 0))}</b> of{' '}
              <b>{getPriceFormat(userNextLevel?.requiredEQP ?? 0)}</b> EQP earned to reach{' '}
              <b>{userNextLevel?.displayName}</b>
            </p>
          ) : (
            <p>{`You made it! As recognition, we're please to award you Diamond status for ${new Date().getFullYear()}`}</p>
          )}
          <div className="bg-dorrus-background-secondary/50 w-full h-2 rounded-full overflow-hidden">
            <span
              style={{
                width: `${Math.floor((Number(user?.balance?.qualPoints ?? 0) / Number(userNextLevel?.requiredEQP ?? 0)) * 100)}%`,
              }}
              className=" rounded-full bg-black h-full block"
            ></span>
          </div>
          <p>Your EQP will reset every December 31st.</p>
        </div>
      </section>
      <CallToAction
        image="/images/loyalty.jpeg"
        title="Browse and redeem your Dorrus Beyond points"
        text="View our expansive catalog of redemption options. Transfer partners will be announced soon!"
        action={() => navigate('/loyalty/redeem')}
        actionText="Redeem"
      />
      <section className="space-y-6">
        <h1 className="font-bold text-5xl">Loyalty</h1>
        {loyaltyLevels.length > 0 && (
          <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 ">
            {loyaltyLevels
              .filter((level) => level?.levelCode !== 'BM')
              .map((level) => (
                <div key={level?.levelGroupID} className="bg-white rounded-2xl shadow-sm p-6">
                  <h5 className="font-bold text-2xl">{level.displayName}</h5>
                  <small className="text-dorrus-text-secondary">{getPriceFormat(level.requiredEQP ?? 0)} EQP</small>
                  <p className="text-lg">{level.description}</p>
                </div>
              ))}
          </div>
        )}
      </section>
      <section className="space-y-6">
        <h1 className="font-bold text-5xl">Dorrus Beyond activity details</h1>
        <p>
          If you book through us, you should see earned points in your account within 48 hours. Certain external
          provider transactions may take up to 45 days.
        </p>
        <LoyaltyTransactions transactions={loyaltyTransactions} />
      </section>
    </div>
  );
};
