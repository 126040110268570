import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';

export const getPendingAccruals = () => {
  return apiInstance.post(`${ApiRoutes.LOYALTY}/create-pending-accruals`);
};

export const getRedemptionDetails = () => {
  return apiInstance.get(`${ApiRoutes.LOYALTY}/redemption-details`);
};

export const getRedemptionCatalogs = () => {
  return apiInstance.get(`${ApiRoutes.LOYALTY}/redemption-catalogs`);
};

export const getLoyaltyLevels = () => {
  return apiInstance.get(`${ApiRoutes.LOYALTY}/elite-levels`);
};

export const getLoyaltyTransactions = () => {
  return apiInstance.get(`${ApiRoutes.LOYALTY}/transactions`);
};

export const getLoyaltyData = () => {
  return apiInstance.get(`externalLoyalty`);
};

export const postPredictPoints = (data: {
  checkInDate: string;
  checkOutDate: string;
  sourceRateCode?: string;
  orderIdText?: string;
}) => {
  return apiInstance.post(`${ApiRoutes.LOYALTY}/predict-points`, { ...data });
};

export const updateLoyaltyRedemption = (id: string | number) => {
  return apiInstance.post(`${ApiRoutes.LOYALTY}/update-redemption-details/${id}`);
};

export const postMemberBonus = (data: { promoCode: string }) => {
  return apiInstance.post(`${ApiRoutes.LOYALTY}/member-bonus-link`, data);
};

export const getMemberBonus = (promoCode: string) => {
  return apiInstance.get(`${ApiRoutes.LOYALTY}/member-bonus/${promoCode}`);
};
