import Info from '@app/assets/amenities/info.svg?react';
import ATM from '@app/assets/amenities/atm.svg?react';
import Barbecue from '@app/assets/amenities/barbecue.svg?react';
import Bilingual from '@app/assets/amenities/bilingual.svg?react';
import CarRental from '@app/assets/amenities/car-rental.svg?react';
import Casino from '@app/assets/amenities/casino.svg?react';
import CheckIn from '@app/assets/amenities/check-in.svg?react';
import Coffee from '@app/assets/amenities/coffee.svg?react';
import Elevator from '@app/assets/amenities/elevator.svg?react';
import Exchange from '@app/assets/amenities/exchange.svg?react';
import Library from '@app/assets/amenities/library.svg?react';
import Pet from '@app/assets/amenities/pet.svg?react';
import Reception from '@app/assets/amenities/reception.svg?react';
import FullReception from '@app/assets/amenities/24h-reception.svg?react';
import AirConditioning from '@app/assets/amenities/ac.svg?react';
import Babysitting from '@app/assets/amenities/babysitting.svg?react';
import Concierge from '@app/assets/amenities/concierge.svg?react';
import Fitness from '@app/assets/amenities/gym.svg?react';
import Laundry from '@app/assets/amenities/laundry.svg?react';
import Parking from '@app/assets/amenities/parking.svg?react';
import Pool from '@app/assets/amenities/pool.svg?react';
import Nightclub from '@app/assets/amenities/nightclub.svg?react';
import Security from '@app/assets/amenities/security.svg?react';
import ExpressIn from '@app/assets/amenities/express-in.svg?react';
import ExpressOut from '@app/assets/amenities/express-out.svg?react';
import Playground from '@app/assets/amenities/playground.svg?react';
import Massage from '@app/assets/amenities/massage.svg?react';
import Executive from '@app/assets/amenities/executive.svg?react';
import Transport from '@app/assets/amenities/transport.svg?react';
import WiFi from '@app/assets/amenities/wifi.svg?react';
import Meeting from '@app/assets/amenities/meeting.svg?react';
import Children from '@app/assets/amenities/children.svg?react';
import Safe from '@app/assets/amenities/safe.svg?react';
import LaundryService from '@app/assets/amenities/laundry-service.svg?react';
import Call from '@app/assets/amenities/call.svg?react';
import Drink from '@app/assets/amenities/drink.svg?react';
import Restaurant from '@app/assets/amenities/restaurant.svg?react';
import Extra from '@app/assets/amenities/extra.svg?react';

export const getAmenityIcon = (amenityCode: string) => {
  // switch using Sabre amenity codes
  switch (amenityCode) {
    case '2018':
      return Extra;
    case '76':
    case '174':
      return Restaurant;
    case '72':
    case '165':
    case '337':
      return Drink;
    case '7':
      return ATM;
    case '1':
      return Reception;
    case '9':
    case '118':
      return Barbecue;
    case '10':
      return Bilingual;
    case '15':
      return CarRental;
    case '16':
      return Casino;
    case '18':
      return CheckIn;
    case '168':
    case '96':
      return LaundryService;
    case '218':
    case '289':
    case '324':
      return Children;
    case '269':
    case '24':
      return Meeting;
    case '2009':
    case '179':
    case '261':
    case '286':
    case '259':
      return WiFi;
    case '43':
    case '172':
    case '181':
      return Transport;
    case '20':
    case '141':
    case '137':
    case '159':
    case '173':
    case '227':
      return Coffee;
    case '33':
      return Elevator;
    case '26':
      return Exchange;
    case '14':
      return Library;
    case '224' || '214':
      return Pet;
    case '53':
    case '42':
    case '68':
    case '216':
    case '230':
    case '63':
    case '64':
      return Parking;
    case '78':
    case '294':
    case '2007':
      return Safe;
    case '2004':
      return Call;
    case '34':
    case '228':
    case '229':
      return Executive;
    case '54':
    case '66':
    case '253':
    case '49':
      return Pool;
    case '2':
      return FullReception;
    case '5':
      return AirConditioning;
    case '62':
      return Nightclub;
    case '48':
      return Fitness;
    case '58':
      return Laundry;
    case '8':
      return Babysitting;
    case '22':
      return Concierge;
    case '3':
    case '2002':
      return Security;
    case '36':
      return ExpressIn;
    case '37':
      return ExpressOut;
    case '70':
      return Playground;
    case '61':
      return Massage;
    default:
      return Info;
  }
};

export const levelColors = {
  BM: '#1C1B1B',
  SM: '#A1A1A1',
  GM: '#D29224',
  PM: '#8E86A4',
  DM: '#6FA4CA',
};
