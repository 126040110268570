import { HotelInterface } from '@app/models/search';
import { getAddressFromFields, sourceRatingToStars } from '@app/utils/utils';
import PinMapIcon from '@app/assets/icons/pin-map.svg?react';
import { Link } from 'react-router-dom';
import { useResultCard } from './ResultCard.hooks';

export const ResultCard = (props?: HotelInterface) => {
  const { guests, numberOfNights, getPriceFormat, addHotelToStorage } = useResultCard();
  return (
    <Link onClick={() => addHotelToStorage(props)} to={`/details/rate?id=${props?.lowestRateInfo.sourceRateCode}`}>
      <div className="bg-white rounded-3xl flex flex-col md:flex-row justify-between gap-4 p-6 cursor-pointer">
        <div className="w-full md:w-64 h-48 relative overflow-hidden rounded-xl">
          <img
            src={props?.propertyImageURL ?? 'https://picsum.photos/seed/picsum/1920/300'}
            alt="hotel name"
            className="w-full h-full absolute inset-0 object-cover object-center"
          />
        </div>
        <div className="mr-auto flex flex-col space-y-2">
          <h3 className="font-bold text-2xl flex flex-col md:flex-row items-center  text-center md:text-left gap-2 md:gap-4">
            {props?.propertyName} {sourceRatingToStars(props?.propertyQuality)}
          </h3>
          <p className="flex items-center gap-2 text-sm">
            <span>
              <PinMapIcon width={15} />
            </span>
            {getAddressFromFields(props?.propertyAddress)} | {props?.distance} {props?.unitOfMeasure} from search
          </p>
          <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 pt-2 md:pt-4">
            {props?.amenities?.slice(0, 6).map((x) => (
              <li key={x.code} className="text-sm">
                {x.name}
              </li>
            ))}
          </ul>

          {/* <Link to="#" className="font-bold mt-6">
            Hotel website
          </Link> */}
        </div>
        <div className="flex justify-between items-center md:items-start flex-row md:flex-col">
          <span className="w-fit h-fit md:ml-auto text-lg bg-dorrus-background-secondary text-white p-4 rounded-2xl block">
            {props?.sourceRating}
          </span>
          <div className="ml-auto w-fit text-right">
            <span className="block text-dorrus-text-secondary">
              <p>
                {numberOfNights} nights, {guests}
              </p>{' '}
            </span>
            <span className="font-bold text-2xl">
              {getPriceFormat(
                props?.lowestRateInfo?.originalRate?.rateWithoutTax ?? 0,
                true,
                props?.lowestRateInfo?.originalRate?.currencyCode,
              )}
            </span>
            <span className="block text-sm">
              +
              {getPriceFormat(
                props?.lowestRateInfo?.originalRate?.taxAmount ?? 0,
                true,
                props?.lowestRateInfo?.originalRate?.currencyCode,
              )}{' '}
              taxes
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
