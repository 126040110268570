import { getMemberBonus } from '@app/services/loyalty';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const usePromotionTerms = () => {
  const [searchParams] = useSearchParams();
  const [terms, setTerms] = useState();

  const getPromotionInformation = () => {
    const promoCode = searchParams.get('promoCode');
    if (promoCode) {
      getMemberBonus(promoCode).then((res) => {
        if (res?.data?.bonus?.terms_conditions) {
          setTerms(res.data.bonus?.terms_conditions);
        }
      });
    }
  };

  useEffect(() => {
    getPromotionInformation();
  }, [searchParams]);

  return { terms };
};
