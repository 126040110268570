import { Loading } from '../Loading/Loading';
import type { ButtonProps } from './Button.interfaces';
import { addDisabledClass, getButtonClassesByType, loadingByButtonClass } from './utils';

export const Button = ({
  children,
  leftNode,
  rightNode,
  kind = 'primary',
  className,
  loading,
  disabled,
  ...rest
}: ButtonProps) => {
  return (
    <button
      type={rest.type ?? 'button'}
      disabled={loading || disabled}
      className={`flex items-center justify-center gap-4 ${getButtonClassesByType(kind, loading ?? disabled)} ${className}`}
      {...rest}
    >
      <div className={`flex items-center justify-center gap-4 ${addDisabledClass(loading ?? disabled)} ${className}`}>
        {leftNode && <div>{leftNode}</div>}
        {children}
        {rightNode && <div>{rightNode}</div>}
        {loading && (
          <div>
            <Loading type={loadingByButtonClass(kind)} width={20} height={20} />
          </div>
        )}
      </div>
    </button>
  );
};
