import { getResetPassword, postUserPasswordCode, updatePassword } from '@app/services/user';
import { forgotPasswordValidation } from '@app/utils/validations';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

export const useForgotPassword = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const [validatingToken, setValidatingToken] = useState(true);
  const [resetInProgress, setResetInProgress] = useState(false);
  const [email, setEmail] = useState<string | undefined>();
  const [error, setError] = useState();
  const formHook = useForm({ resolver: forgotPasswordValidation });

  const goBack = () => {
    navigate('/login');
  };

  const submitForm = async (values: FieldValues) => {
    if (!email) return;
    if (!token) return;

    updatePassword(token, values.password)
      .then(() => {
        toast.success('Password updated with success.');
        navigate('/login');
      })
      .catch((err) => setError(err?.response?.data?.error));
  };

  const resetPassword = async () => {
    if (!email) return;
    getResetPassword(email)
      .then(() => setResetInProgress(true))
      .catch(() => setResetInProgress(false));
  };

  useEffect(() => {
    if (token) {
      postUserPasswordCode(token)
        .catch((err) => setError(err?.response?.data?.error))
        .finally(() => {
          setValidatingToken(false);
        });
    } else {
      setValidatingToken(false);
    }
  }, [token]);

  useEffect(() => {
    const localEmail = localStorage.getItem('email');

    if (localEmail) setEmail(localEmail);
    if (!localEmail) navigate('/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { error, resetPassword, resetInProgress, formHook, submitForm, token, validatingToken, goBack };
};
