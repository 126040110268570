import { StepNames, StepSlugs } from './Registration.interfaces';

export const registrationDetailsState = {
  firstName: '',
  middleName: '',
  lastName: '',
};

export const registrationPhoneState = {
  phoneNumber: '',
  otp: '',
  skipPhone: false,
};

export const registrationPasswordState = {
  password: '',
  confirmPassword: '',
};

export const stepsInformation = [
  {
    id: 1,
    step: 1,
    slug: StepSlugs.password,
    title: StepNames.SecurityInformation,
    fields: ['password', 'confirmPassword'],
  },
  {
    id: 2,
    step: 2,
    slug: StepSlugs.details,
    title: StepNames.PersonalInformation,
    fields: ['firstName', 'middleName', 'lastName'],
  },
  { id: 3, step: 3, slug: StepSlugs.phone, title: StepNames.PhoneNumber, fields: ['phoneNumber', 'skipPhone', 'otp'] },
];
