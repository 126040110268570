export const cancellationPolicy = [
  {
    value: true,
    label: 'Refundable',
  },
];

export const mealList = [
  {
    value: 'Breakfast',
    label: 'Breakfast included',
  },
  {
    value: 'Dinner',
    label: 'Dinner included',
  },
  {
    value: 'Lunch',
    label: 'Lunch included',
  },
  {
    value: 'All',
    label: 'All inclusive',
  },
];
export const paymentType = [
  {
    value: 'reserve',
    label: 'Reserve now, pay later',
  },
  {
    value: 'refundable',
    label: 'Fully refundable',
  },
];
export const propertyClass = [
  {
    value: [5],
    label: '5 stars',
  },
  {
    value: [4],
    label: '4 Stars',
  },
  {
    value: [3],
    label: '3 stars',
  },
  {
    value: [2],
    label: '2 stars',
  },
  {
    value: [0],
    label: 'Unrated',
  },
];

export const reviewScore = [
  {
    value: '4.0',
    label: 'Wonderful: 9+',
  },
  {
    value: '3.0',
    label: 'Very Good: 8+',
  },
  {
    value: '2.0',
    label: 'Good: 7+',
  },
  {
    value: '1.0',
    label: 'Pleasant: 6+',
  },
];

export const amenitiesList = [
  {
    externalCode: 1,
    internalCode: 1,
    name: '24-hour front desk',
  },
  {
    externalCode: 2,
    internalCode: 2,
    name: '24-hour room service',
  },
  {
    externalCode: 3,
    internalCode: 3,
    name: '24-hour security',
  },
  {
    externalCode: 5,
    internalCode: 4,
    name: 'Air conditioning',
  },
  {
    externalCode: 7,
    internalCode: 5,
    name: 'ATM-Cash machine',
  },
  {
    externalCode: 8,
    internalCode: 6,
    name: 'Baby sitting',
  },
  {
    externalCode: 9,
    internalCode: 7,
    name: 'BBQ-Picnic area',
  },
  {
    externalCode: 10,
    internalCode: 8,
    name: 'Bilingual staff',
  },
  {
    externalCode: 14,
    internalCode: 9,
    name: 'Business library',
  },
  {
    externalCode: 15,
    internalCode: 10,
    name: 'Car rental desk',
  },
  {
    externalCode: 16,
    internalCode: 11,
    name: 'Casino',
  },
  {
    externalCode: 18,
    internalCode: 12,
    name: 'Check-in kiosk',
  },
  {
    externalCode: 20,
    internalCode: 13,
    name: 'Coffee shop',
  },
  {
    externalCode: 22,
    internalCode: 14,
    name: 'Concierge desk',
  },
  {
    externalCode: 24,
    internalCode: 15,
    name: 'Conference facilities',
  },
  {
    externalCode: 26,
    internalCode: 16,
    name: 'Currency exchange',
  },
  {
    externalCode: 33,
    internalCode: 17,
    name: 'Elevators',
  },
  {
    externalCode: 34,
    internalCode: 18,
    name: 'Executive floor',
  },
  {
    externalCode: 36,
    internalCode: 19,
    name: 'Express check-in',
  },
  {
    externalCode: 37,
    internalCode: 20,
    name: 'Express check-out',
  },
  {
    externalCode: 41,
    internalCode: 21,
    name: 'Free airport shuttle',
  },
  {
    externalCode: 42,
    internalCode: 22,
    name: 'Free parking',
  },
  {
    externalCode: 43,
    internalCode: 23,
    name: 'Free transportation',
  },
  {
    externalCode: 44,
    internalCode: 24,
    name: 'Game room',
  },
  {
    externalCode: 45,
    internalCode: 25,
    name: 'Gift/News stand',
  },
  {
    externalCode: 48,
    internalCode: 26,
    name: 'Health club',
  },
  {
    externalCode: 49,
    internalCode: 27,
    name: 'Heated pool',
  },
  {
    externalCode: 53,
    internalCode: 28,
    name: 'Indoor parking',
  },
  {
    externalCode: 54,
    internalCode: 29,
    name: 'Indoor pool',
  },
  {
    externalCode: 55,
    internalCode: 30,
    name: 'Hot tub',
  },
  {
    externalCode: 58,
    internalCode: 31,
    name: 'Laundry service',
  },
  {
    externalCode: 60,
    internalCode: 32,
    name: 'Live entertainement',
  },
  {
    externalCode: 61,
    internalCode: 33,
    name: 'Massage services',
  },
  {
    externalCode: 62,
    internalCode: 34,
    name: 'Nighclub',
  },
  {
    externalCode: 63,
    internalCode: 35,
    name: 'Off-site parking',
  },
  {
    externalCode: 64,
    internalCode: 36,
    name: 'On-site parking',
  },
  {
    externalCode: 66,
    internalCode: 37,
    name: 'Outdoor pool',
  },
  {
    externalCode: 68,
    internalCode: 38,
    name: 'Parking',
  },
  {
    externalCode: 69,
    internalCode: 39,
    name: 'Photocopy center',
  },
  {
    externalCode: 70,
    internalCode: 40,
    name: 'Playground',
  },
  {
    externalCode: 71,
    internalCode: 41,
    name: 'Pool',
  },
  {
    externalCode: 72,
    internalCode: 42,
    name: 'Poolside snack bar',
  },
  {
    externalCode: 76,
    internalCode: 43,
    name: 'Restaurant',
  },
  {
    externalCode: 77,
    internalCode: 44,
    name: 'Room attendant',
  },
  {
    externalCode: 78,
    internalCode: 45,
    name: 'Safety deposit box',
  },
  {
    externalCode: 79,
    internalCode: 46,
    name: 'Sauna',
  },
  {
    externalCode: 81,
    internalCode: 47,
    name: 'Shoe shine stand',
  },
  {
    externalCode: 83,
    internalCode: 48,
    name: 'Solarium',
  },
  {
    externalCode: 84,
    internalCode: 49,
    name: 'Spa',
  },
  {
    externalCode: 86,
    internalCode: 50,
    name: 'Steam bath',
  },
  {
    externalCode: 87,
    internalCode: 51,
    name: 'Storage space',
  },
  {
    externalCode: 91,
    internalCode: 52,
    name: 'Tour/sightseeing desk',
  },
  {
    externalCode: 92,
    internalCode: 53,
    name: 'Translation services',
  },
  {
    externalCode: 96,
    internalCode: 54,
    name: 'Dry cleaning',
  },
  {
    externalCode: 97,
    internalCode: 55,
    name: 'Valet parking',
  },
  {
    externalCode: 98,
    internalCode: 56,
    name: 'Vending machine',
  },
  {
    externalCode: 101,
    internalCode: 57,
    name: 'Wheelchair access',
  },
  {
    externalCode: 103,
    internalCode: 58,
    name: 'Multilingual staff',
  },
  {
    externalCode: 104,
    internalCode: 59,
    name: 'Wedding services',
  },
  {
    externalCode: 105,
    internalCode: 60,
    name: 'Banquet facilities',
  },
  {
    externalCode: 106,
    internalCode: 61,
    name: 'Bell staff-porter',
  },
  {
    externalCode: 107,
    internalCode: 62,
    name: 'Beauty shop/salon',
  },
  {
    externalCode: 109,
    internalCode: 63,
    name: 'Direct dial telephone',
  },
  {
    externalCode: 116,
    internalCode: 64,
    name: 'Accessible parking',
  },
  {
    externalCode: 118,
    internalCode: 65,
    name: 'Barbeque grills',
  },
  {
    externalCode: 122,
    internalCode: 66,
    name: 'Shops and commercial services',
  },
  {
    externalCode: 130,
    internalCode: 67,
    name: 'Body scrub',
  },
  {
    externalCode: 131,
    internalCode: 68,
    name: 'Body wrap',
  },
  {
    externalCode: 132,
    internalCode: 69,
    name: 'Public areas air conditioned',
  },
  {
    externalCode: 137,
    internalCode: 70,
    name: 'Complimentary in-room coffee or tea',
  },
  {
    externalCode: 141,
    internalCode: 71,
    name: 'Complimentary continental breakfast',
  },
  {
    externalCode: 145,
    internalCode: 72,
    name: 'Complimentary newspaper in lobby',
  },
  {
    externalCode: 148,
    internalCode: 73,
    name: 'Front desk',
  },
  {
    externalCode: 149,
    internalCode: 74,
    name: 'Grocery shopping service available',
  },
  {
    externalCode: 159,
    internalCode: 75,
    name: 'Continental breakfast',
  },
  {
    externalCode: 160,
    internalCode: 76,
    name: 'Full meal plan',
  },
  {
    externalCode: 162,
    internalCode: 78,
    name: 'Meal plan available',
  },
  {
    externalCode: 165,
    internalCode: 79,
    name: 'Lounges-bars',
  },
  {
    externalCode: 168,
    internalCode: 80,
    name: 'Onsite laundry',
  },
  {
    externalCode: 172,
    internalCode: 81,
    name: 'Transportation',
  },
  {
    externalCode: 173,
    internalCode: 82,
    name: 'Breakfast served in restaurant',
  },
  {
    externalCode: 174,
    internalCode: 83,
    name: 'Lunch served in restaurant',
  },
  {
    externalCode: 179,
    internalCode: 84,
    name: 'Wireless internet in public areas',
  },
  {
    externalCode: 181,
    internalCode: 85,
    name: 'Transportation services - local area',
  },
  {
    externalCode: 186,
    internalCode: 86,
    name: 'Street side parking',
  },
  {
    externalCode: 197,
    internalCode: 87,
    name: 'Locker room',
  },
  {
    externalCode: 198,
    internalCode: 88,
    name: 'Non-smoking rooms',
  },
  {
    externalCode: 200,
    internalCode: 89,
    name: 'Aerobics instruction',
  },
  {
    externalCode: 202,
    internalCode: 90,
    name: 'Bicycle rentals',
  },
  {
    externalCode: 204,
    internalCode: 91,
    name: 'Late check-out available',
  },
  {
    externalCode: 211,
    internalCode: 92,
    name: 'Lobby coffee service',
  },
  {
    externalCode: 214,
    internalCode: 93,
    name: 'Pet amenities available',
  },
  {
    externalCode: 215,
    internalCode: 94,
    name: 'Exhibition-convention floor',
  },
  {
    externalCode: 216,
    internalCode: 95,
    name: 'Long-term parking',
  },
  {
    externalCode: 217,
    internalCode: 96,
    name: 'Children not allowed',
  },
  {
    externalCode: 218,
    internalCode: 97,
    name: 'Children welcome',
  },
  {
    externalCode: 220,
    internalCode: 98,
    name: 'Hotel does not provide pornographic films',
  },
  {
    externalCode: 223,
    internalCode: 99,
    name: 'Internet services',
  },
  {
    externalCode: 224,
    internalCode: 100,
    name: 'Pets allowed',
  },
  {
    externalCode: 227,
    internalCode: 101,
    name: 'Complimentary breakfast',
  },
  {
    externalCode: 228,
    internalCode: 102,
    name: 'Business center',
  },
  {
    externalCode: 229,
    internalCode: 103,
    name: 'Business services',
  },
  {
    externalCode: 230,
    internalCode: 104,
    name: 'Secured parking',
  },
  {
    externalCode: 233,
    internalCode: 105,
    name: 'Tennis court',
  },
  {
    externalCode: 234,
    internalCode: 106,
    name: 'Water sports',
  },
  {
    externalCode: 236,
    internalCode: 107,
    name: 'Golf',
  },
  {
    externalCode: 237,
    internalCode: 108,
    name: 'Horseback riding',
  },
  {
    externalCode: 239,
    internalCode: 109,
    name: 'Beachfront',
  },
  {
    externalCode: 242,
    internalCode: 110,
    name: 'Heated guest rooms',
  },
  {
    externalCode: 253,
    internalCode: 111,
    name: 'Private pool',
  },
  {
    externalCode: 255,
    internalCode: 112,
    name: 'Data port',
  },
  {
    externalCode: 258,
    internalCode: 113,
    name: 'ADA accessible',
  },
  {
    externalCode: 259,
    internalCode: 114,
    name: 'High speed internet access',
  },
  {
    externalCode: 260,
    internalCode: 115,
    name: 'Interior corridors',
  },
  {
    externalCode: 261,
    internalCode: 116,
    name: 'High speed wireless',
  },
  {
    externalCode: 262,
    internalCode: 117,
    name: 'Kitchenette',
  },
  {
    externalCode: 268,
    internalCode: 118,
    name: 'All public areas non-smoking',
  },
  {
    externalCode: 269,
    internalCode: 119,
    name: 'Meeting rooms',
  },
  {
    externalCode: 272,
    internalCode: 120,
    name: 'Snow skiing',
  },
  {
    externalCode: 273,
    internalCode: 121,
    name: 'Water skiing',
  },
  {
    externalCode: 278,
    internalCode: 122,
    name: 'Umbrellas',
  },
  {
    externalCode: 280,
    internalCode: 123,
    name: 'Grocery store',
  },
  {
    externalCode: 282,
    internalCode: 124,
    name: 'Airport shuttle service',
  },
  {
    externalCode: 286,
    internalCode: 125,
    name: 'Complimentary wireless internet',
  },
  {
    externalCode: 289,
    internalCode: 126,
    name: 'Children programs',
  },
  {
    externalCode: 292,
    internalCode: 127,
    name: 'Newspaper',
  },
  {
    externalCode: 294,
    internalCode: 128,
    name: 'Hotel safe deposit box',
  },
  {
    externalCode: 299,
    internalCode: 129,
    name: 'Coffee/tea',
  },
  {
    externalCode: 310,
    internalCode: 130,
    name: 'Hypoallergenic rooms',
  },
  {
    externalCode: 312,
    internalCode: 131,
    name: 'Smoke-free property',
  },
  {
    externalCode: 313,
    internalCode: 132,
    name: 'Water purification system in use',
  },
  {
    externalCode: 316,
    internalCode: 133,
    name: 'Electric vehicle charging',
  },
  {
    externalCode: 324,
    internalCode: 134,
    name: 'Children breakfast',
  },
  {
    externalCode: 325,
    internalCode: 135,
    name: 'Cloakroom service',
  },
  {
    externalCode: 327,
    internalCode: 136,
    name: 'Events ticket service',
  },
  {
    externalCode: 329,
    internalCode: 137,
    name: 'Limited parking',
  },
  {
    externalCode: 334,
    internalCode: 138,
    name: 'Summer terrace',
  },
  {
    externalCode: 336,
    internalCode: 139,
    name: 'Roof terrace',
  },
  {
    externalCode: 337,
    internalCode: 140,
    name: 'Beach bar',
  },
  {
    externalCode: 342,
    internalCode: 141,
    name: 'Snack bar',
  },
  {
    externalCode: 345,
    internalCode: 142,
    name: 'Fitness center',
  },
  {
    externalCode: 2001,
    internalCode: 143,
    name: 'Eco friendly',
  },
  {
    externalCode: 2002,
    internalCode: 144,
    name: 'Stay safe',
  },
  {
    externalCode: 2003,
    internalCode: 145,
    name: 'Rooms with balcony',
  },
  {
    externalCode: 2004,
    internalCode: 146,
    name: 'Local calls',
  },
  {
    externalCode: 2005,
    internalCode: 147,
    name: 'Minibar',
  },
  {
    externalCode: 2006,
    internalCode: 148,
    name: 'Refrigerator',
  },
  {
    externalCode: 2007,
    internalCode: 149,
    name: 'In-room safe',
  },
  {
    externalCode: 2008,
    internalCode: 150,
    name: 'Smoking rooms available',
  },
  {
    externalCode: 2009,
    internalCode: 151,
    name: 'Free Wi-Fi in meeting rooms',
  },
  {
    externalCode: 2010,
    internalCode: 152,
    name: 'Beach view',
  },
  {
    externalCode: 2011,
    internalCode: 153,
    name: 'Ocean view',
  },
  {
    externalCode: 2012,
    internalCode: 154,
    name: 'Mountain view',
  },
  {
    externalCode: 2013,
    internalCode: 155,
    name: 'Pool view',
  },
  {
    externalCode: 2014,
    internalCode: 156,
    name: 'Family room',
  },
  {
    externalCode: 2016,
    internalCode: 157,
    name: 'Rollaway adult',
  },
  {
    externalCode: 2017,
    internalCode: 158,
    name: 'Crib charge',
  },
  {
    externalCode: 2018,
    internalCode: 159,
    name: 'Extra person',
  },
];
