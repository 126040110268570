import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';

export const postExternalLoyalty = (data: {
  providerAccountValue: string;
  providerCode: string;
  providerTypeCode: string;
}) => {
  return apiInstance.post(`${ApiRoutes.EXTERNAL_LOYALTY}`, data);
};
export const fetchExternalLoyalties = () => {
  return apiInstance.get(`${ApiRoutes.EXTERNAL_LOYALTY}`);
};
export const fetchExternalLoyaltyById = (id: string) => {
  return apiInstance.get(`${ApiRoutes.EXTERNAL_LOYALTY}/${id}`);
};
export const deleteExternalLoyalty = (id: string) => {
  return apiInstance.delete(`${ApiRoutes.EXTERNAL_LOYALTY}/${id}`);
};
export const updateExternalLoyalty = (id: string, data: { providerAccountValue: string }) => {
  return apiInstance.put(`${ApiRoutes.EXTERNAL_LOYALTY}/${id}`, data);
};
