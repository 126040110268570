import { Range, Select } from '../Fields';
import { HookedForm } from '../HookedForm/HookedForm';
import {
  amenitiesList,
  cancellationPolicy,
  mealList,
  paymentType,
  propertyClass,
  reviewScore,
} from '@app/consts/filters';
import { useFilters } from './Filters.hooks';
import { HotelInterface } from '@app/models/search';

export const Filters = ({ results, refetch }: { results: HotelInterface[]; refetch: () => void }) => {
  const { formHook, max, handleChange } = useFilters(results, refetch);

  return (
    <div className="my-6">
      <HookedForm
        formHook={formHook}
        onChange={handleChange}
        className="grid md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-none xl:grid-flow-col gap-4"
      >
        <Range
          min={0}
          max={15000 ?? max}
          placeholder="Budget"
          name="RateRange"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
        />
        <Select
          keepPlaceholder
          placeholder="Review score"
          name="Rating"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={reviewScore}
        />
        <Select
          placeholder="Property class"
          name="PropertyQualityCode"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={propertyClass}
        />
        <Select
          keepPlaceholder
          placeholder="Payment type"
          name="RefundableOnly"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={paymentType}
        />
        <Select
          keepPlaceholder
          placeholder="Meal plans available"
          name="meals"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={mealList}
        />
        <Select
          multiple
          placeholder="Amenities"
          name="AmenityCode"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={amenitiesList.map((amenity) => ({ label: amenity.name, value: amenity.externalCode })) ?? []}
        />
        <Select
          placeholder="Cancellation policy"
          name="RefundableOnly"
          triggerClassname="border-0 shadow-sm bg-white !rounded-full !px-4 !py-2 col-auto !justify-center"
          options={cancellationPolicy}
        />
      </HookedForm>
    </div>
  );
};
