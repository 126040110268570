/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { Button } from '@app/components/Button';
import { useAccount } from './Account.hooks';
import { BaseModal } from '@app/components/BaseModal';
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { DatePicker, Input, Phone, Radio, Select } from '@app/components/Fields';
import { format, parseISO, sub } from 'date-fns';
import { getFullNameFromFields } from '@app/utils/utils';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { MAX_COMPANIONS, genderSelection } from '@app/consts/consts';
import ChevronIcon from '@app/assets/icons/chevron.svg?react';
import { FadeIn } from '@app/components/FadeIn';
import { AnimatePresence } from 'framer-motion';
import { Link } from 'react-router-dom';
import { CardsList } from '@app/components/CardsList';
import { ExternalLoyaltyList } from '@app/components/ExternalLoyaltyList';

export const Account = () => {
  const {
    user,
    showAddTravelerModal,
    showChangePasswordModal,
    showDeleteModal,
    showEditProfileModal,
    showEditTravellerModal,
    addTravellerModal,
    deleteModal,
    changePasswordModal,
    editProfileModal,
    editTravellerModal,
    travellers,
    fetchingTravellers,
    handleChangePassword,
    handleDelete,
    handleProfileEdit,
    handleAddTraveller,
    handleEditTraveller,
    handleDeleteTraveller,
    formHookAddTraveler,
    formHookChangePassword,
    formHookDelete,
    formHookEditProfile,
    formHookEditTraveller,
    changePasswordError,
    hasPhoneChange,
    formHookOTP,
    showOtp,
    handlePhoneRegistration,
    handleWrongPhone,
  } = useAccount();

  return (
    <>
      <div className="space-y-10">
        <div className="space-y-4">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between gap-4 md:gap-0">
            <h3 className="text-4xl md:text-5xl font-bold">My Dorrus</h3>
            <div className="flex gap-2">
              <Button
                disabled={travellers.length >= MAX_COMPANIONS}
                onClick={showAddTravelerModal}
                kind="secondary"
                className="min-w-max"
              >
                Add Companion
              </Button>
            </div>
          </div>
          <p>View and manage your account settings and preferences so we can provide a custom travel experience</p>
          <div className=" rounded-2xl  flex gap-4 flex-col">
            <Disclosure defaultOpen>
              <DisclosureButton className="font-bold text-dorrus-text-primary text-lg flex items-center justify-between">
                <span>Primary Traveller</span>
                <Button
                  kind="tertiary"
                  onClick={(e) => {
                    e.stopPropagation();
                    showEditProfileModal();
                  }}
                  className="font-normal"
                >
                  Edit
                </Button>
              </DisclosureButton>
              <DisclosurePanel className="p-0 bg-white rounded-lg shadow-sm overflow-hidden">
                <table className="w-full">
                  <tbody>
                    <tr className="border-b border-b-gray-200">
                      <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                        Full Name
                      </th>
                      <td>{getFullNameFromFields(user ?? {})}</td>
                    </tr>
                    <tr className="border-b border-b-gray-200">
                      <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                        Phone number
                      </th>
                      <td>{formatPhoneNumberIntl(user?.phoneNumber ?? '')}</td>
                    </tr>
                    <tr className="border-b border-b-gray-200">
                      <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                        Date of birth
                      </th>
                      <td>{user?.dob ? format(parseISO(user.dob ?? ''), 'P') : '----'}</td>
                    </tr>
                    <tr>
                      <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                        Gender
                      </th>
                      <td>{user?.gender}</td>
                    </tr>
                  </tbody>
                </table>
              </DisclosurePanel>
            </Disclosure>
            {fetchingTravellers && <p>Loading Companions...</p>}
            {travellers?.length > 0 &&
              !fetchingTravellers &&
              travellers?.map((traveller, index) => (
                <Disclosure key={index}>
                  {({ open }) => (
                    <>
                      <DisclosureButton className=" text-dorrus-text-primary text-lg flex items-center justify-between">
                        <span className="text-left md:text-center py-3">
                          <span>Companion</span> <b>{getFullNameFromFields(traveller)}</b>
                        </span>

                        <div className="flex items-center gap-2">
                          {open && (
                            <FadeIn className="flex items-center gap-2" y="0">
                              <Button
                                kind="tertiary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  showEditTravellerModal(traveller?.id);
                                }}
                                className="text-lg font-normal"
                              >
                                Edit
                              </Button>
                              <Button
                                kind="secondary"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteTraveller(traveller.id!);
                                }}
                                className="text-lg font-normal"
                              >
                                Delete
                              </Button>
                            </FadeIn>
                          )}
                          <div>
                            <ChevronIcon
                              className={`transition-all ${open ? 'rotate-90' : '-rotate-90'}`}
                              width={20}
                              height={20}
                            />
                          </div>
                        </div>
                      </DisclosureButton>
                      <AnimatePresence>
                        {open && (
                          <DisclosurePanel
                            as={FadeIn}
                            static
                            y="0"
                            className="p-0 bg-white shadow-sm rounded-lg overflow-hidden"
                          >
                            <table className="w-full">
                              <tbody>
                                <tr className="border-b border-b-gray-200">
                                  <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                                    Full Name
                                  </th>
                                  <td>{getFullNameFromFields(traveller ?? {})}</td>
                                </tr>
                                <tr className="border-b border-b-gray-200">
                                  <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                                    Phone number
                                  </th>
                                  <td>{formatPhoneNumberIntl(traveller?.phoneNumber ?? '')}</td>
                                </tr>
                                <tr className="border-b border-b-gray-200">
                                  <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                                    Email
                                  </th>
                                  <td>{traveller?.email}</td>
                                </tr>
                              </tbody>
                            </table>
                          </DisclosurePanel>
                        )}
                      </AnimatePresence>
                    </>
                  )}
                </Disclosure>
              ))}
          </div>
        </div>
        <div className="space-y-4">
          <CardsList />
        </div>
        <div className="space-y-4">
          <ExternalLoyaltyList />
        </div>
        <div className="space-y-4">
          <div className="flex">
            <h3 className="text-4xl md:text-5xl font-bold">Account Security</h3>
          </div>
          <p>To protect your account, sensitive data changes may require assistance from our customer care team</p>
          <div className="bg-white rounded-2xl shadow-sm">
            <table className="w-full">
              <tbody>
                <tr className="border-b border-b-gray-200">
                  <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                    Email
                  </th>
                  <td>{user?.email}</td>
                </tr>
                <tr>
                  <th align="left" className="pl-4 py-3 w-48 text-dorrus-text-secondary">
                    Password
                  </th>
                  <td>***********</td>
                  <td className="px-4 py-3" align="right">
                    {user?.authType === 'local' && (
                      <button onClick={showChangePasswordModal} className="font-bold cursor-pointer">
                        Change Password
                      </button>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <Button onClick={showDeleteModal} kind="secondary" className="max-w-fit">
            Delete Account
          </Button>
        </div>
      </div>
      <BaseModal header="Add Companion" open={addTravellerModal} onClose={showAddTravelerModal}>
        <div>
          <HookedForm
            formHook={formHookAddTraveler}
            onSubmit={(values) => {
              handleAddTraveller(values);
            }}
            className="space-y-4"
          >
            <Input
              name="firstName"
              placeholder="First Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="middleName"
              placeholder="Middle Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Phone
              name="phoneNumber"
              placeholder="Phone number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="email"
              placeholder="Email"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <div className="flex gap-4">
              <Button type="button" kind="primary" onClick={showAddTravelerModal} className="border-0">
                Cancel
              </Button>
              <Button kind="secondary" type="submit">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>

      <BaseModal header="Delete account" open={deleteModal} onClose={showDeleteModal}>
        <div>
          <HookedForm formHook={formHookDelete} onSubmit={handleDelete} className="space-y-4">
            <Radio
              id="too-many"
              value="I get too many emails or notifications"
              name="whyDelete"
              label="I get too many emails or notifications"
            />
            <Radio
              id="another-email"
              value="I have an account with another email"
              name="whyDelete"
              label="I have an account with another email"
            />
            <Radio
              id="trip-over"
              value="My trip is over, so I don't need this account"
              name="whyDelete"
              label="My trip is over, so I don't need this account"
            />
            <Radio
              id="negative"
              value="I had a negative booking experience"
              name="whyDelete"
              label="I had a negative booking experience"
            />
            <Radio value="Other" id="other" name="whyDelete" label="Other" />
            {formHookDelete.watch('whyDelete') === 'Other' && (
              <Input
                disabled={formHookDelete.watch('whyDelete') !== 'Other'}
                name="whyDeleteNote"
                placeholder="Specify the reason for deleting your account"
                className="border border-dorrus-button-border rounded-lg w-full p-4 !accent-dorrus-background-secondary !text-dorrus-background-secondary"
              />
            )}
            <div className="flex gap-4">
              <Button type="button" kind="primary" onClick={showDeleteModal} className="border-0">
                Cancel
              </Button>
              <Button kind="secondary" type="submit">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
      <BaseModal header="Change password" open={changePasswordModal} onClose={showChangePasswordModal}>
        <div className="text-left">
          <HookedForm formHook={formHookChangePassword} onSubmit={handleChangePassword} className="space-y-4">
            <Input
              type="password"
              name="oldPassword"
              placeholder="Old Password"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              type="password"
              name="newPassword"
              placeholder="New Password"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              description="Your password must have a Capital Letter, a number and a special character. Minimum 8 characters."
            />
            {changePasswordError && <p className="font-bold text-red-500">{changePasswordError}</p>}
            <div className="flex gap-4">
              <Button type="button" kind="primary" onClick={showChangePasswordModal} className="border-0">
                Cancel
              </Button>
              <Button kind="secondary" type="submit">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>

      <BaseModal header="Edit Primary Traveller" open={editProfileModal} onClose={showEditProfileModal}>
        <div>
          {!hasPhoneChange && (
            <HookedForm formHook={formHookEditProfile} onSubmit={handleProfileEdit} className="space-y-4">
              <Input
                name="firstName"
                placeholder="First Name"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <Input
                name="middleName"
                placeholder="Middle Name"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <Input
                name="lastName"
                placeholder="Last Name"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <Phone
                name="phoneNumber"
                placeholder="Phone Number"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <DatePicker name="dob" maxDate={new Date()} openToDate={sub(new Date(), { years: 18 })} />
              <Select value={user?.gender ?? undefined} name="gender" placeholder="Gender" options={genderSelection} />
              <div className="flex gap-4">
                <Button type="button" kind="primary" onClick={showEditProfileModal} className=" border-0">
                  Cancel
                </Button>
                <Button kind="secondary" type="submit">
                  Save
                </Button>
              </div>
            </HookedForm>
          )}
          {hasPhoneChange && (
            <HookedForm formHook={formHookOTP} onSubmit={handlePhoneRegistration} className="w-full space-y-4">
              <p>We need to confirm this number belongs to you before changing it.</p>
              <div>
                <Phone
                  disabled={showOtp}
                  defaultValue={formHookEditProfile.watch('phoneNumber')}
                  placeholder="Enter cell phone number"
                  name="phoneNumber"
                  className="border border-dorrus-button-border rounded-lg w-full p-4"
                />
                {showOtp && (
                  <div
                    className="block font-bold text-xs text-right cursor-pointer"
                    onClick={() => {
                      handleWrongPhone();
                    }}
                  >
                    <span>Wrong number?</span>
                  </div>
                )}
              </div>

              {showOtp && (
                <Input
                  placeholder="Enter the received OTP"
                  name="otp"
                  className="border border-dorrus-button-border rounded-lg w-full p-4"
                />
              )}

              <Button type="submit" disabled={!formHookOTP.watch('otp')} kind="secondary">
                Validate OTP
              </Button>
              <small className="text-dorrus-text-secondary text-justify block">
                By providing your mobile number, you consent to receive transactional messages from Dorrus. Message and
                data rates may apply. Your information will be handled according to our{' '}
                <Link to="/legal/privacy-policy" className="underline">
                  Privacy Policy
                </Link>
                .
              </small>
            </HookedForm>
          )}
        </div>
      </BaseModal>
      <BaseModal header="Edit Companion" open={Boolean(editTravellerModal)} onClose={() => showEditTravellerModal()}>
        <div>
          <HookedForm formHook={formHookEditTraveller} onSubmit={handleEditTraveller} className="space-y-4">
            <Input
              name="firstName"
              placeholder="First Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="middleName"
              placeholder="Middle Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="lastName"
              placeholder="Last Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Phone
              name="phoneNumber"
              placeholder="Phone Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="email"
              placeholder="Email Address"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />

            <div className="flex gap-4">
              <Button kind="primary" onClick={() => showEditTravellerModal()} className="  border-0">
                Cancel
              </Button>
              <Button kind="secondary" type="submit">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
    </>
  );
};
