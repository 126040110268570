import { hotelCodes } from '@app/consts/consts';
import { ExternalLoyaltyList } from '../ExternalLoyaltyList';
import { Input, Searchable, Select } from '../Fields';
import { useSelectExternalLoyalty } from './SelectExternalLoyalty.hooks';
import { Button } from '../Button';
import { BaseModal } from '../BaseModal';
import { HookedForm } from '../HookedForm';

export const SelectExternalLoyalty = ({ hotelSelected }: { hotelSelected?: string }) => {
  const {
    externalLoyalties,
    existingMembership,
    formHookExternalLoyalty,
    addExternalLoyaltyError,
    showExternalLoyaltyModal,
    addNewExternalLoyaltyModal,
    handleAddExternalLoyalty,
  } = useSelectExternalLoyalty(hotelSelected);

  return (
    <>
      <div>
        {externalLoyalties && externalLoyalties.length > 0 ? (
          <div className="space-y-6">
            <div className="space-y-4 flex-col md:flex-row flex gap-2 justify-between">
              <div>
                <h3 className="text-4xl md:text-5xl font-bold">Loyalty Affiliations</h3>
                <p>Choose your travel loyalty credentials to maximize rewards on each booking</p>
              </div>
              <Button className="!w-fit" kind="secondary" onClick={showExternalLoyaltyModal}>
                Add loyalty affiliation
              </Button>
            </div>
            <Select
              className="bg-white"
              name="externalProvider"
              value={existingMembership}
              options={externalLoyalties.map((externalLoyalty) => ({
                value: externalLoyalty.providerCode,
                label: `${hotelCodes[externalLoyalty.providerCode as keyof typeof hotelCodes]} - N. ${externalLoyalty.providerAccountValue}`,
              }))}
            />
          </div>
        ) : (
          <ExternalLoyaltyList />
        )}
      </div>
      <BaseModal header="Add External Loyalty" open={addNewExternalLoyaltyModal} onClose={showExternalLoyaltyModal}>
        <div>
          <HookedForm
            id="externalLoyaltyForm"
            formHook={formHookExternalLoyalty}
            onSubmit={handleAddExternalLoyalty}
            mode="onSubmit"
            className="space-y-4"
          >
            <Searchable
              name="providerCode"
              placeholder="Provider"
              showLabelAsValue
              value={formHookExternalLoyalty.watch('providerCode')}
              onChange={(value) => {
                formHookExternalLoyalty.setValue('providerCode', value);
              }}
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              options={Object.keys(hotelCodes)
                .sort()
                .map((key) => ({
                  label: hotelCodes[key as keyof typeof hotelCodes],
                  value: key,
                }))}
            />
            <Input
              name="providerAccountValue"
              placeholder="Account Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              type="hidden"
              name="providerTypeCode"
              value={Object.values(hotelCodes).find(
                (provider) => provider === formHookExternalLoyalty.watch('providerCode'),
              )}
            />
            {addExternalLoyaltyError && <p className="font-bold text-dorrusAlerts-red">{addExternalLoyaltyError}</p>}
            <div className="flex gap-4">
              <Button kind="primary" onClick={showExternalLoyaltyModal} className=" border-0">
                Cancel
              </Button>
              <Button form="externalLoyaltyForm" type="submit" kind="secondary">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
    </>
  );
};
