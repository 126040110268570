import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/react';
import { Fragment, ReactElement, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

export const Tabs = ({
  tabs,
  children,
  activeTab,
}: {
  tabs: string[];
  children: ReactElement[] | React.ReactNode[];
  activeTab?: (index: number) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams({ tab: tabs[0] });
      setSelectedTab(0);
    } else {
      const index = tabs.findIndex((tab) => tab === searchParams.get('tab'));

      setSearchParams({ tab: tabs[index] });
      setSelectedTab(index);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  return (
    <TabGroup
      selectedIndex={selectedTab}
      onChange={(index) => {
        activeTab && activeTab(index);

        setSearchParams({ tab: tabs[index] });
      }}
    >
      <TabList className="border-b border-b-[#E5E7EB] flex gap-6 overflow-x-scroll no-scrollbar">
        {tabs.map((tab, index) => (
          <Tab key={index} as={Fragment}>
            {({ selected }) => (
              <button
                className={
                  selected
                    ? 'focus:outline-none focus:ring-0 py-4 text-dorrus-text-primary text-lg relative after:absolute after:z-20 after:bottom-0 after:left-0 after:w-full after:h-[1px] after:bg-dorrus-background-secondary after:content-[""]'
                    : 'focus:outline-none focus:ring-0 py-4 text-dorrus-text-secondary text-lg'
                }
              >
                {tab}
              </button>
            )}
          </Tab>
        ))}
      </TabList>
      <TabPanels className="py-6">
        {children.map((child, index) => (
          <TabPanel key={index}>{child}</TabPanel>
        ))}
      </TabPanels>
    </TabGroup>
  );
};
