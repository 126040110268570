import { useParams } from 'react-router-dom';
import { useVerifyEmail } from './VerifyEmail.hooks';
import DorrusLogo from '@app/assets/dorrus-logo.svg?react';
import { Loading } from '@app/components/Loading/Loading';

export const VerifyEmail = () => {
  const { token } = useParams();
  const { error, inProgress } = useVerifyEmail();

  return (
    <div className="flex flex-col items-center justify-between h-screen relative">
      <DorrusLogo className="mb-auto" />
      <div className="mb-auto">
        <div className="mx-auto">
          <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">Email Verification</h1>
        </div>

        <div className="flex justify-center items-center">
          {(error || !token) && (
            <div>
              <p className="text-lg text-dorrus-text-primary text-center">
                It looks like the token you are trying to use is no longer valid.
              </p>
            </div>
          )}
          {inProgress && (
            <div className="flex items-center gap-6">
              <Loading width={50} />
              <p className="text-lg text-dorrus-text-primary text-center">Validation in progress</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
