import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import RegisterNowBackground from '@app/assets/register-now-bg.svg?react';

export const RegisterNowBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-dorrus-background-secondary text-white p-10 rounded-2xl relative overflow-hidden md:min-h-[220px]">
      <div className="absolute inset-0 h-full w-full">
        <RegisterNowBackground className="absolute inset-0 object-cover w-full h-full" />
      </div>
      <div className="space-y-4 relative">
        <h5 className="text-4xl md:text-5xl font-bold">Register now and get more discounts</h5>
        <p className="text-dorrus-text-secondary w-full md:w-[70%]">
          As a member, you&apos;ll enjoy even greater savings on top of our already competitive rates, making your
          travel experiences more affordable. Join today and start earning rewards for future stays while getting access
          to special offers available only to our members.
        </p>
        <div className="flex gap-4">
          <Button onClick={() => navigate('/login')} kind="tertiary" className="text-dorrus-background-secondary">
            Join today!
          </Button>
        </div>
      </div>
    </div>
  );
};
