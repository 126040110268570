import { useUser } from '@app/context/user';
import { verifyEmail } from '@app/services/user';
import { localStorageHandler } from '@app/utils/utils';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

export const useVerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();
  const { userDispatch } = useUser();
  const [inProgress, setInProgress] = useState(false);
  const [finish, setFinish] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const handleToken = async () => {
    setInProgress(() => true);
    await verifyEmail(token!)
      .then((res) => {
        localStorageHandler.set('dtoken', res.data.token);
        userDispatch({ type: 'setFetching', fetching: true });
        navigate('/registration/details');
      })
      .catch((err) => {
        setError(err?.response?.data?.error);
      })
      .finally(() => {
        setInProgress(() => false);
        setFinish(true);
      });
  };

  useEffect(() => {
    if (token && !finish) {
      handleToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return { error, inProgress };
};
