import { LodgingRoomInterface } from '@app/models/booking';
import { useBooking } from '@app/context/booking';
import { HotelBookingInterface } from '@app/models/orders';
import { cancelBooking, getBookingById, getHotelRatesByDate, updateBooking } from '@app/services/booking';
import { postPredictPoints } from '@app/services/loyalty';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { differenceInMilliseconds, format } from 'date-fns';
import { HotelRoomPlanInterface } from '@app/models/search';
import { RoomCardProps } from '@app/components/RoomCard/RoomCard.interfaces';
import { toast } from 'react-toastify';

export const useBookingManagement = () => {
  const { id, uuid } = useParams();
  const navigate = useNavigate();
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelError, setCancelError] = useState(false);
  const [cancelSuccess, setCancelSuccess] = useState(false);
  const [showChangeDate, setShowChangeDate] = useState(false);
  const [pointsPrediction, setPointsPrediction] = useState<number>(0);
  const [isLoadingPoints, setIsLoadingPoints] = useState<boolean>(false);
  const [availableNewRates, setAvailableNewRates] = useState([]);
  const { bookingsDispatch } = useBooking();
  const [isUpdatingBooking, setIsUpdatingBooking] = useState(false);
  const [isCancelling, setIsCancelling] = useState(false);
  const {
    data: bookingDetails,
    isLoading,
    isFetching,
    refetch,
  } = useQuery({
    queryKey: ['hotel-details'],
    queryFn: () =>
      getBookingById(id as string, uuid as string)
        .then((res) => res.data as HotelBookingInterface | undefined)
        .catch((err) => {
          toast.error(
            err?.response?.data?.error ??
              err?.data?.error ??
              'An error occurred while making the request, try again later.',
          );
          toast.clearWaitingQueue();
        }),
    enabled: Boolean(id),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const orderServices = bookingDetails?.order?.orderItems?.[0]?.services?.[0] ?? undefined;
  const orderLoadgingBookings = bookingDetails?.order?.orderItems?.[0]?.lodgingBookings?.[0] ?? undefined;
  const isCanceled = bookingDetails?.order?.orderStatusCode === 'CLOSED';
  const isPastBooking =
    orderLoadgingBookings?.checkOutUTC &&
    differenceInMilliseconds(new Date(orderLoadgingBookings?.checkOutUTC as string), new Date()) < 0;
  const formHook = useForm();
  const changeDateFormHook = useForm();

  const { isPending, mutate } = useMutation({
    mutationKey: ['hotel-details-update'],
    mutationFn: (values: FieldValues) =>
      getHotelRatesByDate(orderLoadgingBookings?.sourcePropertyID as string, 'GLOBAL', false, {
        adults: Number(orderServices?.lodgingRooms?.[0]?.qtyAdults ?? 1),
        children: Number(orderServices?.lodgingRooms?.[0]?.qtyChildren ?? 0),
        inDate: format(values?.newCheckIn, 'yyyy-MM-dd'),
        outDate: format(values?.newCheckOut, 'yyyy-MM-dd'),
        ...(Number(orderServices?.lodgingRooms?.[0]?.qtyChildren) > 0 && {
          ChildAges: Array.from({ length: orderServices?.lodgingRooms?.[0]?.qtyChildren ?? 0 }).map(() => 1),
        }),
      })
        .then((res) => setAvailableNewRates(res?.data?.rateResults?.roomsAvail ?? []))
        .catch((e) => console.error(e)),
    retry: false,
  });

  const cancelOrder = () => {
    if (!bookingDetails?.confirmationID) return;
    setCancelError(false);
    setIsCancelling(true);
    cancelBooking(`${bookingDetails.confirmationID}/${uuid}`)
      .then(() => setCancelSuccess(true))
      .catch(() => setCancelError(true))
      .finally(() => {
        bookingsDispatch({ type: 'setFetching', fetching: true });
        setIsCancelling(false);
      });
  };

  const handleChangeDateModal = () => {
    if (isCanceled || isPastBooking) {
      navigate(`/details/rate?hotelId=${orderLoadgingBookings?.sourcePropertyID}`);
    }
    if (availableNewRates.length > 0) {
      setAvailableNewRates([]);
    }
    setShowChangeDate(!showChangeDate);
  };

  const handleCancelModal = () => {
    setCancelModal(!cancelModal);
  };

  const countGuests = (lodgingRooms?: LodgingRoomInterface[]) => {
    if (lodgingRooms?.length === 0) return 0;
    return lodgingRooms?.reduce(
      (acc, room) =>
        acc + Number(room?.qtyAdults ?? 0) + Number(room?.qtyChildren ?? 0) + Number(room?.qtyInfants ?? 0),
      0,
    );
  };

  const handleChangeDateSubmit = (values: FieldValues) => {
    if (!orderLoadgingBookings?.sourcePropertyID) return;
    mutate(values);
  };

  const updateRateDate = async (rate?: Partial<HotelRoomPlanInterface & RoomCardProps>) => {
    if (!rate || !bookingDetails?.confirmationID) return;
    const values = changeDateFormHook.getValues();
    setIsUpdatingBooking(true);
    await updateBooking(`${bookingDetails?.confirmationID}/${uuid}`, {
      checkInDate: format(values.newCheckIn, 'yyyy-MM-dd'),
      checkOutDate: format(values.newCheckOut, 'yyyy-MM-dd'),
      sourceRateCode: rate?.sourceRateKey as string,
      roomProductCode: rate?.productCode as string,
    })
      .then(() => {
        refetch();
        handleChangeDateModal();
        bookingsDispatch({ type: 'setFetching', fetching: true });
      })
      .catch((e) => console.error(e))
      .finally(() => {
        setIsUpdatingBooking(false);
      });
  };

  if (!id) {
    navigate('/my-trips');
  }

  useEffect(() => {
    if (bookingDetails && orderLoadgingBookings && id) {
      setIsLoadingPoints(true);
      postPredictPoints({
        checkInDate: orderLoadgingBookings?.checkInUTC ?? '',
        checkOutDate: orderLoadgingBookings?.checkOutUTC ?? '',
        orderIdText: id,
      })
        .then((res) => {
          setPointsPrediction(res?.data?.points ?? 0);
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          setIsLoadingPoints(false);
        });
    } else {
      setPointsPrediction(-1);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingDetails, orderLoadgingBookings]);

  return {
    orderServices,
    orderLoadgingBookings,
    bookingDetails,
    isLoading: isLoading || isFetching,
    cancelOrder,
    cancelModal,
    handleCancelModal,
    cancelError,
    cancelSuccess,
    isCancelling,
    formHook,
    navigate,
    countGuests,
    pointsPrediction,
    handleChangeDateModal,
    showChangeDate,
    changeDateFormHook,
    handleChangeDateSubmit,
    availableNewRates,
    isPendingDateChange: isPending,
    updateRateDate,
    isUpdatingBooking,
    isCanceled,
    isPastBooking,
    isLoadingPoints,
  };
};
