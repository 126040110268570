import { initialValues } from './utils';

import type { Action, State } from './types';

export function userReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setData': {
      return {
        ...state,
        user: action.user,
        fetchingUserData: false,
      };
    }
    case 'setCards': {
      return {
        ...state,
        cards: action.cards,
      };
    }
    case 'setLoyalties': {
      return {
        ...state,
        externalLoyalties: action.externalLoyalties,
      };
    }
    case 'logout': {
      return {
        ...initialValues,
      };
    }
    case 'setAuthenticated': {
      return {
        ...state,
        isAuthenticated: action.authenticated,
      };
    }
    case 'setFetching': {
      return {
        ...state,
        fetchingUserData: action.fetching,
      };
    }
    case 'setLoyaltyLevels': {
      return {
        ...state,
        loyaltyLevels: action.loyaltyLevels,
      };
    }
    case 'setFetchingLoyaltyLevels': {
      return {
        ...state,
        fetchingLoyaltyLevels: action.fetching,
      };
    }
    case 'setFetchingLoyalties': {
      return {
        ...state,
        fetchingUserLoyalties: action.fetching,
      };
    }
    case 'setFetchingCards': {
      return {
        ...state,
        fetchingUserCards: action.fetching,
      };
    }
    case 'updateProfileDetails': {
      return {
        ...state,
        user: {
          ...state.user,
          ...action.profileDetails,
        },
      };
    }
    case 'setUnitOfMeasure': {
      return {
        ...state,
        language: {
          ...state.language,
          unitOfMeasure: action.unitOfMeasure,
        },
      };
    }
    case 'setUserLanguage': {
      return {
        ...state,
        language: {
          ...state.language,
          ...action.language,
        },
      };
    }
    default: {
      throw new Error('Error - unknown action type');
    }
  }
}
