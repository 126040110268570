import { AlertInterface } from '@app/models/general';
import { getAlertDisplay, getAlertStyleBySeverity } from '@app/utils/utils';
import AlertIcon from '@app/assets/icons/alert.svg?react';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { useEffect, useState } from 'react';

export const Alert = ({ alert, user }: { alert: AlertInterface; user?: boolean }) => {
  const [shouldDisplay, setShouldDisplay] = useState('none');
  const [localAlerts, setLocalAlerts] = useState<{ alertId: number }[]>([]);

  const colors = getAlertStyleBySeverity(alert.severity);

  const handleClose = () => {
    const localStorageAlerts = JSON.parse(localStorage.getItem('dAlerts') ?? '[]');
    const updateAlerts = [...localStorageAlerts, ...localAlerts];

    updateAlerts.push({ alertId: alert.alertID });
    localStorage.setItem('dAlerts', JSON.stringify(updateAlerts));
    setLocalAlerts(updateAlerts);
  };

  useEffect(() => {
    setShouldDisplay(getAlertDisplay(alert, user, localAlerts));
    const alertsLocal = localStorage.getItem('dAlerts');

    setLocalAlerts(alertsLocal ? JSON.parse(alertsLocal) : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (localAlerts.some((localAlert) => alert.alertID === localAlert.alertId)) {
      setShouldDisplay('none');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localAlerts]);

  return (
    <div
      className="px-6 py-4 font-bold shadow-md flex gap-2 justify-between items-center"
      style={{ background: colors.background, color: colors.color, display: shouldDisplay }}
    >
      <div className="flex gap-2 items-center">
        <AlertIcon style={{ fill: 'white' }} width={25} height={25} />
        <div>
          <p>{alert.alertText}</p>
        </div>
      </div>
      <CloseIcon onClick={handleClose} width={15} height={15} style={{ fill: colors.color, cursor: 'pointer' }} />
    </div>
  );
};
