import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSearch } from '@app/context/search';
import { useSearchParams } from 'react-router-dom';
import { updateRateValidation } from '@app/utils/validations';
import { formatISO } from 'date-fns';

export const useRoomAvailabilityChanger = (refetch?: () => void) => {
  const [searchParams] = useSearchParams();
  const { searchDispatch, searchParams: searchParamsContext } = useSearch();
  const [totalGuests, setTotalGuests] = useState(0);
  const [showDropdown, setShowDropdown] = useState(false);

  const formHook = useForm({
    defaultValues: {
      adults: String(searchParams.get('adults') ?? searchParamsContext?.adults ?? 1),
      children: String(searchParams.get('children') ?? searchParamsContext?.children ?? 0),
      dateIn: new Date(searchParams.get('inDate') ?? searchParamsContext?.inDate ?? new Date()).toISOString() ?? '',
      dateOut: new Date(searchParams.get('outDate') ?? searchParamsContext?.outDate ?? new Date()).toISOString() ?? '',
      childAges: searchParamsContext?.ChildAges ?? undefined,
    },
    resolver: updateRateValidation,
  });

  const dateInValue = formHook.watch('dateIn');
  const adultGuests = formHook.watch('adults');
  const childGuests = formHook.watch('children');
  const childAges = formHook.watch('childAges');

  useEffect(() => {
    if (dateInValue) {
      document.getElementById('dateOut')?.click();
    }
  }, [dateInValue]);

  const getTotalGuests = () => {
    const adults = adultGuests ?? 0;
    const children = childGuests ?? 0;

    setTotalGuests(Number(adults) + Number(children));
  };

  const updateSearch = (values: FieldValues) => {
    searchDispatch({
      type: 'updateSearchParams',
      clearFilter: true,
      updateSearchParams: {
        adults: Number(values?.adults),
        children: Number(values?.children ?? 0),
        inDate: values?.dateIn ? formatISO(values.dateIn, { representation: 'date' }) : '',
        outDate: values?.dateOut ? formatISO(values.dateOut, { representation: 'date' }) : '',
        ...(Number(childGuests) > 0 && { ChildAges: values.childAges }),
      },
    });
    if (refetch) {
      refetch();
    }
  };

  const handleAdd = (field: 'adults' | 'children', limit?: number) => {
    const value = formHook.getValues(field);

    if (limit && Number(value) < limit) {
      formHook.setValue(field, String(Number(value) + 1));
      if (field === 'children') {
        formHook.setValue(
          'childAges',
          Array.from({ length: Number(value ?? 0) + 1 }).map(() => 0),
        );
      }
      return;
    }
    formHook.setValue(field, String(Number(value) + 1));
    if (field === 'children' && childGuests && Number(childGuests) === 0) {
      formHook.setValue(
        'childAges',
        Array.from({ length: Number(value ?? 0) + 1 }).map(() => 1),
      );
    } else {
      formHook.setValue('childAges', childAges ? [...childAges, 1] : [1]);
    }
    return;
  };

  const handleRemove = (field: 'adults' | 'children') => {
    const value = formHook.getValues(field);

    if (Number(value) > 0) {
      formHook.setValue(field, String(Number(value) - 1));
      if (field === 'children') {
        formHook.setValue('childAges', childAges?.slice(0, -1));
      }
      return;
    }
  };

  const handleRemoveChildAge = (index: number) => {
    const value = formHook.getValues(`childAges.${index}`);

    if (Number(value) > 0) {
      formHook.setValue(`childAges.${index}`, Number(value) - 1);
      return;
    }
  };

  const handleAddChildAge = (index: number, limit?: 18) => {
    const value = formHook.getValues(`childAges.${index}`);

    if (limit && Number(value) < limit) {
      formHook.setValue(`childAges.${index}`, Number(value) + 1);
      return;
    }
    formHook.setValue(`childAges.${index}`, Number(value) + 1);
    return;
  };

  useEffect(() => {
    getTotalGuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultGuests, childGuests]);

  return {
    formHook,
    handleAdd,
    handleRemove,
    handleRemoveChildAge,
    handleAddChildAge,
    totalGuests,
    adultGuests,
    childGuests,
    showDropdown,
    setShowDropdown,
    updateSearch,
  };
};
