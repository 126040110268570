import { MarkerCluster } from '@app/components/MarkerCluster/MarkerCluster';
import { SearchResultsInterface } from '@app/models/search';
import { Map } from '@vis.gl/react-google-maps';
import { useSearchParams } from 'react-router-dom';

const mapId = Math.floor(Math.random() * Date.now()).toString(16);

export const ResultsMap = ({ results }: { results?: SearchResultsInterface[] }) => {
  const [searchParams] = useSearchParams();

  return (
    <div className="h-[500px] rounded-lg overflow-hidden">
      {searchParams && (
        <Map
          mapId={mapId}
          defaultZoom={10}
          defaultCenter={{
            lat: searchParams.get('searchLatitude') ? Number(searchParams.get('searchLatitude')) : 0,
            lng: searchParams.get('searchLongitude') ? Number(searchParams.get('searchLongitude')) : 0,
          }}
          gestureHandling={'greedy'}
          disableDefaultUI
        >
          <MarkerCluster points={results ?? []} />
        </Map>
      )}
    </div>
  );
};
