import { useUser } from '@app/context/user';
import { getMemberBonus, postMemberBonus } from '@app/services/loyalty';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

export const usePromotion = () => {
  const [searchParams] = useSearchParams();
  const { user } = useUser();
  const [disableForm, setDisableForm] = useState(false);
  const [noUser, setNoUser] = useState(false);
  const [terms, setTerms] = useState();
  const [succesRegistration, setSuccessRegistration] = useState(false);
  const [errorRegistration, setErrorRegistration] = useState();
  const formHook = useForm();

  const getPromotionInformation = () => {
    const promoCode = searchParams.get('promoCode');
    if (promoCode) {
      getMemberBonus(promoCode).then((res) => {
        if (res?.data?.bonus?.terms_conditions) {
          setTerms(res.data.bonus?.terms_conditions);
        }
      });
    }
  };

  const handleSubmit = (values?: FieldValues) => {
    if (!values?.promoCode) return;
    setSuccessRegistration(false);
    setErrorRegistration(undefined);
    postMemberBonus(values as { promoCode: string })
      .then((res) => {
        if (res?.data?.success) {
          setSuccessRegistration(true);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorRegistration(error?.response?.data?.error);
      });
  };

  useEffect(() => {
    if (!user?.firstName) {
      setDisableForm(true);
    } else {
      setNoUser(false);
      setDisableForm(false);
    }
  }, [user]);

  useEffect(() => {
    if (searchParams.get('promoCode') && !user?.firstName) {
      setNoUser(true);
      formHook.reset({ promoCode: searchParams.get('promoCode') });
    }
  }, [searchParams]);

  useEffect(() => {
    getPromotionInformation();
    if (searchParams.get('promoCode') && !user?.firstName) {
      setNoUser(true);
      formHook.reset({ promoCode: searchParams.get('promoCode') });
    }
  }, []);

  return { disableForm, noUser, formHook, handleSubmit, terms, succesRegistration, errorRegistration };
};
