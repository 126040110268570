import { useUser } from '@app/context/user';
import { useEffect } from 'react';

export const Scripts = () => {
  const { user } = useUser();

  useEffect(() => {
    const frontChat = document.getElementById('front-chat');
    if (!frontChat) {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'front-chat';
      if (user && user?.email) {
        script.innerHTML = `window.FrontChat('init', {chatId: '8bee36c4ca77cc8c1f1ebedc657e089d', useDefaultLauncher: true});`;
      } else {
        script.innerHTML = `window.FrontChat('init', {chatId: '3ed8002659cb2c6d498f8c0759ac89fe', useDefaultLauncher: true});`;
      }
      document.getElementById('scripts')?.appendChild(script);
    }
  }, [user]);

  return <div id="scripts" />;
};
