import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';

export const AddPhoneBanner = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-dorrus-background-secondary rounded-2xl overflow-hidden p-6 mb-10 flex justify-between">
      <div className=" relative text-white flex items-center">
        <p className="text-2xl font-bold">
          Add Your Phone Number Now to secure your account and
          <br /> streamline your booking experience!
        </p>
      </div>
      <div className=" text-white p-6 flex flex-col items-center justify-end">
        <Button
          type="button"
          kind="tertiary"
          onClick={() => navigate('/registration/phone?updating=true')}
          className="text-dorrus-background-secondary"
        >
          Click here
        </Button>
      </div>
    </div>
  );
};
