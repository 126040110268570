import { LoyaltyTransaction } from '@app/models/loyalty';
import { LoyaltyTransactionsRow } from '../LoyaltyTransactionsRow';

export const LoyaltyTransactions = ({ transactions }: { transactions: LoyaltyTransaction[] }) => {
  return (
    <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
      <table className="w-full border-collapse">
        <thead>
          <tr className="border-b border-b-gray-200">
            <td className="font-bold p-4 max-w-fit">Date</td>
            <td className="font-bold">Description</td>
            <td className="font-bold">Points</td>
            <td className="font-bold">EQP</td>
            <td className="font-bold">Status</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {transactions?.length === 0 && (
            <tr>
              <td className="p-4 text-dorrus-text-secondary" colSpan={5}>
                No transactions found for this account.
              </td>
            </tr>
          )}
          {transactions?.length > 0 &&
            transactions?.map((transaction, index) => <LoyaltyTransactionsRow key={index} transaction={transaction} />)}
        </tbody>
      </table>
    </div>
  );
};
