import { useUser } from '@app/context/user';
import { addCard } from '@app/services/tokenizer';
import { addUserCard, deleteUserCard } from '@app/services/user';
import { addCardValidation } from '@app/utils/validations';
import creditCardType from 'credit-card-type';
import { format } from 'date-fns';
import { BaseSyntheticEvent, useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

export const useCardsList = () => {
  const formHookCard = useForm({ resolver: addCardValidation });
  const { user, cards, userDispatch } = useUser();
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [deleteCardModal, setDeleteCardModal] = useState<string | undefined>();
  const [cardFieldLimit, setCardFieldLimit] = useState(20);
  const [addCardError, setAddCardError] = useState<string | undefined>();
  const [isSavingCard, setIsSavingCard] = useState(false);

  const showCardModal = () => {
    if (!addNewCardModal) formHookCard.reset();

    setAddNewCardModal(!addNewCardModal);
  };

  const showDeleteCardModal = (id?: string) => {
    setDeleteCardModal(id);
  };

  const handleDeleteCard = () => {
    if (!deleteCardModal) return;
    deleteUserCard(deleteCardModal)
      .then(() => {
        showDeleteCardModal();
      })
      .catch((e) => console.error(e))
      .finally(() => {
        userDispatch({ type: 'setFetchingCards', fetching: true });
      });
  };

  const handleAddCard = (values: FieldValues, event?: BaseSyntheticEvent) => {
    event?.preventDefault();
    event?.stopPropagation();
    setIsSavingCard(true);
    setAddCardError(undefined);
    addCard({
      cardholderName: values.cardholderName,
      card: values.card?.split(' ').join(''),
      expDate: format(values.expDate, 'yyyy-MM'),
      type: creditCardType(values.card)[0].niceType,
      ...(user?.id && { userId: String(user.id) }),
    })
      .then((res) => {
        return addUserCard(
          res.cardId,
          values.card.substring(values.card.length - 4),
          values.expDate,
          creditCardType(values.card)[0].niceType,
        )
          .then(() => {
            userDispatch({ type: 'setFetchingCards', fetching: true });
            showCardModal();
          })
          .catch((e) => {
            setAddCardError('There was an error saving this card to your account.');
            console.error(e);
          });
      })
      .catch((e) => {
        if (e?.response?.data?.error?.includes('card')) {
          setAddCardError('There was an error adding this card. Check its number and try again.');
        }
        console.error(e);
      })
      .finally(() => setIsSavingCard(false));
  };

  const newCardNumber = formHookCard.watch('card');

  useEffect(() => {
    if (newCardNumber && newCardNumber.length > 2) {
      const type = creditCardType(newCardNumber);

      if (type.length > 0) {
        setCardFieldLimit(Math.min(...type[0].lengths) + type[0].gaps?.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCardNumber]);

  return {
    cards,
    user,
    formHookCard,
    showCardModal,
    showDeleteCardModal,
    deleteCardModal,
    addNewCardModal,
    handleAddCard,
    handleDeleteCard,
    cardFieldLimit,
    addCardError,
    isSavingCard,
  };
};
