import { useUser } from '@app/context/user';
import { HotelRoomPlanInterface } from '@app/models/search';
import { Button } from '../Button';
import { RoomCardProps } from '../RoomCard/RoomCard.interfaces';
import InfoIcon from '@app/assets/icons/info.svg?react';
import { useSearch } from '@app/context/search';
import { differenceInDays } from 'date-fns';

export const RoomRateCard = ({
  rate,
  handleClick,
  setMoreDetailsModal,
}: {
  rate: HotelRoomPlanInterface;
  setMoreDetailsModal: (rate?: HotelRoomPlanInterface) => void;
  handleClick?: (rateInfo: Partial<HotelRoomPlanInterface & RoomCardProps>) => void;
}) => {
  const { searchParams } = useSearch();
  const { getPriceFormat } = useUser();

  return (
    <div className="flex flex-col justify-between shadow-md rounded-2xl p-6 space-y-4">
      <div className="flex justify-between">
        <div>
          <h5 className="font-bold">{rate.rateName}</h5>
          <small>{rate?.cancelPolicyText}</small>
          {!rate?.isRefundable && !rate?.cancelPolicyText && <small>Non-refundable</small>}
        </div>
        <div>
          <button onClick={() => setMoreDetailsModal(rate)}>
            <InfoIcon width={24} height={24} />
          </button>
        </div>
      </div>
      <div className="space-y-4 flex">
        <div>
          <span className="block text-dorrus-text-secondary">
            <p>{differenceInDays(searchParams?.outDate ?? '', searchParams?.inDate ?? '')} nights</p>{' '}
          </span>
          <span className="font-bold text-2xl">
            {getPriceFormat(rate?.totalRateNoTax ?? 0, true, rate?.currencyCode)}
          </span>
          <span className="block text-sm">
            {getPriceFormat(rate?.taxTotalAmount ?? 0, true, rate?.currencyCode)} taxes
          </span>
        </div>
        <Button
          kind="secondary"
          onClick={() => {
            rate?.sourceRateKey && handleClick && handleClick(rate);
          }}
          className="!w-fit !h-fit ml-auto"
        >
          Select
        </Button>
      </div>
    </div>
  );
};
