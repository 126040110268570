import * as React from "react";
const SvgUatp = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: 45, height: 30, xmlnsXlink: "http://www.w3.org/1999/xlink", id: "Layer_1", x: "0px", y: "0px", viewBox: "0 0 392.7 120.8", style: {
  enableBackground: "new 0 0 392.7 120.8",
  background: "black",
  borderRadius: 5,
  overflow: "hidden"
}, xmlSpace: "preserve", ...props }, /* @__PURE__ */ React.createElement("style", { type: "text/css" }, "\n    \n	.st0{fill:#FFFFFF;}\n	\n		.st1{clip-path:url(#SVGID_00000030449462757885392010000006176451158712778905_);fill:url(#SVGID_00000096738553476205982040000005208069946094222259_);}\n"), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("rect", { id: "SVGID_1_", x: -101.8, y: -360.6, width: 595.3, height: 841.9 })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_00000016797712051118382720000014952894036348714415_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_1_", style: {
  overflow: "visible"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_00000080891372559020326370000004314394747687681947_", d: "M232,23.3c-15.2,8.5-30.7,20.2-49.3,31.8      c-6.4,4-13.3,8-20.6,11.9c-19,10-41.4,19-69.3,24.6l0,1.9c88.5-5,136.7-50.2,175.6-67.9c46.2-21.1,79.3-15.4,91.5-13.7l0-1.6      c0,0-20.2-6.6-48.9-6.6C288.1,3.7,259.7,7.9,232,23.3" })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_00000136409601050250675760000018065423545865848969_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_00000080891372559020326370000004314394747687681947_", style: {
  overflow: "visible"
} }))), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M167.9,83.3l4.6,13.1l6.6,18.6H206l-15.9-41C183.1,77.3,175.7,80.4,167.9,83.3" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("defs", null, /* @__PURE__ */ React.createElement("path", { id: "SVGID_00000008119927809460848160000017750252865029939098_", d: "M232,23.2c-15.2,8.5-30.7,20.2-49.3,31.8      c-6.4,4-13.3,8-20.6,11.9c-19,10-41.4,19-69.3,24.6l0,1.9c88.5-5,136.7-50.2,175.6-67.9c46.2-21.1,79.3-15.4,91.5-13.7l0-1.6      c0,0-20.2-6.6-48.9-6.6C288.1,3.6,259.7,7.9,232,23.2" })), /* @__PURE__ */ React.createElement("clipPath", { id: "SVGID_00000107581518100763217440000004309405460637163671_" }, /* @__PURE__ */ React.createElement("use", { xlinkHref: "#SVGID_00000008119927809460848160000017750252865029939098_", style: {
  overflow: "visible"
} })), /* @__PURE__ */ React.createElement("linearGradient", { id: "SVGID_00000119099114970983566660000012704233281049958031_", gradientUnits: "userSpaceOnUse", x1: -101.8007, y1: 481.3232, x2: -100.8278, y2: 481.3232, gradientTransform: "matrix(274.5376 0 0 -274.5376 28040.9473 132189.8438)" }, /* @__PURE__ */ React.createElement("stop", { offset: 0, style: {
  stopColor: "#7AC143"
} }), /* @__PURE__ */ React.createElement("stop", { offset: 1, style: {
  stopColor: "#00A94F"
} })), /* @__PURE__ */ React.createElement("rect", { x: 92.8, y: 3.6, style: {
  clipPath: "url(#SVGID_00000107581518100763217440000004309405460637163671_)",
  fill: "url(#SVGID_00000119099114970983566660000012704233281049958031_)"
}, width: 267.1, height: 89.8 })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M7.5,17.1H32v57.3c0,16.2,6.6,21.8,17.6,21.8c6.8,0,11-0.7,15.5-1.3V17.1h24.6v94.4     c-8.4,2.6-27.5,5.1-40,5.1c-22.4,0-42.2-8.9-42.2-36.7V17.1z" }), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M336.1,115h-24.5V17.9c8.2-1.3,21.1-2.5,32-2.5c24.2,0,41.5,11.2,41.5,36.1c0,25.5-19.6,35.1-40.2,35.1     c-3,0-6.7-0.2-8.7-0.3V115z M336.1,65.4c1.2,0.2,4.6,0.3,7.4,0.3c10.1,0,16.8-4.1,16.8-14.2c0-10.4-7.9-14.2-16.8-14.2     c-2,0-5.4,0.2-7.4,0.5V65.4z" }), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M182.7,55c-6.4,4-13.3,8-20.6,11.9l-9-25.3L126.3,115h-25.9l38.1-97.9H168L182.7,55z" })), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("polygon", { className: "st0", points: "261.3,115 236.9,115 236.9,38.7 207.6,38.7 207.6,17.1 290.6,17.1 290.6,38.7 261.3,38.7    " }))), /* @__PURE__ */ React.createElement("path", { className: "st0", d: "M370,10.3c0-3.7,3-6.4,6.6-6.4c3.5,0,6.5,2.7,6.5,6.4c0,3.7-3,6.5-6.5,6.5C373,16.8,370,14.1,370,10.3z    M376.5,15.7c2.9,0,5.2-2.3,5.2-5.4c0-3.1-2.3-5.4-5.2-5.4c-3,0-5.3,2.3-5.3,5.4C371.3,13.5,373.6,15.7,376.5,15.7z M375.2,14.1   H374V6.6h2.8c1.8,0,2.6,0.7,2.6,2.1c0,1.3-0.8,1.9-1.9,2.1l2.1,3.3h-1.3l-2-3.2h-1.3V14.1z M376.5,9.9c1,0,1.8-0.1,1.8-1.2   c0-0.9-0.8-1.1-1.6-1.1h-1.5v2.3H376.5z" })));
export default SvgUatp;
