import { hotelCodes } from '@app/consts/consts';
import { useUser } from '@app/context/user';
import { postExternalLoyalty } from '@app/services/externalLoyalties';
import { addExternalLoyaltyValidation } from '@app/utils/validations';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

export const useSelectExternalLoyalty = (hotelSelected?: string) => {
  const { externalLoyalties, userDispatch } = useUser();
  const [existingMembership, setExistingMembership] = useState<string | undefined>();
  const [addNewExternalLoyaltyModal, setAddNewExternalLoyaltyModal] = useState(false);
  const [addExternalLoyaltyError, setAddExternalLoyaltyError] = useState<string | undefined>();
  const [isSavingExternalLoyalty, setIsSavingExternalLoyalty] = useState(false);
  const formHookExternalLoyalty = useForm({ resolver: addExternalLoyaltyValidation });

  const showExternalLoyaltyModal = () => {
    if (!addNewExternalLoyaltyModal) formHookExternalLoyalty.reset();
    setAddExternalLoyaltyError(undefined);

    setAddNewExternalLoyaltyModal(!addNewExternalLoyaltyModal);
  };

  const handleAddExternalLoyalty = (values: FieldValues) => {
    setIsSavingExternalLoyalty(true);
    setAddExternalLoyaltyError(undefined);
    const providerCode = Object.keys(hotelCodes).find((key) => key === values.providerCode) as string;

    postExternalLoyalty({
      providerAccountValue: values.providerAccountValue,
      providerCode,
      providerTypeCode: values.providerTypeCode,
    })
      .then(() => {
        userDispatch({ type: 'setFetchingLoyalties', fetching: true });
        showExternalLoyaltyModal();
      })
      .catch((e) => {
        setAddExternalLoyaltyError(
          e?.response?.data?.error ?? 'There was an error adding this membership. Check its values and try again.',
        );

        console.error(e);
      })
      .finally(() => setIsSavingExternalLoyalty(false));
  };

  const watchProviderName = formHookExternalLoyalty.watch('providerCode');

  useEffect(() => {
    if (watchProviderName) {
      const providerKey = Object.keys(hotelCodes).find(
        (provider) => hotelCodes[provider as keyof typeof hotelCodes] === watchProviderName,
      );
      formHookExternalLoyalty.setValue('providerTypeCode', providerKey ?? watchProviderName ?? '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchProviderName]);

  useEffect(() => {
    if (hotelSelected && externalLoyalties.length > 0) {
      const membership = externalLoyalties.find((loyalty) => loyalty.providerCode === hotelSelected);
      if (membership) {
        setExistingMembership(membership.providerAccountValue);
      }
    }
  }, [externalLoyalties, hotelSelected]);

  return {
    existingMembership,
    externalLoyalties,
    formHookExternalLoyalty,
    showExternalLoyaltyModal,
    addExternalLoyaltyError,
    addNewExternalLoyaltyModal,
    isSavingExternalLoyalty,
    handleAddExternalLoyalty,
  };
};
