import type { ReactNode } from 'react';
import { useEffect, useReducer } from 'react';

import { travellersReducer } from './reducers';
import { TravellersStateContext, initialValues } from './utils';
import { getTravellers } from '@app/services/traveller';
import { useUser } from '../user';

export const TravellersProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(travellersReducer, initialValues);
  const { user, fetchingUserData } = useUser();
  const { travellers, fetchingTravellers } = state;

  const value = {
    fetchingTravellers,
    travellers,
    travellersDispatch: dispatch,
  };

  useEffect(() => {
    if (user && fetchingTravellers) {
      getTravellers()
        .then((res) => {
          dispatch({ type: 'setData', travellers: res.data.travellers });
        })
        .catch((err: Error) => {
          console.error(err);
        })
        .finally(() => {
          dispatch({ type: 'setFetching', fetching: false });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchingTravellers, fetchingUserData]);

  return <TravellersStateContext.Provider value={value}>{children}</TravellersStateContext.Provider>;
};
