/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { formatCard, getCardTypeLogo, getFullNameFromFields } from '@app/utils/utils';
import { useCardsList } from './CardsList.hooks';
import { add, format, parseISO } from 'date-fns';
import { BaseModal } from '../BaseModal';
import { HookedForm } from '../HookedForm';
import { DatePicker, Input, Radio } from '../Fields';
import { Button } from '../Button';
import { yearAndMonthMask } from '@app/consts/consts';
import BinIcon from '@app/assets/icons/bin.svg?react';

export const CardsList = ({
  allowSelection,
  radioName,
  error,
}: {
  allowSelection?: boolean;
  radioName?: string;
  error?: string;
}) => {
  const {
    user,
    deleteCardModal,
    formHookCard,
    showCardModal,
    showDeleteCardModal,
    addNewCardModal,
    handleAddCard,
    handleDeleteCard,
    cardFieldLimit,
    cards,
    addCardError,
    isSavingCard,
  } = useCardsList();

  return (
    <div className="space-y-4">
      <div className="flex items-start md:items-center justify-between flex-col md:flex-row gap-2 md:gap-0">
        <h3 className="text-4xl md:text-5xl font-bold">Payment Details</h3>
        <div className="flex gap-2">
          {user && (
            <Button kind="secondary" onClick={showCardModal}>
              Add new card
            </Button>
          )}
        </div>
      </div>
      {user && <p>Securely add or remove payment methods to make it easier when you book.</p>}
      <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
        {cards && user && (
          <table className="w-full">
            <tbody>
              {cards?.map((card) => {
                const CardLogo = getCardTypeLogo(card?.cardType ?? '');

                return (
                  <tr key={card?.cardId} className="border-b border-b-gray-200">
                    {allowSelection && radioName && (
                      <th align="left" className="px-4 py-3 text-dorrus-text-secondary min-w-fit w-0">
                        <Radio name={radioName} value={card.cardId} />
                      </th>
                    )}
                    <th align="left" className="px-4 py-3 text-dorrus-text-secondary min-w-fit w-0">
                      <CardLogo />
                    </th>
                    <td className="pl-4 py-3 uppercase hidden md:table-cell" align="left">
                      {card?.cardType}
                    </td>
                    <td className="pl-4 py-3">
                      <span className="hidden md:inline">**** **** ****</span> {card?.lastDigits}
                    </td>
                    <td className="pl-4 py-3">
                      <span>{card?.expDate ? format(parseISO(card?.expDate ?? ''), 'y-MM') : ''}</span>
                    </td>
                    <td className="px-4 py-3" align="right">
                      <span onClick={() => showDeleteCardModal(card.cardId)} className="font-bold cursor-pointer">
                        <span className="hidden md:inline">Delete</span>
                        <BinIcon width={16} className="inline md:hidden" />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}

        {radioName && error && (
          <p className="text-red-500 p-4">
            {error ?? 'There was an error while booking your reservation. Check your information and try again.'}
          </p>
        )}
      </div>
      <BaseModal header="Add new card" open={addNewCardModal} onClose={showCardModal}>
        <div>
          <HookedForm
            id="cardForm"
            formHook={formHookCard}
            onSubmit={handleAddCard}
            mode="onSubmit"
            className="space-y-4"
          >
            <Input
              defaultValue={getFullNameFromFields(user ?? '')}
              name="cardholderName"
              placeholder="Cardholder's Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="card"
              placeholder="Card Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              format={formatCard}
              maxlength={cardFieldLimit}
            />
            <DatePicker
              showPreviousMonths={false}
              minDate={new Date()}
              maxDate={add(new Date(), { years: 15 })}
              showMonthYearPicker
              monthsShown={1}
              mask={yearAndMonthMask as unknown as string}
              dateFormat="MM/yyyy"
              name="expDate"
              placeholder="Expiration date"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            {addCardError && <p className="text-red-500">{addCardError}</p>}
            <div className="flex gap-4">
              <Button kind="primary" onClick={showCardModal} className=" border-0">
                Cancel
              </Button>
              <Button disabled={isSavingCard} type="submit" kind="secondary" form="cardForm">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
      <BaseModal header="Confirmation" open={Boolean(deleteCardModal)} onClose={showDeleteCardModal}>
        <div className="space-y-4">
          <h2 className="font-bold text-2xl">
            Are you sure you want to delete this{' '}
            <span className="uppercase">{cards?.find((x) => x.id === deleteCardModal)?.type}</span> card?
          </h2>
          <div className="flex gap-4">
            <Button kind="primary" onClick={() => showDeleteCardModal()} className=" font-semibold border-0">
              Cancel
            </Button>
            <Button kind="secondary" onClick={handleDeleteCard}>
              Continue
            </Button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};
