import { ReactNode, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

export const Legal = ({ children }: { children: ReactNode }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (pathname === '/legal' || pathname === '/legal/') {
      navigate('/legal/terms-and-conditions');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container px-6 mx-auto">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
        <div className="col-span-4 w-full">
          <h3 className="font-bold text-4xl md:text-5xl pb-4">LEGAL</h3>
          <ul>
            <li className="text-dorrus-button-border font-bold">
              <Link to="/legal/terms-and-conditions">Terms and Conditions</Link>
            </li>
            <li className="text-dorrus-button-border font-bold">
              <Link to="/legal/privacy-policy">Privacy Policy</Link>
            </li>
            <li className="text-dorrus-button-border font-bold">
              <Link to="/legal/privacy-policy#cookies">Cookies Policy</Link>
            </li>
          </ul>
        </div>
        <div id="terms-content" className="min-h-screen col-span-8 prose [&_h5]:font-bold [&_h5]:text-lg">
          <h3 className="font-bold text-4xl md:text-5xl pb-4 uppercase">
            {pathname?.split('/')?.[2]?.replace(/[^a-zA-Z0-9]+/g, ' ')}
          </h3>
          {children}
        </div>
      </div>
    </div>
  );
};
