import { createContext } from 'react';

import type { UserContextInterface, State } from './types';

export const UserStateContext = createContext<UserContextInterface | undefined>(undefined);

export const initialValues = {
  user: undefined,
  cards: [],
  externalLoyalties: [],
  loyaltyLevels: [],
  language: {},
  fetchingUserData: true,
  fetchingUserCards: false,
  fetchingUserLoyalties: false,
  isAuthenticated: false,
} as unknown as State;
