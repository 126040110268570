import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Button } from '../Button';
import { DateRange } from '../Fields';
import { HookedForm } from '../HookedForm/HookedForm';
import { useRoomAvailabilityChanger } from './RoomAvailabilityChanger.hooks';
import SearchIcon from '@app/assets/icons/search.svg?react';
import { AnimatePresence, motion } from 'framer-motion';
import { preventDefault } from '@app/utils/utils';

export const RoomAvailabilityChanger = ({ refetch, isFetching }: { refetch?: () => void; isFetching?: boolean }) => {
  const {
    formHook,
    handleAdd,
    handleRemove,
    totalGuests,
    updateSearch,
    adultGuests,
    childGuests,
    handleRemoveChildAge,
    handleAddChildAge,
  } = useRoomAvailabilityChanger(refetch);

  return (
    <div>
      <div className="bg-white rounded-2xl z-20 relative px-6 py-4 w-full">
        <HookedForm
          formHook={formHook}
          onSubmit={updateSearch}
          className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-none lg:grid-flow-col gap-2"
        >
          <DateRange name="dateIn" label="Check in" secondName="dateOut" secondLabel="Check out" />

          <div className="flex flex-col md:items-center gap-2">
            <div className="flex flex-col gap-2">
              <label htmlFor="adults" className="font-bold">
                Guests
              </label>
              <Menu>
                {({ open }) => (
                  <div className="relative">
                    <MenuButton className="pb-2">
                      <span className="rounded-lg cursor-pointer w-full">{`${totalGuests} guest(s)`}</span>
                    </MenuButton>
                    <AnimatePresence>
                      {open && (
                        <MenuItems
                          as={motion.div}
                          initial={{ opacity: 0, scale: 0.95 }}
                          animate={{ opacity: 1, scale: 1 }}
                          exit={{ opacity: 0, scale: 0.95 }}
                          anchor="bottom"
                          className="origin-top absolute z-50 mt-2 min-w-fit bg-white p-4 rounded-lg shadow-md"
                        >
                          <MenuItem>
                            <div className="px-4">
                              <h4 className="font-bold text-dorrus-text-secondary">Room 1</h4>
                              <div className="flex gap-2 flex-col">
                                <div className="flex gap-10 justify-between items-center">
                                  <span>Adults</span>
                                  <div className="flex items-center gap-6">
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleRemove('adults'))}
                                      kind="pill"
                                    >
                                      -
                                    </Button>
                                    {adultGuests}
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleAdd('adults'))}
                                      kind="pill"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>
                                <div className="flex gap-10 justify-between items-center">
                                  <span>Children</span>
                                  <div className="flex items-center gap-6">
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleRemove('children'))}
                                      kind="pill"
                                    >
                                      -
                                    </Button>
                                    {childGuests}
                                    <Button
                                      type="button"
                                      onClick={(e) => preventDefault(e, () => handleAdd('children'))}
                                      kind="pill"
                                    >
                                      +
                                    </Button>
                                  </div>
                                </div>

                                {Array.from({ length: Number(childGuests) ?? 0 }).map((_, index) => {
                                  const child = formHook.watch('childAges');
                                  return (
                                    <div key={index} className="flex gap-10 justify-between items-center">
                                      <span>Child {index + 1} age</span>
                                      <div className="flex items-center gap-6">
                                        <Button
                                          type="button"
                                          onClick={(e) => preventDefault(e, () => handleRemoveChildAge(index))}
                                          kind="pill"
                                        >
                                          -
                                        </Button>
                                        {child ? child[index] : 1}
                                        <Button
                                          type="button"
                                          onClick={(e) => preventDefault(e, () => handleAddChildAge(index))}
                                          kind="pill"
                                        >
                                          +
                                        </Button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          </MenuItem>
                        </MenuItems>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </Menu>
            </div>
          </div>
          <div className="flex items-center justify-end">
            <Button
              loading={isFetching}
              kind="secondary"
              type="submit"
              className="w-full md:w-max px-6"
              leftNode={<SearchIcon />}
            >
              Change Search
            </Button>
          </div>
        </HookedForm>
      </div>
    </div>
  );
};
