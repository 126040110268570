import { HotelImagesInterface } from '@app/models/search';
import { BaseModal } from '../BaseModal';
import { useEffect, useMemo } from 'react';
import { HookedForm } from '../HookedForm';
import { Select } from '../Fields';
import { useForm } from 'react-hook-form';

export const HotelImagesModal = ({
  open = true,
  onClose,
  images,
}: {
  open: boolean;
  onClose: () => void;
  images: HotelImagesInterface;
}) => {
  const formHook = useForm();

  const getImagesFromCategory = (category?: string) => {
    if (!category) return;

    return images[category]?.map((image, index) => (
      <div
        key={index}
        className={`w-full  overflow-hidden rounded-xl relative ${images[category]?.length === 1 ? 'col-span-2 h-[460px]' : 'h-[250px]'}`}
      >
        <img
          src={image?.ImageItems?.Image[0]?.Url}
          alt={`${category} ${index}`}
          className="object-cover absolute inset-0 w-full h-full"
        />
      </div>
    ));
  };

  const selectedCategory = formHook.watch('category');

  const imagesToRender = useMemo(() => {
    return (
      <div className="overflow-y-scroll max-h-[500px] grid grid-cols-1 lg:grid-cols-2 gap-4">
        {getImagesFromCategory(selectedCategory?.value)}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  useEffect(() => {
    if (images) {
      formHook.setValue('category', { label: Object.keys(images)[0], value: Object.keys(images)[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  return (
    <BaseModal size="max-w-5xl" header="Images" open={open} onClose={onClose}>
      <HookedForm formHook={formHook} className="mb-4">
        <Select
          className="w-[300px]"
          triggerClassname="!w-[300px]"
          placeholder="Categories"
          name="category"
          options={Object.keys(images).map((x) => ({ label: x, value: x }))}
        />
      </HookedForm>
      {imagesToRender}
    </BaseModal>
  );
};
