import { Button } from '@app/components/Button';
import { Input } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm';
import { Link } from 'react-router-dom';
import { usePromotion } from './Promotion.hooks';
import { Tooltip } from '@app/components/Tooltip/Tooltip';

export const Promotion = () => {
  const { noUser, formHook, handleSubmit, succesRegistration, errorRegistration } = usePromotion();

  return (
    <div>
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6 py-10">
        <div className="space-y-6">
          <div className="space-y-4">
            <h3 className="text-4xl md:text-5xl font-bold">Dorrus Beyond Promotion</h3>
            <h4>Please complete the registration form below in order to participate in an exciting Dorrus Beyond</h4>
            {noUser && (
              <p className="font-bold ">
                Not a Dorrus Beyond member?{' '}
                <Link to="/login" className="underline underline-offset-2">
                  Join Now.
                </Link>
              </p>
            )}
          </div>
          <HookedForm formHook={formHook} onSubmit={handleSubmit} className="space-y-6 max-w-[300px]">
            <Input name="promoCode" placeholder="Promotion Code" />

            {!noUser && (
              <Button type="submit" kind="secondary" disabled={noUser || succesRegistration}>
                Register now
              </Button>
            )}

            {noUser && (
              <Tooltip content={<p>You must be signed-in to register</p>}>
                <Button type="submit" kind="secondary" disabled={noUser || succesRegistration}>
                  Register now
                </Button>
              </Tooltip>
            )}
            {succesRegistration && <p>Promotion enrollment successful!</p>}
            {errorRegistration && <p className="text-red-600">{errorRegistration}</p>}
          </HookedForm>
        </div>
        <div>
          <p>
            If you are experiencing difficulties registering for a promotion, please contact the{' '}
            <Link className="underline underline-offset-2" to="/contact-us">
              Dorrus Beyond Customer Care
            </Link>{' '}
            team!
            <br />
            <br />
            Many offers are only available to Dorrus Beyond members that receive a specific, targeted communication from
            Dorrus. Successful registration does not guarantee that you will receive the points, miles or benefits
            offered when the offer requirements are met if you are not eligible per the offer terms and conditions.
            Dorrus, Inc. and its subsidiaries and affiliates which are Adamo Systems, Inc. companies reserve the right
            to cancel any Dorrus Beyond membership and revoke any and all unredeemed Dorrus Beyond points for fraud or
            abuse of any portion of the program per the Dorrus Beyond membership rules.
          </p>
        </div>
      </section>
    </div>
  );
};
