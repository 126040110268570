import { usePromotionTerms } from './PromotionTerms.hooks';

export const PromotionTerms = () => {
  const { terms } = usePromotionTerms();

  return (
    <div>
      <h3 className="text-4xl md:text-5xl font-bold">Dorrus Beyond Promotion</h3>
      <h4 className="font-bold text-lg">Terms and Conditions</h4>

      {terms && (
        <div>
          <div className="prose" dangerouslySetInnerHTML={{ __html: terms }} />
        </div>
      )}
    </div>
  );
};
