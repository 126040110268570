import { HotelRoomPlanInterface } from '@app/models/search';
import { useRoomCard } from './RoomCard.hooks';
import { RoomCardProps } from './RoomCard.interfaces';
import { BaseModal } from '../BaseModal';
import { RoomRateCard } from '../RoomRateCard/RoomRateCard';
import { getAmenityIcon } from '@app/utils/maps';
import { useLocation } from 'react-router-dom';

export const RoomCard = (props?: Partial<HotelRoomPlanInterface & RoomCardProps & { hotelAmenities?: [] }>) => {
  const location = useLocation();
  const { guests, getPriceFormat, moreDetailsModal, handleRoomDetails } = useRoomCard();

  return (
    <>
      <div className="bg-white rounded-3xl flex flex-col  justify-between gap-4 p-6">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="relative  w-full md:w-64 h-48 overflow-hidden rounded-xl">
            <img
              src={props?.image ? props?.image : '/images/room-placeholder.png'}
              alt="hotel name"
              className="absolute w-full md:w-64 h-full object-cover object-center"
            />
          </div>
          <div className="mr-auto flex flex-col">
            <h5 className="text-2xl font-bold capitalize">{props?.roomName?.toLocaleLowerCase()}</h5>
            <p>{guests}</p>
            <div className="hidden md:block mt-4">
              <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
                {!props?.simplified &&
                  props?.hotelAmenities &&
                  props?.hotelAmenities
                    .slice(0, 6)
                    .map((amenity: { code?: string | number; description?: string }, index: number) => {
                      const Icon = getAmenityIcon(String(amenity.code));
                      return (
                        <p key={index} data-code={amenity.code} className="flex gap-2 items-start ">
                          <Icon width={20} height={20} />
                          {amenity.description}
                        </p>
                      );
                    })}
                <a
                  href={`${location?.pathname}${location?.search}#amenities`}
                  className="flex gap-2 items-center underline underline-offset-2"
                >
                  See more
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1  lg:grid-cols-2 xl:grid-cols-3 gap-4">
          {props?.ratesAvailable?.map((rate, _index) => {
            return (
              <RoomRateCard
                key={_index}
                rate={rate}
                handleClick={props?.handleClick}
                setMoreDetailsModal={() => handleRoomDetails(rate)}
              />
            );
          })}
        </div>
      </div>
      <BaseModal header="More details" open={Boolean(moreDetailsModal)} onClose={() => handleRoomDetails(undefined)}>
        <div className="text-left space-y-6">
          {moreDetailsModal?.rateDescription && (
            <div>
              <h5 className="font-bold text-xl">Rate details:</h5>
              <p>{moreDetailsModal?.rateDescription}</p>
            </div>
          )}

          <div>
            <h5 className="font-bold text-xl">This rate is {!moreDetailsModal?.isRefundable && 'non-'}refundable.</h5>
          </div>

          <div>
            <h5 className="font-bold text-xl">Meals are not included.</h5>
          </div>
          {Boolean(moreDetailsModal?.roomExtras?.length) && (
            <div>
              <h5 className="font-bold text-xl">Extras available:</h5>

              <ul>
                {moreDetailsModal?.roomExtras?.map((x) => (
                  <li key={x.extraCode}>
                    <span className="font-bold">{x.extraName}</span> - {getPriceFormat(x.extraAmount ?? 0, true)}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {Boolean(moreDetailsModal?.roomAddtlDetails?.length) && (
            <div>
              <h5 className="font-bold text-xl">Additional Informations:</h5>
              {Boolean(moreDetailsModal?.roomExtras?.length) && (
                <ul>
                  {moreDetailsModal?.roomAddtlDetails
                    ?.filter((x) => x.addtlDetailCode !== 14)
                    .map((x) => (
                      <li key={x.addtlDetailCode}>
                        <span className="font-bold">{x.addtlDetailText}</span>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          )}
        </div>
      </BaseModal>
    </>
  );
};
