import { ComponentProps, ComponentType } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const Radio = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  id?: string;
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number;
}) => {
  const {
    register,
    getFieldState,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex flex-row gap-2 items-center">
      <label htmlFor={componentProps.id} className="flex items-center gap-2">
        <input
          className="focus:outline-none focus:ring-transparent rounded-full p-2 !accent-dorrus-background-secondary !text-dorrus-background-secondary"
          {...componentProps}
          {...register(componentProps.name, {
            ...(componentProps.value && { value: componentProps.value }),
            ...(componentProps.isRequired && { required: 'This field is required' }),
            ...(componentProps.validation && { pattern: componentProps.validation }),
          })}
          type="radio"
        />
        <span>{componentProps.label}</span>
      </label>

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
