import Users from '@app/assets/icons/users.svg?react';
import { useUser } from '@app/context/user';
interface LoyaltyPillProps {
  title: string;
  points: number;
  description?: string;
}
export const LoyaltyPill = ({ title, points, description }: LoyaltyPillProps) => {
  const { getPriceFormat } = useUser();

  return (
    <div className="bg-white shadow-sm rounded-full flex items-center px-6 py-2 gap-4 min-w-[300px]">
      <div className="bg-dorrus-background-secondary p-4 rounded-full items-center justify-center">
        <Users />
      </div>
      <div className="flex flex-col">
        <p>{title}</p>
        <span className="text-2xl font-bold">{getPriceFormat(points)}</span>
        <small className="text-sm text-dorrus-text-secondary">{description}</small>
      </div>
    </div>
  );
};
