import { cleanUpTopic } from '@app/utils/utils';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';

export const TermsAndConditionsDocument = () => {
  useEffect(() => {
    const privacyContent = document?.getElementById('terms-content');
    const allTopics = privacyContent?.querySelectorAll('h5');
    if (allTopics?.length) {
      allTopics.forEach((topic) => {
        topic.id = cleanUpTopic(topic.textContent);
      });
    }
  }, []);

  return (
    <>
      <p>Last Modified: 16 March 2024</p>
      <h5>Acceptance of the Terms of Use</h5>
      <p>
        These terms of use are binding upon the User and Dorrus, Inc. (&quot;the Company&quot;). The following terms set
        the rules for the User&quot;s access and use of dorrus.com, including any content and services offered through
        dorrus.com (the &quot;Website&quot;). Please read the Terms of Use before using the Website.
      </p>
      <p>
        By using this Website you accept these Terms of Use. If you do not want to agree to these Terms of Use, you must
        leave the Website and refrain from having any access to it in the future.
      </p>
      <p>
        By using this Website, you represent and warrant that you are of legal age to consent to these Terms and
        Conditions. If you do not meet the legal age requirements to consent to these Terms you must leave the Website
        and refrain from having any access to it in the future.
      </p>
      <h5>Changes to the Terms of Use and Conditions and to the Website</h5>
      <p>
        We may revise and update these Terms of Use as necessary as possible and at our discretion. All changes are
        effective at the moment we post such changes and shall apply to all access and use of the Website.
      </p>
      <p>
        The User must verify these Terms of Use from time to time. The User&quot;s continued access and use of the
        Website following a revision of the Terms of Use shall imply an acceptance of the changes.
      </p>
      <p>
        The Company may change the content of this Website from time to time. However, this content is not necessarily
        complete or up-to-date and the Company is not under any obligation to update it.
      </p>
      <h5>Reliance on the Information Posted</h5>
      <p>
        The information presented on the Website is only available for information purposes. The Company does not
        warrant the accuracy or completeness of this information. The User may not place reliance on the information on
        the Website. The Company disclaims any liability that could arise if any user or any visitor to the Website
        relies on such materials.
      </p>
      <p>
        Third parties may provide content to this Website that includes or may include materials provided by other
        users, bloggers, and third-party licensors, syndicators or reporting services. The opinions and statements
        expressed in these materials are solely the responsibility of the person or entity providing those materials.
        These materials do not necessarily reflect the opinion or views of the Company.
      </p>
      <h5>Website Access</h5>
      <p>
        The Company reserves the right to amend, modify or delete this Website and its services at our discretion. The
        Company will not be liable if the Website is unavailable. The Company may restrict user access to some parts of
        the Website or the entire Website.
      </p>

      <p>
        The Company is not responsible if the User does not meet the requirements to access the Website. The information
        you provide to the Company must be up to date and complete. The User is responsible for informing all persons
        who use the User&quot;s internet connection about complying with the Website&quot;s Terms of Use.
      </p>
      <p>
        The User agrees that all the information provided to the Company for registration purposes is subject to its
        <Link to="/legal/privacy-policy">Privacy Policy</Link>. The User consents to all actions the Company takes under
        our <Link to="/legal/privacy-policy">Privacy Policy</Link>. The User is responsible for treating the username
        and password chosen or given as confidential. The User acknowledges that the account is personal and agrees to
        keep its information private from any other person. The User acknowledges that third parties do not have a right
        to access this Website with the User&quot;s registered information.
      </p>
      <p>
        The User shall notify the Company immediately of any unauthorized access to or use of the User&quot;s registered
        information or any other sort of breach of security. The Company has the right to delete any registered
        information or delete any account if it considers that the User has violated any provision of these Terms of Use
        or **Name of Dorrus Loyalty** Program Rules.
      </p>
      <h5>Prohibitions</h5>
      <p>
        The User agrees not to use the Website in any way or form that violates any applicable federal, state, local, or
        international law. The User may not send, receive, upload, download, or otherwise transmit, or procure the
        sending of, any advertising or promotional material, including any junk mail, chain letter, spam, or any other
        similar solicitation, without the prior written consent of the Company.
      </p>
      <p>
        The User may not impersonate or attempt to impersonate the Company, a Company employee, another user, or any
        other person or entity when using the Website. The User may not engage in any other conduct that restricts or
        inhibits other users&quot; use or enjoyment of the Website or that may harm the Company or users of the Website,
        or expose them to liability.
      </p>
      <p>
        Additionally, the User may not use the Website in any manner that could disable, overburden, damage, interfere,
        or impair other users&quot; access to the Website or the proper working of the Website.
        <br />
        Furthermore, the User may not use any bot, robot, spider, or any automatic device to access the Website for
        monitoring purposes.
      </p>
      <h5>User Content</h5>
      <p>
        The Website may contain forums, chat rooms, personal web pages or profiles, bulletin boards, and other
        interactive features that allow the users to post content or materials on the Website. Any User Content you post
        to the site will be considered non-confidential and non-proprietary. The User grants the Company and assigns the
        right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such
        material for any purpose.
      </p>
      <p>
        The User represents and warrants that the User has the right to distribute the content that the User posts or
        shares on the Website. The User also warrants that all such contents comply with these Terms of Use. The Company
        is not responsible or liable to any third party for the contents posted on its Website.
      </p>
      <h5>Content Standards</h5>
      <p>
        These content standards apply to all content made by the User on the Website. These contents must comply with
        any applicable federal, state, local, and international laws and regulations.
      </p>
      <p>
        Content by the User must not contain images or texts that are offensive, defamatory, abusive, harassing,
        violent, hateful, inflammatory, obscene, or otherwise objectionable. The User must not contribute any sexually
        explicit, violent, sexually discriminatory, or racist content. Furthermore, the User must also not violate any
        privacy rights of other Users (<Link to="/legal/privacy-policy">Privacy Policy</Link>) or any third party, nor
        promote or advocate in favor of any illegal activity. Lastly, the User must not infringe any patent, trademark,
        copyright, or other Intellectual Property.
      </p>
      <h5>User Information</h5>
      <p>
        All the information collected on this Website by the Company is subject to our{' '}
        <Link to="/legal/privacy-policy">Privacy Policy</Link>. By using the Website, the User consents to any action
        the Company might take concerning the User’s information and its compliance with the{' '}
        <Link to="/legal/privacy-policy">Privacy Policy</Link>.
      </p>
      <p>
        All transactions through this Website or information formed through the Website, or resulting from visits made
        by the User, are governed by these Terms of Use. and, when applicable, to the **Name of Dorrus Loyalty** Program
        Rules _______________________(link to the Program Rules).
      </p>
      <p>
        The Company hereby incorporates any additional terms and conditions and by this reference and may also
        incorporate specific portions, services, or features of the Website.
      </p>
      <h5>Social Media and Links</h5>
      <p>
        The User may place a link to our homepage provided that it is done fairly and legally and does not damage our
        reputation. However, the User must not be associated with the Company and such linking does not constitute any
        form of approval or endorsement on the Company&quot;s part.
      </p>
      <p>
        This Website may provide certain social media features that enable you to link from your own or certain
        third-party websites to certain content on this Website. It may also send emails or other communications with
        certain content, or links to certain content, on this Website, and cause limited portions of content on this
        Website to be displayed or appear to be displayed on the User&quot;s own or certain third-party websites.
      </p>
      <p>
        The User may use these features solely as they are provided by the Company. The User you must not take any
        action concerning the materials on this Website that is inconsistent with any other provision of these Terms of
        Use.
      </p>
      <p>
        The User agrees to cooperate with us in immediately ceasing any unauthorized linking. The Company reserves the
        right to withdraw linking permission without notice and may disable all or any social media features at any time
        without any prior notice.
      </p>
      <h5>Intellectual Property Rights</h5>
      <p>
        The Company owns the Website and its entire contents, features, and functionality, including but not limited to,
        all information, software, apps, texts, displays, images, video, and audio, and the design are the intellectual
        property of the Company and its licensors. The Company&quot;s intellectual property is protected by domestic
        United States and international copyright, trademark, patent, trade secret, and other intellectual property
        laws.
      </p>
      <p>
        These Terms of Use permit the User to use the Website for personal use only. The User must not reproduce,
        distribute, modify, create derivative works of, publicly display, publicly perform, republish, download, store
        or transmit any of the material on our Website. However, the User may print or download pages from the Website
        for the User&quot;s personal use. The User may also download desktop or mobile applications solely for personal
        use, provided that the User agrees to be bound by any license agreement the company may offer for such
        applications.
      </p>
      <p>
        The User must not modify copies of any materials from this site, use any illustrations, photographs, video or
        audio sequences, or any graphics separately from the accompanying text. The User must not access or use for any
        commercial purposes any part of the Website or any services or materials available through the Website.
      </p>
      <p>
        If the User prints, copies, modifies, downloads, or otherwise uses or provides any other person with access to
        any part of the Website, it will be considered a breach of the Terms of Use. In such circumstances, the
        User&quot;s right to use the Website will stop immediately and must return or destroy any copies of the
        materials made.
      </p>
      <h5>Trademarks</h5>
      <p>
        The Company name, its trademarks, and all related names, logos, product and service names, designs, and slogans
        are trademarks of the Company or its affiliates or licensors. The User must not use such marks without the prior
        written permission of the Company. All other names, logos, product and service names, designs, and slogans on
        this Website are the trademarks of their respective owners.
      </p>
      <h5>No warranties</h5>
      <p>
        This website is provided “as is” without any representations or warranties, express or implied. The Company
        makes no representations or warranties in relation to this website or the information and materials provided on
        this website.
      </p>
      <p>
        The Company does not warrant that this website will be constantly available, or available at all; or that the
        information on this website is complete, true, accurate, or non-misleading. Nothing on this website constitutes
        or is meant to constitute, advice of any kind. If you require advice in relation to any matter you should
        consult an appropriate professional.
      </p>
      <h5>Limitations of liability</h5>
      <p>
        The Company will not be liable to the User in relation to the contents of, or use of, or otherwise in connection
        with, this website for any indirect, special, or consequential loss; or for any business losses, loss of
        revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation
        or goodwill, or loss or corruption of information or data.
      </p>
      <h5>Indemnification</h5>
      <p>
        You agree to defend, indemnify, and hold harmless the Company from and against any claims, liabilities, damages,
        judgments, awards, losses, costs, expenses, or fees (including reasonable attorneys&quot; fees) arising out of
        or relating to your violation of these Terms of Use or your use of the Website, including, but not limited to,
        your User Contents, any use of the Website&quot;s content, services, and products other than as expressly
        authorized in these Terms of Use, or your use of any information obtained from the Website.
      </p>
      <h5>Governing Law</h5>
      <p>
        All matters relating to the Website and all issues that could potentially arise out of, or related to, these
        Terms of Use, and any dispute or claim arising therefrom or related thereto (in each case, including
        non-contractual disputes or claims), shall be governed by and construed under the internal laws of the State of
        Minnesota without giving effect to any choice or conflict of law provision or rule (whether of the State of
        Minnesota or any other jurisdiction).
      </p>
      <p>
        The User waives any and all objections to the Company&quot;s exercise of jurisdiction by such courts and venue
        in such courts.
      </p>
      <h5>Waiver and Severability</h5>
      <p>
        If any provision of this website disclaimer is or is found to be, unenforceable under applicable law, that will
        not affect the enforceability of the other provisions of this website disclaimer
      </p>
      <h5>Entire Agreement</h5>
      <p>
        The Terms of Use and other relevant policies incorporated herein by reference constitute the sole and entire
        agreement between the User and the Company regarding the Website and supersede all prior and contemporaneous
        understandings, agreements, representations, and warranties, of any kind, regarding the use of the Website.
      </p>
      <h5>Comments and Concerns</h5>
      <p>
        If you have any comments or questions about these Terms of Use, please notify the Company at
        <a href="mailto:consumer.legal@dorrus.com">consumer.legal@dorrus.com</a>.
      </p>
    </>
  );
};
