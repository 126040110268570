import { Button } from '@app/components/Button';
import GoogleIcon from '@app/assets/icons/Google.svg?react';
import FacebookIcon from '@app/assets/icons/Facebook.svg?react';
import AppleIcon from '@app/assets/icons/Apple.svg?react';
import { useLogin } from './Login.hooks';
import { Input } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { emailValidation } from '@app/utils/validations';
import { Link } from 'react-router-dom';
import { Loading } from '@app/components/Loading/Loading';

export const Login = () => {
  const { setIsTriggered, isEmailLogin, formHook, onSubmit, fetchingUserData, doesEmailExist, token, cancelLogin } =
    useLogin();

  return (
    <div className="flex flex-col items-center md:justify-center h-screen relative">
      <div className="rounded-3xl md:bg-white md:w-[670px] p-4 md:p-10 space-y-6">
        <div className="max-w-full md:max-w-[80%] mx-auto">
          <h1 className="text-4xl md:text-5xl text-dorrus-text-primary font-bold mb-4 text-center">
            Join to unlock the best of Dorrus.
          </h1>
          <p className="text-dorrus-text-primary text-center">
            Experience exclusive benefits, personalized rewards, and unmatched travel deals when you become a member.
            <span className="hidden md:inline">
              {' '}
              Elevate your travel with Dorrus Beyond and enjoy more with every booking.
            </span>
          </p>
        </div>
        {fetchingUserData && token && (
          <div className="flex justify-center items-center">
            <Loading width={50} height={50} />
            <p>Loading user information...</p>
          </div>
        )}
        {!token && (
          <div className="space-y-4 flex items-center flex-col justify-center md:w-2/3 mx-auto">
            {!isEmailLogin && (
              <>
                <Button
                  className="bg-white "
                  onClick={() => {
                    window.location.href = `${import.meta.env.VITE_APP_API_BASE}users/login/google`;
                  }}
                  leftNode={<GoogleIcon />}
                >
                  Continue with Google
                </Button>
                <Button
                  className="bg-white "
                  onClick={() => {
                    window.location.href = `${import.meta.env.VITE_APP_API_BASE}users/login/facebook`;
                  }}
                  leftNode={<FacebookIcon />}
                >
                  Continue with Facebook
                </Button>
                <Button
                  className="bg-white"
                  leftNode={<AppleIcon />}
                  onClick={() => {
                    window.location.href = `${import.meta.env.VITE_APP_API_BASE}users/login/apple`;
                  }}
                >
                  Continue with Apple ID
                </Button>
                <p>or</p>
              </>
            )}
            <HookedForm formHook={formHook} onSubmit={onSubmit} className="w-full space-y-4">
              <Input
                placeholder="Email"
                name="email"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                isRequired
                disabled={isEmailLogin}
                validation={emailValidation}
                maxlength={255}
              />
              {isEmailLogin && (
                <div>
                  <Input
                    placeholder="Password"
                    isRequired
                    name="password"
                    type="password"
                    className="border border-dorrus-button-border rounded-lg w-full p-4"
                    {...(!doesEmailExist && {
                      description:
                        'A password must contain one Capital Letter, a number and a special character. Minimum 8 characters.',
                    })}
                  />
                </div>
              )}

              <Button
                className="!font-light"
                type={isEmailLogin ? 'submit' : 'button'}
                onClick={() => {
                  formHook.trigger('email');
                  setIsTriggered(true);
                }}
                kind="secondary"
              >
                Continue
              </Button>
              {isEmailLogin && (
                <div className="flex justify-between">
                  <Link to="/forgot-password" className="text-dorrus-text-primary text-left font-bold pt-1 block">
                    Forgot Password
                  </Link>
                  <button
                    type="button"
                    onClick={cancelLogin}
                    className="text-dorrus-text-primary text-left font-bold pt-1 block"
                  >
                    Reset Login
                  </button>
                </div>
              )}
            </HookedForm>
          </div>
        )}
      </div>
      <div className="md:absolute bottom-4 px-4">
        <p>
          By signing in or creating an account, you agree with our{' '}
          <Link to="/terms-and-conditions" className="underline">
            Terms & Conditions
          </Link>{' '}
          and{' '}
          <Link to="/privacy-policy" className="underline">
            Privacy Statement
          </Link>
          .
        </p>
      </div>
    </div>
  );
};
