import { useUser } from '@app/context/user';
import { LoyaltyTransaction } from '@app/models/loyalty';
import { LoyaltyTransactionsStatusMap } from '@app/utils/utils';
import { format } from 'date-fns';
import { useState } from 'react';
import ChevronIcon from '@app/assets/icons/chevron.svg?react';
import ExternalIcon from '@app/assets/icons/external.svg?react';
import { Button } from '../Button';

export const LoyaltyTransactionsRow = ({ transaction }: { transaction: LoyaltyTransaction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { getPriceFormat } = useUser();

  const breakdownPts = transaction?.trxBreakdown?.filter((trx) => trx.itemPts);
  const breakdownEQP = transaction?.trxBreakdown?.filter((trx) => trx.itemEQP);
  const trxBreakdown = [...breakdownPts, ...breakdownEQP];

  return (
    <>
      <tr className="border-b border-b-gray-200 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
        <td className="p-4 w-[200px]" valign="top">
          {format(transaction?.timestamp ?? new Date(), 'MMM dd, yyyy')}
        </td>
        <td className="py-4">{transaction?.description}</td>
        <td className="py-4">{transaction?.totalPts ? getPriceFormat(transaction?.totalPts) : ''}</td>
        <td className="py-4">{transaction?.totalEQP ? getPriceFormat(transaction?.totalEQP) : ''}</td>
        <td className="py-4" valign="top">
          {LoyaltyTransactionsStatusMap.get(transaction?.status)}
          {transaction?.status === 4 && (
            <Button kind="secondary" className="!px-2 !py-0 text-sm !w-fit">
              View
            </Button>
          )}
        </td>
        <td className="py-4">
          {trxBreakdown.length > 0 && (
            <ChevronIcon width={20} className={`transition ${isOpen ? '-rotate-90' : 'rotate-90'}`} />
          )}
        </td>
      </tr>
      {isOpen && (
        <>
          {trxBreakdown.map((trx, index) => {
            const hasRedemption = trx?.redemptionUrl ? JSON.parse(trx?.redemptionUrl ?? '')?.url?.[0] : undefined;
            return (
              <tr key={index} className={`italic text-sm ${index === trxBreakdown.length - 1 ? 'border-b' : ''}`}>
                <td className="py-2"></td>
                <td className="py-2">
                  {hasRedemption ? (
                    <a
                      rel="noreferrer"
                      href={hasRedemption}
                      target="_blank"
                      className="underline underline-offset-2 flex items-center gap-2"
                    >
                      {trx.itemDescription} <ExternalIcon height={15} />
                    </a>
                  ) : (
                    trx.itemDescription
                  )}
                </td>
                <td>{trx.itemPts ? getPriceFormat(trx.itemPts) : ''}</td>
                <td>{trx.itemEQP ? getPriceFormat(trx.itemEQP) : ''}</td>
              </tr>
            );
          })}
        </>
      )}
    </>
  );
};
