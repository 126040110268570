import { ComponentProps, ComponentType, ReactNode, useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { Combobox, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/react';
import SpinnerIcon from '@app/assets/spinner.svg?react';
import { AnimatePresence, motion } from 'framer-motion';
import CheckIcon from '@app/assets/icons/check.svg?react';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const Searchable = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  id?: string;
  placeholder?: string;
  name: string;
  className?: string;
  label?: string;
  value?: string | number;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  options?: { label: string; value: string }[];
  onSearch?: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (value: string) => void;
  isFetching?: boolean;
  inputClassName?: string;
  optionIcon?: ReactNode | JSX.Element;
  recentSearches?: { label: string; value: string }[];
  showLabelAsValue?: boolean;
}) => {
  const [availableOptions, setAvailableOptions] = useState<{ label: string; value: string }[]>(
    componentProps.options ?? [],
  );
  const [searchValue, setSearchValue] = useState<string>('');

  const {
    getFieldState,
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);

  const handleSearch = (value: React.ChangeEvent<HTMLInputElement>) => {
    if (!componentProps.onSearch) {
      setSearchValue(value.currentTarget.value);
    }
  };

  useEffect(() => {
    if (Boolean(searchValue) && !componentProps.onSearch) {
      setAvailableOptions(
        componentProps.options?.filter((option) => option.label.toLowerCase().includes(searchValue.toLowerCase())) ??
          [],
      );
    } else {
      setAvailableOptions(componentProps.options ?? []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, componentProps.options]);

  return (
    <div className="flex flex-col">
      {componentProps.label && (
        <label htmlFor={componentProps.name} className="font-bold">
          {componentProps.label}
        </label>
      )}
      <Controller
        control={control}
        name={componentProps.name}
        render={({ field: { onChange, value } }) => {
          return (
            <Combobox
              immediate
              as="div"
              id={componentProps.name}
              defaultValue={value}
              name={componentProps.name}
              onChange={componentProps?.onChange ?? onChange}
            >
              {({ open }) => (
                <div>
                  <div className="flex items-center">
                    <ComboboxInput
                      {...(componentProps.id && { 'data-id': componentProps.id })}
                      displayValue={() =>
                        componentProps.showLabelAsValue
                          ? componentProps.label ?? componentProps.options?.find((opt) => opt.value === value)?.label
                          : componentProps.value ?? value ?? ''
                      }
                      onChange={componentProps.onSearch ?? handleSearch}
                      placeholder={componentProps.placeholder ?? 'Please choose'}
                      className={`focus:outline-none focus:ring-0 border relative border-dorrus-button-border p-4 rounded-lg font-normal text-left min-w-fit w-full ${!value ? 'text-dorrus-text-secondary' : ''} ${componentProps.inputClassName ?? ''}`}
                      autoComplete="off"
                      onBlur={() => setSearchValue('')}
                    />
                    {componentProps.isFetching && (
                      <div>
                        <SpinnerIcon width={30} />
                      </div>
                    )}
                  </div>
                  <AnimatePresence>
                    {open && (
                      <ComboboxOptions
                        static
                        as={motion.div}
                        initial={{ opacity: 0, scale: 0.95 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.95 }}
                        anchor="bottom start"
                        className="origin-top empty:hidden z-[9999] bg-white mt-2 rounded-xl shadow-md overflow-hidden top-0 min-w-fit w-[--input-width]   min-h-fit h-fit !max-h-[300px]"
                      >
                        {searchValue && availableOptions?.length === 0 && (
                          <div className="p-2">No available options found.</div>
                        )}
                        {!value && componentProps.recentSearches && componentProps.recentSearches?.length > 0 && (
                          <div>
                            <small className="px-4 py-2">Recent Searches</small>
                            {componentProps.recentSearches?.map((option) => (
                              <ComboboxOption
                                value={option.value ?? option.label}
                                className="text-base cursor-pointer select-none relative px-4 py-2 hover:bg-gray-200 flex gap-2 items-center min-w-[250px] w-[--button-width] "
                                key={option.value ?? option.label}
                              >
                                {({ selected }) => (
                                  <>
                                    {componentProps.optionIcon && <span>{componentProps.optionIcon}</span>}
                                    <span className={selected ? 'font-bold' : ''}>{option.label}</span>
                                  </>
                                )}
                              </ComboboxOption>
                            ))}
                          </div>
                        )}
                        {!componentProps.isFetching &&
                          availableOptions?.map((option) => (
                            <ComboboxOption
                              value={option.value ?? option.label}
                              className="text-base cursor-pointer select-none relative px-4 py-2 hover:bg-gray-200 flex gap-2 items-center min-w-[250px] w-full"
                              key={option.value ?? option.label}
                            >
                              {({ selected }) => (
                                <>
                                  {componentProps.optionIcon && <span>{componentProps.optionIcon}</span>}
                                  <span className={selected ? 'font-bold' : ''}>{option.label}</span>
                                  <span className="w-5">{selected && <CheckIcon width={20} />}</span>
                                </>
                              )}
                            </ComboboxOption>
                          ))}
                      </ComboboxOptions>
                    )}
                  </AnimatePresence>
                </div>
              )}
            </Combobox>
          );
        }}
      />

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
