import { useExternalLoyaltyList } from './ExternalLoyaltyList.hooks';
import { BaseModal } from '../BaseModal';
import { HookedForm } from '../HookedForm';
import { Input, Searchable } from '../Fields';
import { Button } from '../Button';
import { hotelCodes } from '@app/consts/consts';
import BinIcon from '@app/assets/icons/bin.svg?react';
import EditIcon from '@app/assets/icons/edit.svg?react';
import { HotelRoomSelectionInterface } from '@app/models/search';
import { useEffect } from 'react';

export const ExternalLoyaltyList = ({ hotelInfo }: { hotelInfo?: HotelRoomSelectionInterface }) => {
  const {
    deleteExternalLoyaltyModal,
    formHookExternalLoyalty,
    showExternalLoyaltyModal,
    showDeleteExternalLoyaltyModal,
    addNewExternalLoyaltyModal,
    handleAddExternalLoyalty,
    handleDeleteExternalLoyalty,
    externalLoyalties,
    showUpdateExternalLoyaltyModal,
    updateExternalLoyaltyModal,
    formHookUpdateExternalLoyalty,
    handleUpdateExternalLoyalty,
    addExternalLoyaltyError,
  } = useExternalLoyaltyList();

  useEffect(() => {
    if (hotelInfo?.chainCode) {
      formHookExternalLoyalty.setValue('providerCode', hotelInfo.chainCode);
      formHookExternalLoyalty.setValue('providerTypeCode', hotelInfo.chainCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelInfo]);

  return (
    <div className="space-y-4">
      <div className="flex items-start md:items-center justify-between flex-col md:flex-row gap-2 md:gap-0">
        <h3 className="text-4xl md:text-5xl font-bold">Loyalty Affiliations</h3>
        <div className="flex gap-2">
          <Button kind="secondary" onClick={showExternalLoyaltyModal}>
            Add loyalty affiliation
          </Button>
        </div>
      </div>
      <p>Save your travel loyalty credentials to maximise rewards on each booking</p>
      <div className="bg-white rounded-2xl shadow-sm overflow-hidden">
        {externalLoyalties && (
          <table className="w-full">
            <tbody>
              {externalLoyalties && externalLoyalties.length > 0 ? (
                externalLoyalties?.map((externalLoyalty) => {
                  return (
                    <tr key={externalLoyalty?.entryID} className="border-b border-b-gray-200">
                      <td
                        className="pl-4 py-3 uppercase hidden md:table-cell font-bold whitespace-nowrap min-w-[200px]"
                        align="left"
                      >
                        {hotelCodes[externalLoyalty?.providerCode as keyof typeof hotelCodes]}
                      </td>
                      <td className="pl-4 py-3 w-full">{externalLoyalty?.providerAccountValue}</td>
                      <td className="px-2 py-3 w-fit" align="right">
                        <button
                          type="button"
                          onClick={() => showUpdateExternalLoyaltyModal(String(externalLoyalty.entryID))}
                          className="font-bold cursor-pointer"
                        >
                          <span className="hidden md:inline">Update</span>
                          <EditIcon width={16} className="inline md:hidden" />
                        </button>
                      </td>
                      <td className="px-4 py-3 w-fit" align="left">
                        <button
                          type="button"
                          onClick={() => showDeleteExternalLoyaltyModal(String(externalLoyalty.entryID))}
                          className="font-bold cursor-pointer"
                        >
                          <span className="hidden md:inline">Delete</span>
                          <BinIcon width={16} className="inline md:hidden" />
                        </button>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={5} className="px-4 py-3 text-dorrus-text-secondary">
                    <span>There are no credentials saved - </span>
                    <button onClick={showExternalLoyaltyModal} type="button" className="underline">
                      add one today
                    </button>
                    .
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
      <BaseModal header="Add External Loyalty" open={addNewExternalLoyaltyModal} onClose={showExternalLoyaltyModal}>
        <div>
          <HookedForm
            id="externalLoyaltyForm"
            formHook={formHookExternalLoyalty}
            onSubmit={handleAddExternalLoyalty}
            mode="onSubmit"
            className="space-y-4"
          >
            <Searchable
              name="providerCode"
              placeholder="Provider"
              showLabelAsValue
              value={formHookExternalLoyalty.watch('providerCode')}
              onChange={(value) => {
                formHookExternalLoyalty.setValue('providerCode', value);
              }}
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              options={Object.keys(hotelCodes)
                .sort()
                .map((key) => ({
                  label: hotelCodes[key as keyof typeof hotelCodes],
                  value: key,
                }))}
            />
            <Input
              name="providerAccountValue"
              placeholder="Account Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              type="hidden"
              name="providerTypeCode"
              value={Object.values(hotelCodes).find(
                (provider) => provider === formHookExternalLoyalty.watch('providerCode'),
              )}
            />
            {addExternalLoyaltyError && <p className="font-bold text-dorrusAlerts-red">{addExternalLoyaltyError}</p>}
            <div className="flex gap-4">
              <Button kind="primary" onClick={showExternalLoyaltyModal} className=" border-0">
                Cancel
              </Button>
              <Button form="externalLoyaltyForm" type="submit" kind="secondary">
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
      <BaseModal
        header="Updating Membership"
        open={Boolean(updateExternalLoyaltyModal)}
        onClose={() => showUpdateExternalLoyaltyModal()}
      >
        <div>
          <HookedForm
            id="externalLoyaltyUpdateForm"
            formHook={formHookUpdateExternalLoyalty}
            onSubmit={handleUpdateExternalLoyalty}
            mode="onSubmit"
            className="space-y-4"
          >
            {updateExternalLoyaltyModal && (
              <p>
                Your are updating your membership for{' '}
                {
                  hotelCodes[
                    externalLoyalties.find((loyalty) => loyalty.entryID === Number(updateExternalLoyaltyModal))
                      ?.providerCode as keyof typeof hotelCodes
                  ]
                }
              </p>
            )}
            <Input
              name="providerAccountValue"
              placeholder="Account Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <div className="flex gap-4">
              <Button kind="primary" onClick={() => showUpdateExternalLoyaltyModal()} className=" border-0">
                Cancel
              </Button>
              <Button
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateExternalLoyalty(formHookUpdateExternalLoyalty.getValues());
                }}
                form="externalLoyaltyUpdateForm"
                type="submit"
                kind="secondary"
              >
                Save
              </Button>
            </div>
          </HookedForm>
        </div>
      </BaseModal>
      <BaseModal
        header="Confirmation"
        open={Boolean(deleteExternalLoyaltyModal)}
        onClose={showDeleteExternalLoyaltyModal}
      >
        <div className="space-y-4">
          <h2 className="font-bold text-2xl">Are you sure you want to delete this membership?</h2>
          <div className="flex gap-4">
            <Button kind="primary" onClick={() => showDeleteExternalLoyaltyModal()} className=" font-semibold border-0">
              Cancel
            </Button>
            <Button kind="secondary" onClick={handleDeleteExternalLoyalty}>
              Continue
            </Button>
          </div>
        </div>
      </BaseModal>
    </div>
  );
};
