import { Banner } from '@app/components/Banner';
import { Filters } from '@app/components/Filters/Filters';
import { useResults } from './Results.hooks';
import { Select } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { Button } from '@app/components/Button';
import { ResultsList } from './ResultsList';
import { ResultsMap } from './ResultsMap';
import { Loading } from '@app/components/Loading/Loading';
import { AnimatePresence, motion } from 'framer-motion';
import MapViewIcon from '@app/assets/icons/map-view.svg?react';
import SortIcon from '@app/assets/icons/sort.svg?react';
import ListIcon from '@app/assets/icons/list.svg?react';
import { HotelInterface } from '@app/models/search';
import { getAllowedParams, prepareFilters } from '@app/context/search/utils';
import { getHotels } from '@app/services/booking';
import { useSearch } from '@app/context/search';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@app/context/user';

export const Results = () => {
  const navigate = useNavigate();
  const { language } = useUser();
  const { toggleMap, showMap, focusOnLocation, formHook, handleSort } = useResults();
  const { searchParams: searchParamsContext, filters } = useSearch();
  const [pageSize, setPageSize] = useState(10);
  const { data, isLoading, isFetching, refetch } = useQuery({
    queryKey: ['hotelSearch', searchParamsContext],
    queryFn: () =>
      getHotels({
        ...(getAllowedParams(searchParamsContext) as object),
        ...(prepareFilters(filters) as object),
        PageSize: pageSize,
        unitOfMeasure: language.unitOfMeasure,
      }),
    refetchOnWindowFocus: false,
    enabled: Boolean(searchParamsContext),
  });

  const handleNextPage = () => {
    if (data?.hasNextPage) {
      setPageSize(() => pageSize + 5);
    }
  };

  if (searchParamsContext && !searchParamsContext?.location) {
    navigate('/');
  }
  useEffect(() => {
    if (pageSize > 10) {
      refetch();
    }
  }, [pageSize]);

  return (
    <div>
      <Banner simplified refetch={refetch} />

      {isLoading && (
        <div className="flex items-center justify-center my-10">
          <Loading width={100} height={100} />
        </div>
      )}
      {!isLoading && (
        <>
          <h3 className="text-5xl font-bold font-gilroy mt-12">Select a hotel</h3>
          <Filters results={data?.results} refetch={refetch} />
          <div
            className={`my-6 flex flex-col md:flex-row gap-4 items-center ${data?.results?.length > 0 ? 'justify-between' : 'justify-end'}`}
          >
            {data?.results?.length > 0 && (
              <span className="text-2xl font-bold font-gilroy flex items-center gap-4">
                {data?.maxResults} properties {isFetching && <Loading width={20} height={20} />}
              </span>
            )}
            <div className="flex flex-nowrap gap-4">
              <Button name="review" onClick={toggleMap} className="border-0  bg-white !rounded-full !px-2 !py-1">
                {!showMap ? (
                  <>
                    <MapViewIcon />
                    View in a Map
                  </>
                ) : (
                  <>
                    <ListIcon width={20} /> View in List
                  </>
                )}
              </Button>
              <HookedForm
                formHook={formHook}
                onChange={(e) => {
                  handleSort(e);
                  refetch();
                }}
                className="w-fit "
              >
                <Select
                  icon={<SortIcon width={15} />}
                  placeholder="Sort by"
                  name="SortBy"
                  triggerClassname="border-0 shadow-sm bg-white !min-w-28 !rounded-full !px-4 !py-2 text-center whitespace-nowrap"
                  value={{ label: 'Distance from search', value: { SortBy: 'DistanceFrom', SortOrder: 'ASC' } }}
                  options={[
                    { label: 'Distance from search', value: { SortBy: 'DistanceFrom', SortOrder: 'ASC' } },
                    { label: 'Price: low to high', value: { SortBy: 'TotalRate', SortOrder: 'ASC' } },
                    { label: 'Price: high to low', value: { SortBy: 'TotalRate', SortOrder: 'DESC' } },
                    // { label: 'Guest rating + our picks', value: { SortBy: 'TotalRate', SortOrder: 'DESC' } },
                    { label: 'Star rating', value: { SortBy: 'SabreRating', SortOrder: 'DESC' } },
                  ]}
                />
              </HookedForm>
            </div>
          </div>
          <div className="w-full pb-10">
            {data?.results?.length > 0 && !isLoading && (
              <AnimatePresence>
                {!showMap && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                  >
                    <ResultsList results={data?.results as unknown as HotelInterface[]} />
                    <div className="flex items-center justify-center py-8">
                      <Button disabled={!data?.hasNextPage} onClick={handleNextPage} kind="secondary">
                        Load more
                      </Button>
                    </div>
                    {/* <QueryLoader scroller={resultsQuery.scroller} /> */}
                  </motion.div>
                )}
                {showMap && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.95 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.95 }}
                  >
                    <ResultsMap results={data?.results as unknown as HotelInterface[]} />
                  </motion.div>
                )}
              </AnimatePresence>
            )}
            {isFetching && (
              <div className="flex items-center justify-center my-10">
                <Loading width={100} height={100} />
              </div>
            )}
            {data?.results?.length === 0 && (!isLoading || !isFetching) && (
              <div className="flex gap-4 flex-col items-center py-6">
                <h3 className="font-bold text-2xl">We don&apos;t have any results for that search :(</h3>
                <button type="button" className="underline text-lg" onClick={() => focusOnLocation()}>
                  Try different places?
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
