import { useQuery } from '@tanstack/react-query';
import { parseRateKey } from '../ResultDetails/utils';
import { getHotelRates, postBooking } from '@app/services/booking';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useUser } from '@app/context/user';
import { FieldValues, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useBooking } from '@app/context/booking';
import { useTravellers } from '@app/context/travellers';
import { getHotelImageFromCategory, getUserBookingSelection } from '@app/utils/utils';
import { HotelRoomSelectionInterface } from '@app/models/search';
import { HotelImageCategories } from '@app/consts/enums';
import { guestBookingValidation } from '@app/utils/validations';
import { useSearch } from '@app/context/search';
import { toast } from 'react-toastify';
import creditCardType from 'credit-card-type';
import { addCard } from '@app/services/tokenizer';
import { format } from 'date-fns';
import { hotelCodes } from '@app/consts/consts';

export const useGuestBookingPage = () => {
  const [searchParams] = useSearchParams();
  const { getPriceFormat } = useUser();
  const { travellers } = useTravellers();
  const { searchParams: searchContext } = useSearch();
  const [bookingError, setBookingError] = useState(false);
  const [isBooking, setIsBooking] = useState(false);
  const [userSelection, setUserSelection] = useState<HotelRoomSelectionInterface | undefined>();
  const [hotelImage, setHotelImage] = useState<string | undefined>();
  const { currentBooking } = useBooking();
  const [cardFieldLimit, setCardFieldLimit] = useState(20);
  const navigate = useNavigate();
  const formHook = useForm({ resolver: guestBookingValidation });
  const rateKey = searchParams?.get('rateKey');
  const { data: rateDetails, isFetching } = useQuery({
    queryKey: ['hotel-details'],
    queryFn: () =>
      getHotelRates({ sourceRateCode: parseRateKey(rateKey ?? '') })
        .then((res) => res.data)
        .catch((err) => {
          toast.error(
            err?.response?.data?.error ??
              err?.data?.error ??
              'Error while trying to retrieve the hotel information or rates.',
          );
          toast.clearWaitingQueue();
        }),
    enabled: Boolean(rateKey),
    retry: false,
  });

  const completeBooking = (values: FieldValues) => {
    setBookingError(false);
    setIsBooking(true);

    addCard({
      cardholderName: values.cardholderName,
      card: values.card?.split(' ').join(''),
      expDate: format(values.expDate, 'yyyy-MM'),
      type: creditCardType(values.card)[0].niceType,
    })
      .then((res) => {
        postBooking({
          cardId: res?.cardId,
          email: values?.email,
          sourceRateCode: parseRateKey(searchParams.get('rateKey') ?? ''),
          phoneNumber: values?.phone,
          travelers: [{ givenName: values.firstName, surname: values.lastName, passengerCode: 'ADT' }],
          ...(values.providerCode && {
            loyaltyPrograms: {
              programNumber: values.providerAccountValue,
              supplierCode: Object.keys(hotelCodes).find(
                (key) => hotelCodes[key as keyof typeof hotelCodes] === values.providerCode,
              ),
            },
          }),
        })
          .then((res) => {
            navigate(`/my-trips/${res?.data?.orderId}/${res?.data?.orderUUID}?status=success`);
          })
          .catch(() => setBookingError(true))
          .finally(() => setIsBooking(false));
      })
      .catch((e) => {
        console.error(e);
      });
  };

  useEffect(() => {
    if (!userSelection && currentBooking && rateDetails) {
      const selectionDetails = getUserBookingSelection(currentBooking, rateDetails);
      const exteriorImage = getHotelImageFromCategory(rateDetails?.media, HotelImageCategories.EXTERIOR, 'MEDIUM');

      setUserSelection(selectionDetails);
      if (exteriorImage && exteriorImage.total > 0) {
        setHotelImage(exteriorImage?.images[0].Url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentBooking, rateDetails]);

  const newCardNumber = formHook.watch('card');

  useEffect(() => {
    if (newCardNumber && newCardNumber.length > 2) {
      const type = creditCardType(newCardNumber);

      if (type.length > 0) {
        setCardFieldLimit(Math.min(...type[0].lengths) + type[0].gaps?.length);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCardNumber]);

  if (!rateKey) {
    navigate('/');
  }

  return {
    travellers,
    getPriceFormat,
    currentBooking,
    rateDetails,
    isFetching,
    formHook,
    completeBooking,
    userSelection,
    hotelImage,
    isBooking,
    bookingError,
    searchContext,
    cardFieldLimit,
  };
};
