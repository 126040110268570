import { useUser } from '@app/context/user';
import { Booking } from '../Booking/Booking';
import { GuestBooking } from '../GuestBooking';

export const BookingHandler = () => {
  const { user } = useUser();
  if (!user) {
    return <GuestBooking />;
  } else {
    return <Booking />;
  }
};
