import { ComponentProps, ComponentType, ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { Menu, MenuButton, MenuItems } from '@headlessui/react';
import { motion, AnimatePresence } from 'framer-motion';
import { Button } from '../Button';
import { Range as RangeSlider } from 'react-range';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const Range = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  placeholder?: string;
  name: string;
  type?: string;
  className?: string;
  triggerClassname?: string;
  label?: string;
  value?: string | number;
  icon?: ReactNode | JSX.Element;
  min?: number;
  max?: number;
}) => {
  const {
    getFieldState,
    control,
    watch,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();
  const rangeFieldValue = watch(componentProps.name);
  const [range, setRange] = useState([
    rangeFieldValue ? rangeFieldValue[0] : componentProps.min ?? 0,
    rangeFieldValue ? rangeFieldValue[1] : componentProps.max ?? 0,
  ]);
  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex flex-col">
      {componentProps.label && <label htmlFor={componentProps.name}>{componentProps.label}</label>}
      <Controller
        control={control}
        name={componentProps.name}
        render={({ field: { onChange, value } }) => (
          <Menu>
            {({ open, close }) => (
              <div className="relative">
                <MenuButton
                  className={`flex items-center gap-2 border relative border-dorrus-button-border p-4 rounded-lg font-normal text-left min-w-fit w-full ${!value ? 'text-dorrus-text-secondary' : ''} ${componentProps.triggerClassname ?? ''}`}
                >
                  {componentProps.placeholder}
                </MenuButton>
                <AnimatePresence>
                  {open && (
                    <MenuItems
                      static
                      as={motion.div}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      anchor="bottom start"
                      className="bg-white rounded-xl w-fit mt-2 p-4 z-20 shadow-md"
                    >
                      <div className="flex justify-between">
                        <span className="text-xs">${componentProps.min}</span>
                        <span className="text-xs">${componentProps.max}</span>
                      </div>
                      <RangeSlider
                        values={range ?? [0, 15000]}
                        step={0.5}
                        min={0}
                        max={15000}
                        onChange={(values) => {
                          setRange(values);
                        }}
                        onFinalChange={(values) => onChange(values)}
                        renderTrack={({ props, children }) => (
                          <div
                            onMouseDown={props.onMouseDown}
                            onTouchStart={props.onTouchStart}
                            style={{
                              ...props.style,
                              height: '36px',
                              display: 'flex',
                              width: '100%',
                            }}
                          >
                            <div
                              ref={props.ref}
                              style={{
                                height: '3px',
                                width: '100%',
                                borderRadius: '50px',
                                background: 'black',
                                alignSelf: 'center',
                              }}
                            >
                              {children}
                            </div>
                          </div>
                        )}
                        renderThumb={({ props, isDragged }) => (
                          <div
                            {...props}
                            key={props.key}
                            style={{
                              ...props.style,
                              height: '15px',
                              width: '15px',
                              borderRadius: '100%',
                              backgroundColor: 'black',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              boxShadow: '0px 2px 6px #AAA',
                            }}
                          >
                            <div
                              style={{
                                height: '5px',
                                width: '5px',
                                borderRadius: '100%',
                                backgroundColor: isDragged ? 'white' : 'black',
                              }}
                            />
                          </div>
                        )}
                      />

                      {range.some((x) => x) && (
                        <div className="text-center">
                          <span className="text-xs font-bold">
                            ${range[0]} - ${range[1]}
                          </span>
                        </div>
                      )}
                      <div className="flex gap-2 mt-2">
                        <Button
                          onClick={() => {
                            onChange([0, 15000]);
                            setRange([0, 15000]);
                            close();
                          }}
                          kind="primary"
                          className="text-sm p-2 h-6 !w-fit border-0"
                          type="button"
                        >
                          Clear
                        </Button>
                        <Button
                          onClick={() => onChange(range)}
                          kind="secondary"
                          className="text-sm p-2 h-6 !w-fit border-t-0"
                          type="button"
                        >
                          Apply
                        </Button>
                      </div>
                    </MenuItems>
                  )}
                </AnimatePresence>
              </div>
            )}
          </Menu>
        )}
      />

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
