import { useContext } from 'react';

import { SearchStateContext } from './utils';

const useSearch = () => {
  const context = useContext(SearchStateContext);
  if (context === undefined) {
    throw new Error('useSearch must be used within an SearchProvider');
  }
  return context;
};

export { useSearch };

export { SearchProvider } from './search';
