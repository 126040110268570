import { Dialog, DialogPanel } from '@headlessui/react';
import { BaseModalProps } from './BaseModal.interfaces';
import CloseIcon from '@app/assets/icons/close.svg?react';
import { AnimatePresence, motion } from 'framer-motion';

export const BaseModal = ({ size = 'max-w-lg', header, children, footer, ...rest }: BaseModalProps) => {
  return (
    <AnimatePresence>
      {rest.open && (
        <Dialog open={rest.open} onClose={rest.onClose} className="relative">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-[9990] inset-0 bg-black/30"
          />
          <div className="fixed inset-0 z-[9991] overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-left sm:items-center sm:p-0">
              <DialogPanel
                as={motion.div}
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className={`bg-white rounded-xl shadow-md w-full ${size}`}
              >
                {header && (
                  <div className="flex items-center justify-between px-6 pt-6">
                    <h2 className="text-2xl font-bold ">{header}</h2>
                    <button onClick={rest.onClose}>
                      <CloseIcon width={20} height={20} />
                    </button>
                  </div>
                )}
                <div className="p-6">{children}</div>
                {footer && <div>{footer}</div>}
              </DialogPanel>
            </div>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
