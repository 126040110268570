import { Navigation } from '@app/components/Navigation';
import { useEffect, useState, type ReactElement } from 'react';
import DorrusFullLogo from '@app/assets/dorrus-logo.svg?react';
import DorrusMinLogo from '@app/assets/dorrus-min-logo.svg?react';
import { useUserGuard } from './UserGuard.hooks';
import { AddPhoneBanner } from '@app/components/AddPhoneBanner';
import { Footer } from '@app/components/Footer';
import { Loading } from '@app/components/Loading/Loading';
import { Link } from 'react-router-dom';
import { AlertInterface } from '@app/models/general';
import { getAlerts } from '@app/services/general';
import { Alert } from '@app/components/Alert/Alert';

export const UserGuard = ({ children }: { children: ReactElement }) => {
  const { hideNavigation, user, hasPadding, fetchingUserData } = useUserGuard();
  const [alerts, setAlerts] = useState<AlertInterface[]>([]);

  const getFrontendAlerts = async () => {
    getAlerts()
      .then((res) => {
        setAlerts(res.data.alerts);
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    getFrontendAlerts();
  }, []);

  return (
    <>
      {alerts.length > 0 && (
        <div className="flex flex-col gap-2 mb-2">
          {alerts.map((alert, _index) => (
            <Alert key={_index} alert={alert} user={!!user?.email} />
          ))}
        </div>
      )}
      {!hideNavigation ? (
        <div className="px-6">
          <Navigation />
        </div>
      ) : (
        <div className="absolute px-6 py-6 flex items-center justify-center w-full">
          <Link to="/">
            <DorrusFullLogo className="hidden md:block" />
            <DorrusMinLogo className="block md:hidden" />
          </Link>
        </div>
      )}

      <div className={`container mx-auto ${!hasPadding ? 'p-6' : ''}`}>
        {!hideNavigation && user?.email && !user.phoneNumber && <AddPhoneBanner />}
        {fetchingUserData && !user && (
          <div className="flex items-center justify-center py-20">
            <Loading width={50} />
          </div>
        )}
        {user && children}
      </div>
      {!hideNavigation && (
        <div className="px-6">
          <Footer />
        </div>
      )}
    </>
  );
};
