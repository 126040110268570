import { PreparedCatalog } from '@app/models/loyalty';
import { Button } from '../Button';

interface CardProps extends PreparedCatalog {
  action?: () => void;
}

export const Card = ({ imageURL, brandName, items, action }: CardProps) => {
  return (
    <div className="rounded-2xl bg-white overflow-hidden shadow-sm p-6 flex flex-col gap-4">
      <div className="-mx-6 -mt-6 w-[calc(100%) + 48px] h-36 relative overflow-hidden">
        <img
          src={imageURL ?? 'https://placehold.co/300x300?text=Gift+Card'}
          alt="placeholder"
          className="absolute inset-0 w-full h-full object-scale-down"
        />
      </div>
      <div>
        <h5 className="font-bold text-2xl">{brandName}</h5>
        <span className="text-dorrus-text-secondary text-sm">{items?.length ?? 0} options available</span>
      </div>
      {action && (
        <Button kind="primary" className="border-0" onClick={action}>
          Select
        </Button>
      )}
    </div>
  );
};
