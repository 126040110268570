import { Button } from '@app/components/Button';
import { useUser } from '@app/context/user';
import { getAddressFromFields, getTotalOfNights, sourceRatingToStars } from '@app/utils/utils';
import { addDays, format } from 'date-fns';
import { useBookingManagement } from './BookingManagement.hooks';
import { BaseModal } from '@app/components/BaseModal';
import { Loading } from '@app/components/Loading/Loading';
import { HookedForm } from '@app/components/HookedForm';
import { DateRange, Radio } from '@app/components/Fields';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { RoomCard } from '@app/components/RoomCard';
import { HotelRoomInterface } from '@app/models/search';
import SearchIcon from '@app/assets/icons/search.svg?react';
import GreenTickIcon from '@app/assets/icons/green-tick.svg?react';
import WarningIcon from '@app/assets/icons/warning.svg?react';

export const BookingManagement = () => {
  const {
    bookingDetails,
    orderLoadgingBookings,
    orderServices,
    cancelModal,
    handleCancelModal,
    isLoading,
    cancelOrder,
    cancelError,
    cancelSuccess,
    isCancelling,
    navigate,
    formHook,
    countGuests,
    pointsPrediction,
    handleChangeDateModal,
    showChangeDate,
    changeDateFormHook,
    handleChangeDateSubmit,
    availableNewRates,
    isPendingDateChange,
    updateRateDate,
    isUpdatingBooking,
    isCanceled,
    isPastBooking,
    isLoadingPoints,
  } = useBookingManagement();
  const { id, uuid } = useParams();
  const { getPriceFormat, user } = useUser();
  const [searchParams] = useSearchParams();
  const isFreshBooking = searchParams.get('status') === 'success';
  const guests = countGuests(orderServices?.lodgingRooms);
  const description = bookingDetails?.hotels?.[0]?.room?.description?.toLocaleLowerCase().split(' ');
  const roomSize = description?.find((word) => word.includes('sqm') || word.includes('sqft') || word.includes('m²'));
  const bookingRoom = orderServices?.lodgingRooms?.[0];

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center">
        <Loading width={50} height={50} />
        <p>Fetching your reservation...</p>
      </div>
    );
  }

  if (!isLoading && (!bookingDetails || !bookingRoom)) {
    return (
      <div className="flex flex-col items-center justify-center min-w-screen space-y-4">
        <WarningIcon width={50} height={50} />
        <p>We couldn't find your booking.</p>
        <p>
          If you are sure the booking exists, you can check the URL if anything is wrong or{' '}
          <Link to="/contact-us" className="underline underline-offset-2">
            get in touch with us
          </Link>
          .
        </p>
        <div className="flex gap-2">
          <Button kind="secondary" className="h-fit">
            Go to Home
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      {isFreshBooking && (
        <div className="bg-white rounded-2xl flex p-6 items-center justify-between mb-10">
          <div className="flex gap-2 items-center">
            <GreenTickIcon width={45} height={45} />
            <div>
              <h6 className="font-bold text-2xl">Thanks {user?.firstName}</h6>
              <h6 className="font-bold text-2xl">
                Your booking to {bookingDetails?.order?.address?.[0]?.cityName} is confirmed.
              </h6>
            </div>
          </div>
        </div>
      )}
      {isPastBooking && !bookingDetails?.feedback && !isCanceled && (
        <div className="bg-white rounded-2xl flex p-6 items-center justify-between mb-10">
          <div className="flex gap-2 items-center">
            <GreenTickIcon width={45} height={45} />
            <div>
              <h6 className="font-bold text-2xl">Your stay is over.</h6>
              <h6 className="font-bold text-2xl">Rate the hotel and leave your feedback.</h6>
            </div>
          </div>

          <div>
            <Button kind="secondary" onClick={() => navigate(`/booking/${id as string}/review/${uuid}`)}>
              Leave a review
            </Button>
          </div>
        </div>
      )}
      <div className="space-y-6 pb-10">
        <div className="grid grid-cols-12 gap-6">
          <div className="col-span-12 lg:col-span-8 space-y-6">
            <h3 className="text-4xl md:text-5xl font-bold">Hotel details</h3>

            <div className="bg-white rounded-2xl flex flex-col lg:flex-row justify-between gap-6 p-6">
              <div className="w-full md:max-w-[220px] h-[160px] md:h-[125px] space-y-2">
                <div className="w-full md:max-w-[220px] h-[160px] md:h-[125px] relative overflow-hidden rounded-xl">
                  <img
                    src={orderLoadgingBookings?.hotelImageUrl ?? '/images/placeholder-hotel.png'}
                    alt="room"
                    className="absolute object-cover object-center w-full h-full"
                  />
                </div>
                <Button
                  onClick={handleChangeDateModal}
                  kind="secondary"
                  className=" mt-auto hidden lg:block print:hidden"
                >
                  {isCanceled || isPastBooking ? 'View Hotel' : 'Modify'}
                </Button>
              </div>

              <div className="mr-auto flex flex-col justify-between w-full gap-4">
                <div className="flex flex-col md:flex-row justify-between items-center">
                  <div>
                    <h5 className="text-2xl font-bold capitalize">{orderLoadgingBookings?.propertyName}</h5>

                    <span>{sourceRatingToStars(JSON.parse(orderLoadgingBookings?.propertyQuality ?? '[]'))}</span>
                  </div>
                </div>

                <div className="my-auto space-y-4">
                  <div className="flex flex-col md:flex-row gap-6">
                    <span>
                      <span className="font-bold">Check-in:</span>{' '}
                      {orderLoadgingBookings?.checkInUTC &&
                        format(orderLoadgingBookings?.checkInUTC ?? '', 'eee, MMM dd, yyyy')}{' '}
                      {bookingDetails?.hotels?.[0]?.checkInTime &&
                        bookingDetails?.hotels?.[0]?.checkInTime !== '00:00' &&
                        `until ${bookingDetails?.hotels?.[0]?.checkInTime}`}
                    </span>
                    <span>
                      <span className="font-bold">Check-out:</span>{' '}
                      {orderLoadgingBookings?.checkOutUTC &&
                        format(orderLoadgingBookings?.checkOutUTC ?? '', 'eee, MMM dd, yyyy')}{' '}
                      {bookingDetails?.hotels?.[0]?.checkOutTime &&
                        bookingDetails?.hotels?.[0]?.checkOutTime !== '00:00' &&
                        `until ${bookingDetails?.hotels?.[0]?.checkOutTime}`}
                    </span>
                  </div>

                  <p>
                    <span className="font-bold">Booking details:</span>{' '}
                    {`${guests ?? 0} ${(guests ?? 0) > 1 ? 'guests' : 'guest'}`},{' '}
                    {orderServices?.lodgingRooms?.[0]?.roomName}
                  </p>
                  <p>
                    <span className="font-bold">Address:</span>{' '}
                    <Link
                      target="_blank"
                      to={`https://www.google.com/maps/search/?api=1&query=${encodeURI(getAddressFromFields(bookingDetails?.order?.address?.[0]))}`}
                    >
                      {getAddressFromFields(bookingDetails?.order?.address?.[0])}
                    </Link>
                  </p>
                </div>
              </div>

              <Button kind="secondary" className=" mt-auto block lg:hidden print:hidden">
                {isCanceled || isPastBooking ? 'View Hotel' : 'Change Dates'}
              </Button>
            </div>

            <h3 className="text-4xl md:*:text-5xl font-bold">Room details</h3>
            <div className="bg-white rounded-2xl p-6 flex flex-col md:flex-row gap-6">
              <div className="w-full min-w-[220px] max-w-[220px] h-[125px] relative overflow-hidden rounded-xl">
                <img
                  src={bookingRoom?.roomImageUrl ?? '/images/room-placeholder.png'}
                  alt="room"
                  className="absolute object-cover object-center w-full h-full"
                />
              </div>
              <div className="space-y-6">
                <h5 className="font-bold text-2xl">{orderServices?.lodgingRooms?.[0]?.roomName}</h5>
                {roomSize && (
                  <p>
                    <span className="font-bold">Size:</span> {roomSize?.replace(/,/g, '')}
                  </p>
                )}
                <ul className="list-disc ml-4">
                  <li>City view</li>
                  <li>Air conditioning</li>
                  <li>Private Bathroom</li>
                  <li>Flat-screen TV</li>
                  <li>Soundproof</li>
                  <li>Minibar</li>
                </ul>
                <p>{description?.join(' ')?.replace(/(?<=(?:^|[.?!])\W*)[a-z]/g, (i) => i.toUpperCase())}</p>
              </div>
            </div>
          </div>
          <div className="col-span-12 lg:col-span-4 space-y-6">
            <div className="flex justify-end gap-4">
              {!isCanceled && !isPastBooking && (
                <>
                  <Button kind="tertiary" disabled={isCanceled} onClick={() => handleCancelModal()}>
                    {isCanceled ? 'This booking was canceled' : 'Cancel'}
                  </Button>

                  {/* <Button className="!w-max" kind="secondary" disabled={isCanceled} onClick={() => window?.print()}>
                    Print full version
                  </Button> */}
                </>
              )}
            </div>
            {isCanceled && (
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <h5 className="font-bold text-2xl mb-2">This booking was canceled.</h5>
              </div>
            )}
            <div className="bg-white rounded-2xl p-6 shadow-lg">
              <h5 className="font-bold text-2xl mb-2">Booking References</h5>
              <p>
                <b>Dorrus:</b> {bookingDetails?.order?.OrderIDText}
              </p>
              <p>
                <b>Hotel:</b> {orderLoadgingBookings?.supplierBookingRef}
              </p>
            </div>
            {!isCanceled && (
              <div className="bg-white rounded-2xl p-6 shadow-lg">
                <h5 className="font-bold text-2xl mb-2">Contact property</h5>
                {orderLoadgingBookings?.contactInfos?.phones &&
                  orderLoadgingBookings?.contactInfos?.phones?.length > 0 && (
                    <p>
                      <span className="pr-1">Phone:</span>
                      {orderLoadgingBookings?.contactInfos?.phones?.map((phone, _index) => (
                        <a key={_index} href={`tel:${phone.phoneNumber}`}>
                          +{phone.phoneNumber}
                        </a>
                      ))}
                    </p>
                  )}
                {orderLoadgingBookings?.contactInfos?.faxes &&
                  orderLoadgingBookings?.contactInfos?.faxes?.length > 0 && (
                    <p>
                      <span className="pr-1">Faxes:</span>
                      {orderLoadgingBookings?.contactInfos?.faxes?.map((fax, _index) => (
                        <a key={_index} href={`tel:${fax.phoneNumber}`}>
                          +{fax.phoneNumber}
                        </a>
                      ))}
                    </p>
                  )}
                {/* <p className="font-bold">Send a message</p> */}
              </div>
            )}
            <div className="bg-white rounded-2xl p-6 shadow-lg space-y-6">
              <h5 className="font-bold text-2xl">Price summary</h5>
              <div className="text-left space-y-4">
                <div className="flex justify-between">
                  <p>
                    1 room x{' '}
                    {getTotalOfNights(
                      orderLoadgingBookings?.checkInUTC ?? new Date().toDateString(),
                      orderLoadgingBookings?.checkOutUTC ?? addDays(new Date(), 1).toDateString(),
                    )}{' '}
                    nights
                  </p>
                  <p className="font-bold">
                    {getPriceFormat(
                      bookingDetails?.order?.totalPrice?.baseAmount ?? 0,
                      true,
                      bookingDetails?.order?.totalPrice?.baseAmountCurrencyCode,
                    )}
                  </p>
                </div>
                <div className="flex justify-between">
                  <p>Taxes and fees</p>
                  <p className="font-bold">
                    {getPriceFormat(
                      bookingDetails?.order?.totalPrice?.taxes ?? 0,
                      true,
                      bookingDetails?.order?.totalPrice?.baseAmountCurrencyCode,
                    )}
                  </p>
                </div>
                <hr />
                <div className="flex justify-between">
                  <p className="font-bold">Total</p>
                  <p className="font-bold text-2xl">
                    {getPriceFormat(
                      bookingDetails?.order?.totalPrice?.totalAmount ?? 0,
                      true,
                      bookingDetails?.order?.totalPrice?.baseAmountCurrencyCode,
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center p-6 bg-white shadow-lg rounded-xl relative z-10 space-y-2">
              <h5 className="font-bold text-2xl">Cancellation Policy</h5>
              <p className="text-dorrus-text-secondary">
                Dorrus does not enforce any cancellation policies, however the property might have a specific change and
                cancellation policy for the selected rate:
              </p>
              <p>{orderLoadgingBookings?.cancelPenaltyDescription}</p>
              <p>This booking is {!orderLoadgingBookings?.cancelRefundable && 'non-'}refundable.</p>
              {orderLoadgingBookings?.cancelPenaltyDescription && (
                <p>{orderLoadgingBookings?.cancelPenaltyDescription}</p>
              )}
              {orderLoadgingBookings?.penaltyStartUTC && (
                <p>
                  Fees start to apply from{' '}
                  {format(orderLoadgingBookings?.penaltyStartUTC ?? new Date(), 'MMM dd, yyyy - hh:mm a')}
                </p>
              )}
            </div>
            {user && (
              <div className="bg-white rounded-2xl p-6 shadow-lg space-y-6 print:hidden">
                <h5 className="font-bold text-2xl">Estimated Rewards</h5>
                <div className="flex justify-between">
                  <p>
                    1 room x{' '}
                    {getTotalOfNights(
                      orderLoadgingBookings?.checkInUTC ?? new Date().toDateString(),
                      orderLoadgingBookings?.checkOutUTC ?? addDays(new Date(), 1).toDateString(),
                    )}{' '}
                    nights
                  </p>
                  {isLoadingPoints && pointsPrediction !== -1 && <p className="font-bold">Loading...</p>}
                  {pointsPrediction === -1 && !isLoadingPoints && (
                    <p className="font-bold">Unable to retrieve points.</p>
                  )}
                  {pointsPrediction >= 0 && <p className="font-bold">{getPriceFormat(pointsPrediction)} points</p>}
                </div>
              </div>
            )}
          </div>
        </div>
        {/* <CallToAction
          image="/images/b4308ea0c22adadde551d5a20d059b78.jpeg"
          title="Upgrade your room"
          text="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        /> */}
      </div>
      <BaseModal header="Cancel your booking" size="max-w-2xl" open={cancelModal} onClose={handleCancelModal}>
        <div className="space-y-4">
          {cancelError && <p className="text-red-500">There was an error while trying to cancel your booking.</p>}
          {cancelSuccess && (
            <>
              <p>Update: Your booking has been canceled.</p>
              <p>Please check your inbox for an email confirming your cancellation.</p>
              <div className="flex justify-center">
                <Button
                  kind="secondary"
                  className="w-[200px]"
                  onClick={() => {
                    navigate('/my-trips');
                    handleCancelModal();
                  }}
                >
                  Ok
                </Button>
              </div>
            </>
          )}
          {!cancelSuccess && (
            <>
              <h5 className="font-bold text-lg">Tell us your reason for canceling</h5>

              <HookedForm formHook={formHook} onSubmit={cancelOrder} className="space-y-4">
                <Radio name="cancelReason" label="Personal reasons" value="Personal reasons" />
                <Radio name="cancelReason" label="Property asked to cancel" value="Property asked to cancel" />
                <Radio
                  name="cancelReason"
                  label="Change of dates or destination"
                  value="Change of dates or destination"
                />
                <Radio
                  name="cancelReason"
                  label="Change in the number or needs of travelers"
                  value="Change in the number or needs of travelers"
                />
                <Radio
                  name="cancelReason"
                  label="Made bookings for the same dates – canceled the ones"
                  value="Made bookings for the same dates – canceled the ones"
                />
                <Radio
                  name="cancelReason"
                  label="Found a different accommodation option"
                  value="Found a different accommodation option"
                />
                <Radio name="cancelReason" label="None of the above" value="None of the above" />
                <div className="flex gap-4">
                  <Button
                    disabled={isCancelling}
                    type="button"
                    kind="primary"
                    onClick={handleCancelModal}
                    className=" border-0"
                  >
                    Keep booking
                  </Button>
                  <Button kind="secondary" loading={isCancelling} onClick={cancelOrder}>
                    Yes, cancel this booking
                  </Button>
                </div>
              </HookedForm>
            </>
          )}
        </div>
      </BaseModal>
      <BaseModal header="Change trip dates" size="max-w-4xl" open={showChangeDate} onClose={handleChangeDateModal}>
        {!availableNewRates.length && (
          <div className="space-y-4">
            <p>Enter new check in and check out dates</p>
            <div className="shadow-md p-4 rounded-xl">
              <HookedForm
                formHook={changeDateFormHook}
                onSubmit={handleChangeDateSubmit}
                className="flex justify-between"
              >
                <DateRange label="Check-in" secondLabel="Check-out" name="newCheckIn" secondName="newCheckOut" />
                <Button
                  loading={isPendingDateChange}
                  type="submit"
                  kind="secondary"
                  className="!w-fit px-6"
                  leftNode={<SearchIcon />}
                >
                  Search
                </Button>
              </HookedForm>
            </div>
          </div>
        )}
        {availableNewRates.length > 0 && (
          <>
            <p>We found this rates on your new dates</p>
            <div className="grid grid-cols-1 gap-4">
              {availableNewRates?.map((roomsAvailable: HotelRoomInterface, index: number) => (
                <div key={index} className="rounded-xl shadow-md">
                  <RoomCard
                    simplified
                    image={undefined}
                    handleClick={updateRateDate}
                    roomName={roomsAvailable.roomName}
                    inDate={roomsAvailable.inDate}
                    outDate={roomsAvailable.outDate}
                    ratesAvailable={roomsAvailable.ratesAvail}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </BaseModal>
      {isUpdatingBooking && (
        <div className="z-[9999] fixed inset-0 w-full h-full bg-black/60 text-white flex items-center justify-center flex-col">
          <Loading width={30} height={30} />
          <p className="text-lg">Updating booking...</p>
        </div>
      )}
    </>
  );
};
