import { Button } from '@app/components/Button';
import { useForgotPassword } from './ForgotPassword.hooks';
import { Input } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { Loading } from '@app/components/Loading/Loading';

export const ForgotPassword = () => {
  const { resetInProgress, resetPassword, formHook, submitForm, error, token, validatingToken, goBack } =
    useForgotPassword();

  return (
    <div className="flex flex-col items-center justify-center h-svh  relative">
      <div className="rounded-lg md:bg-white md:w-[670px]  p-4 md:p-6 space-y-6">
        {validatingToken && token && (
          <div className="flex items-center flex-col gap-2">
            <Loading width={25} height={25} />
            <p className="text-dorrus-text-primary">Validating your request...</p>
          </div>
        )}
        {!validatingToken && (
          <>
            <div className="max-w-[70%] mx-auto">
              {token && (
                <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">Create a new password</h1>
              )}

              {!token && (
                <>
                  <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">
                    Forgot your password?
                  </h1>
                  <p className="text-lg text-dorrus-text-primary text-center">
                    That&apos;s okay, it happens! Click on the button below to reset your password.
                  </p>
                </>
              )}
            </div>
            {error && (
              <div className="flex items-center flex-col">
                <p className="text-dorrus-text-primary">
                  The following error occured while trying to validate your token
                </p>
                <p className="text-[red] mb-4">{error}</p>
                <Button onClick={goBack} kind="secondary" className="max-w-[200px]">
                  Go back
                </Button>
              </div>
            )}
            <div className="flex justify-center items-center">
              {token && !error && (
                <HookedForm formHook={formHook} onSubmit={submitForm} className="space-y-4 w-2/3">
                  <Input name="token" hidden value={token} />
                  <Input
                    placeholder="Password"
                    isRequired
                    name="password"
                    type="password"
                    className="border border-dorrus-button-border rounded-lg w-full p-4"
                  />
                  <Input
                    placeholder="Confirm Password"
                    isRequired
                    name="confirmPassword"
                    type="password"
                    className="border border-dorrus-button-border rounded-lg w-full p-4"
                  />
                  <Button kind="secondary" type="submit">
                    Reset Password
                  </Button>
                </HookedForm>
              )}
              {!token ? (
                resetInProgress ? (
                  <p className="text-dorrus-text-primary">Password reset link sent to your email</p>
                ) : (
                  <Button kind="secondary" className="max-w-fit" onClick={resetPassword}>
                    Reset Your Password
                  </Button>
                )
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
