import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';

type Props = {
  children?: React.ReactNode;
  y?: string;
  // eslint-disable-next-line @typescript-eslint/ban-types
  transition?: {};
  className?: string;
};

export const FadeIn = ({ children, y = '3vh', transition = {}, className = '', ...other }: Props) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      controls.start('visible');
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial="hidden"
      transition={{ duration: 0.5, ease: 'easeInOut', ...transition }}
      variants={{
        visible: { opacity: 1, y: 0 },
        hidden: { opacity: 0, y },
      }}
      {...other}
      className={className}
    >
      {children}
    </motion.div>
  );
};
