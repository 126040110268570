import { useNavigate } from 'react-router-dom';
import { Button } from '../Button';
import { useUser } from '@app/context/user';

export const WorldMap = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  return (
    <div className="relative min-h-[400px]">
      <div className="circle absolute inset-0">
        <img src="/images/HEX.png" />
      </div>
      <div className="absolute inset-0">
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[48%] md:right-[15%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/44df5cb0b3eb50296d53dd2bead6e45d.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[45%] md:left-[18%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/4d5a6d7080bf6f415ba8bd0407de09ea.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[60%] md:right-[45%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/50d35cb8fb75a9cde4fccdb212b1544b.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[30%] md:right-[35%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/0660a30f8f1d9e16efa8e3da70322dcd.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[42%] md:left-[33%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/786b226d5d70e39a1dfa0e817fee1e4d.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[35%] md:left-[32%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/8310448974cf239a72bad8ef817f985f.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[60%] md:right-[29%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/b820ac79b1222acb2b8f4a2f1afff92a.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[70%] md:right-[35%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/e1403438c76c5ca5e98e596e9f590604.jpeg"
          />
        </div>
        <div className="hidden md:block h-8 w-8 rounded-full overflow-hidden absolute md:top-[70%] md:left-[35%] border-white border">
          <img
            className="w-full h-full object-cover object-center"
            src="/images/reviews/eb3065519f49205e5a65e2381d2958ab.jpeg"
          />
        </div>
      </div>

      <div className="absolute inset-0 flex flex-col justify-between">
        <div className="space-y-4">
          <h3 className="font-bold text-3xl md:text-5xl">Discover a World of Benefits with Dorrus</h3>
          <p className="text-dorrus-text-secondary">Start Your Journey Today</p>
          <Button
            onClick={() => (user ? navigate('/loyalty') : navigate('/login'))}
            kind="secondary"
            className="!w-fit"
          >
            Join Dorrus
          </Button>
        </div>
        <div className="bg-white rounded-xl w-fit p-6 space-y-4">
          <img src="/images/users.png" />
          <span className="inline-block text-3xl md:text-8xl font-bold">200+</span>
          <p className="text-dorrus-text-secondary">Trusted by over 200 travelers worldwide</p>
        </div>
      </div>
    </div>
  );
};
