import type { ReactNode } from 'react';
import { useEffect, useReducer } from 'react';

import { bookingsReducer } from './reducers';
import { BookingsStateContext, initialValues } from './utils';
import { useUser } from '../user';
import { getCanceledOrders, getOrders, getPastOrders } from '@app/services/booking';
import { localStorageHandler } from '@app/utils/utils';
import { isEqual } from 'lodash';
import { OrderInterface } from '@app/models/booking';

export const BookingProvider = ({ children }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(bookingsReducer, initialValues);
  const { user } = useUser();
  const { currentBooking, bookings, pastBookings, canceledBookings, fetchingBookings, currentHotel } = state;

  useEffect(() => {
    if (fetchingBookings && user?.email) {
      Promise.all([getOrders(), getPastOrders(), getCanceledOrders()])
        .then((res) => {
          dispatch({
            type: 'setOrders',
            bookings: res[0]?.data?.orders?.filter((order: OrderInterface) => order.orderStatusCode !== 'CLOSED'),
          });
          dispatch({
            type: 'setPastOrders',
            pastBookings: res[1]?.data?.orders?.filter((order: OrderInterface) => order.orderStatusCode),
          });
          dispatch({
            type: 'setCanceledOrders',
            canceledBookings: res[2]?.data?.orders?.filter((order: OrderInterface) => order.orderStatusCode),
          });
        })
        .catch((e) => {
          console.error(e);
          dispatch({ type: 'logout' });
        })
        .finally(() => {
          dispatch({ type: 'setFetching', fetching: false });
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchingBookings]);

  useEffect(() => {
    const localCurrentBooking = localStorageHandler.get('dCurrentBooking');
    if (localCurrentBooking) {
      const parseCurrentBooking = JSON.parse(localCurrentBooking);

      if (isEqual(parseCurrentBooking, currentBooking)) {
        return;
      }
    }
    if (currentBooking) {
      localStorageHandler.set('dCurrentBooking', JSON.stringify(currentBooking));
    }
  }, [currentBooking]);

  useEffect(() => {
    const currentLocal = localStorageHandler.get('dCurrentHotel');
    if (currentHotel) {
      localStorageHandler.set('dCurrentHotel', JSON.stringify(currentHotel));
    } else {
      if (currentLocal) {
        dispatch({ type: 'setCurrentHotel', hotel: JSON.parse(currentLocal) });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentHotel]);

  useEffect(() => {
    const localCurrentBooking = localStorageHandler.get('dCurrentBooking');
    if (!currentBooking && localCurrentBooking) {
      const parseCurrentBooking = JSON.parse(localCurrentBooking);
      dispatch({ type: 'setCurrentBooking', booking: parseCurrentBooking });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    currentBooking,
    currentHotel,
    bookings,
    pastBookings,
    canceledBookings,
    fetchingBookings,
    bookingsDispatch: dispatch,
  };

  return <BookingsStateContext.Provider value={value}>{children}</BookingsStateContext.Provider>;
};
