import { Button } from '@app/components/Button';
import { Navigation } from '@app/components/Navigation';
import { useNavigate } from 'react-router-dom';

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Navigation />
      <section className="flex items-center justify-center flex-col space-y-2">
        <h1 className="text-7xl">404</h1>
        <p>Looks like the page you are trying to access is not available.</p>
        <p>Maybe we should go back to the home page?</p>
        <Button onClick={() => navigate('/')} kind="secondary" className="!w-fit">
          Go Home
        </Button>
      </section>
    </div>
  );
};
