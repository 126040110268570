import { Switch } from '@headlessui/react';
import { ComponentProps, ComponentType } from 'react';
import { Controller, UseFormRegisterReturn, useFormContext } from 'react-hook-form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const SwitchField = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  id?: string;
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number;
}) => {
  const {
    control,
    getFieldState,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex flex-col">
      {componentProps.label && <label htmlFor={componentProps.name}>{componentProps.label}</label>}
      <Controller
        control={control}
        name={componentProps.name}
        render={({ field: { onChange, value } }) => (
          <Switch
            checked={value}
            onChange={onChange}
            className="group inline-flex h-6 w-11 items-center rounded-full bg-gray-200 transition data-[checked]:bg-dorrus-text-primary"
          >
            <span className="size-4 translate-x-1 rounded-full bg-white transition group-data-[checked]:translate-x-6" />
          </Switch>
        )}
      />
      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
