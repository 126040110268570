import { useUser } from '@app/context/user';
import { PreparedCatalog } from '@app/models/loyalty';
import { getRedemptionCatalogs, updateLoyaltyRedemption } from '@app/services/loyalty';
import { groupBy } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';

export const useRedeemLoyalty = () => {
  const { getPriceFormat, user, userDispatch } = useUser();
  const [viewGiftCard, setGiftCard] = useState<PreparedCatalog | undefined>();
  const [successRedeem, setSuccessRedeem] = useState<string | undefined>();
  const formHook = useForm({ defaultValues: { reward: '' } });
  const [loyaltyGiftCards, setLoyaltyGiftCards] = useState<PreparedCatalog[]>([]);

  const getLoyaltyCatalogs = async () => {
    getRedemptionCatalogs()
      .then((res) => {
        const groupedCatalogs = groupBy(res?.data?.catalogs, 'productBrandCode');
        const preparedCatalogs = Object.keys(groupedCatalogs).map((catalogKey) => {
          return {
            catalogKey,
            brandName: groupedCatalogs[catalogKey][0].productBrandName,
            imageURL: groupedCatalogs[catalogKey][0].imageURL,
            items: groupedCatalogs[catalogKey],
          };
        });

        setLoyaltyGiftCards(preparedCatalogs);
      })
      .catch((e) => console.error(e));
  };

  const handleGiftCardModal = (catalogKey?: string) => {
    if (!catalogKey) {
      setGiftCard(undefined);

      formHook.reset({ reward: undefined });
      return;
    }
    const catalog = loyaltyGiftCards.find((c) => c.catalogKey === catalogKey);
    setGiftCard(catalog);
  };

  const handleRedeem = (value?: FieldValues) => {
    updateLoyaltyRedemption(value?.reward)
      .then((res) => {
        setSuccessRedeem(res.data?.loyaltyDetail?.redemptionData);
        userDispatch({ type: 'setFetching', fetching: true });
      })
      .catch((e) => {
        setSuccessRedeem(undefined);
        console.log(e);
        formHook.setError('reward', { message: e?.response?.data?.error });
      });
  };
  useEffect(() => {
    if (viewGiftCard) {
      const reward = viewGiftCard.items[0];

      if (reward) {
        formHook.reset({ reward: String(reward?.entryID) });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewGiftCard]);

  useEffect(() => {
    getLoyaltyCatalogs();
  }, []);

  const selectedReward = formHook.watch('reward');

  return {
    getPriceFormat,
    handleGiftCardModal,
    handleRedeem,
    viewGiftCard,
    formHook,
    selectedReward,
    successRedeem,
    setSuccessRedeem,
    loyaltyGiftCards,
    user,
  };
};
