import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { useRegistration } from './Registration.hooks';
import { Input } from '@app/components/Fields';
import { Button } from '@app/components/Button';
import { FormInitialState } from './Registration.interfaces';

export const RegistrationDetails = () => {
  const { formHookDetails, handleRegistrationDetails } = useRegistration();

  return (
    <div className="flex items-center justify-center h-screen">
      <div className="rounded-3xl md:bg-white md:w-[670px]  p-4 md:p-10 space-y-6">
        <div className="max-w-[70%] mx-auto space-y-6">
          <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">Welcome to Dorrus!</h1>
          <p className="text-center text-lg uppercase">Step 2/3</p>
          <p className="text-lg text-dorrus-text-primary text-center">
            Please share your contact details so we can personalize your experience:
          </p>
        </div>
        <div className="space-y-4 flex items-center flex-col justify-center md:w-2/3 mx-auto">
          <HookedForm<FormInitialState>
            formHook={formHookDetails}
            onSubmit={handleRegistrationDetails}
            className="w-full space-y-4"
          >
            <>
              <Input
                placeholder="Enter email"
                name="email"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                isRequired
                hidden
              />
              <Input
                placeholder="Enter First Name"
                name="firstName"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                isRequired
              />
              <Input
                placeholder="Enter Middle Name"
                name="middleName"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              <Input
                placeholder="Enter Last Name"
                name="lastName"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                isRequired
              />
            </>

            <Button type="submit" kind="secondary">
              Next
            </Button>
          </HookedForm>
        </div>
      </div>
    </div>
  );
};
