import { useBooking } from '@app/context/booking';
import { useSearch } from '@app/context/search';
import { useUser } from '@app/context/user';
import { HotelInterface } from '@app/models/search';
import { getGuestsText } from '@app/utils/utils';
import { addDays, differenceInDays } from 'date-fns';

export const useResultCard = () => {
  const { searchParams } = useSearch();
  const { getPriceFormat } = useUser();
  const { bookingsDispatch } = useBooking();

  const addHotelToStorage = (hotel?: HotelInterface) => {
    bookingsDispatch({ type: 'setCurrentHotel', hotel: hotel });
  };

  return {
    searchParams,
    numberOfNights: differenceInDays(
      searchParams?.outDate ?? new Date(),
      searchParams?.inDate ?? addDays(new Date(), 1),
    ),
    guests: getGuestsText(searchParams),
    getPriceFormat,
    addHotelToStorage,
  };
};
