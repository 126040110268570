import { createContext } from 'react';

import type { SearchContextInterface, State } from './types';
import {
  SearchParamsFiltersInterface,
  SearchParamsFiltersURLInterface,
  SearchParamsInterface,
} from '@app/models/search';
import { OptionType } from '@app/models/utils';

export interface IInfiniteScroller {
  scrollerRef: (v: any) => void;
  rootRef: (v: any) => void;
}

export interface IInfiniteQuery<T> {
  loading: boolean;
  items: T[];
  count: number;
  scroller: IInfiniteScroller | undefined;
}

export const SearchStateContext = createContext<SearchContextInterface | undefined>(undefined);

export const initialValues = {
  results: [],
  fetchResults: false,
  searchParams: undefined,
  filters: undefined,
} as unknown as State;

export const getAllowedParams = (params?: Partial<SearchParamsInterface>, keepLocation?: boolean, asURL?: boolean) => {
  const duplicateParams = { ...params };
  if (!keepLocation) delete duplicateParams?.location;
  delete duplicateParams?.userId;
  delete duplicateParams?.placeID;

  Object.keys(duplicateParams).forEach(
    (key) =>
      (duplicateParams[key as keyof SearchParamsInterface] === undefined ||
        duplicateParams[key as keyof SearchParamsInterface] === '') &&
      delete duplicateParams[key as keyof SearchParamsInterface],
  );

  return asURL ? new URLSearchParams(duplicateParams as unknown as string).toString() : duplicateParams;
};

export const prepareFilters = (filters?: Partial<SearchParamsFiltersInterface>, asURL?: boolean) => {
  if (!filters) return '';
  let filterAsParams: SearchParamsFiltersURLInterface = {};
  Object.keys(filters).forEach((key) => {
    const filterKey = key as keyof SearchParamsFiltersInterface;
    const paramKey = key as keyof SearchParamsFiltersURLInterface;
    if (filters[filterKey]) {
      console.log(filters[filterKey]);
      if (Array.isArray(filters[filterKey])) {
        const onlyValues = (filters[filterKey] as OptionType[])?.map((f) => f.value);

        filterAsParams = { ...filterAsParams, [paramKey]: onlyValues };
        return;
      }
      if ((filters[filterKey] as OptionType).value) {
        filterAsParams = { ...filterAsParams, [paramKey]: (filters[filterKey] as OptionType)?.value as string };

        return;
      }
      filterAsParams = { ...filterAsParams, [paramKey]: filters[filterKey] };

      return;
    }
  });

  return asURL ? new URLSearchParams(filterAsParams as unknown as string).toString() : filterAsParams;
};

export const getLast = <T>(arr: T[]): T => {
  return arr[arr.length - 1];
};
