import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';
import { TravellerInterface } from '@app/models/traveller';

export const postTraveller = async (data: TravellerInterface) => {
  return apiInstance.post(`${ApiRoutes.TRAVELLER}/`, data);
};

export const getTravellers = async () => {
  return apiInstance.get(`${ApiRoutes.TRAVELLER}/`);
};

export const getTraveller = async (id: number) => {
  return apiInstance.get(`${ApiRoutes.TRAVELLER}/${id}`);
};

export const updateTraveller = async (data: TravellerInterface) => {
  const userInfo = { ...data };
  if (userInfo.id) delete userInfo.id;
  if (!userInfo.phoneNumber) delete userInfo.phoneNumber;

  return await apiInstance.put(`${ApiRoutes.TRAVELLER}/${data.id}`, userInfo);
};

export const deleteTraveller = async (id: number) => {
  return await apiInstance.delete(`${ApiRoutes.TRAVELLER}/${id}`);
};
