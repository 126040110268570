import { Button } from '@app/components/Button';
import { Loading } from '@app/components/Loading/Loading';
import { RoomCard } from '@app/components/RoomCard';
import { HotelRoomInterface } from '@app/models/search';
import { getAddressFromFields, getPolicies, getRatingText, sourceRatingToStars } from '@app/utils/utils';
import { Map } from '@vis.gl/react-google-maps';
import { useResultDetails } from './ResultDetails.hooks';
import { ReviewCard } from '@app/components/ReviewCard';
import { ReviewsModal } from '@app/components/ReviewsModal/ReviewsModal';
import { HookedForm } from '@app/components/HookedForm';
import { Select } from '@app/components/Fields';
import { HotelImagesModal } from '@app/components/HotelImagesModal/HotelImagesModal';
import { getAmenityIcon } from '@app/utils/maps';
import SortIcon from '@app/assets/icons/sort.svg?react';
import PinIcon from '@app/assets/icons/pin-map.svg?react';
import { RoomAvailabilityChanger } from '@app/components/RoomAvailabilityChanger';
import { format } from 'date-fns';

export const ResultDetails = () => {
  const {
    feedbackForm,
    handleRoomSelection,
    hotelDetails,
    isFetching,
    isPending,
    // openRateModal,
    // getPriceFormat,
    // userSearchParams,
    // setPaymentType,
    // proceedToPayment,
    showMoreReviews,
    setShowMoreReviews,
    hotelImages,
    hotelFeedback,
    showAllPhotos,
    setShowAllPhotos,
    hotelMainImage,
    hotelGuestRoomImage,
    getRoomCardImage,
    hotelRates,
    mutate,
  } = useResultDetails();

  return (
    <div className="pb-10">
      {isFetching && (
        <div className="flex items-center justify-center my-10">
          <Loading width={100} height={100} />
        </div>
      )}
      {hotelDetails && !isFetching && (
        <div className="space-y-10">
          <section className="bg-white rounded-2xl p-6 mb-6 flex justify-between">
            <div className="space-y-2">
              <div className="flex flex-col items-start md:items-center gap-1">
                <h2 className="font-bold text-3xl md:text-5xl">
                  {hotelDetails?.rateResults?.hotelName ?? 'Loading...'}
                </h2>
                <span className="mr-auto">{sourceRatingToStars(hotelDetails?.rateResults?.propertyQuality)}</span>
              </div>
              <p className="text-dorrus-text-secondary text-sm align-middle">
                <PinIcon className="inline-block [&_path]:stroke-dorrus-text-secondary" />{' '}
                {getAddressFromFields(hotelDetails?.rateResults?.hotelAddress)}
              </p>
            </div>
            <div className="flex gap-2 items-center">
              <div className="flex justify-center flex-col items-end text-right">
                <p className="font-bold">{getRatingText(hotelDetails?.rateResults?.rating)}</p>
                {hotelFeedback && hotelFeedback?.feedbacks?.length > 0 && (
                  <p className="text-sm text-right text-dorrus-text-secondary">
                    {hotelFeedback?.feedbacks.length} reviews
                  </p>
                )}
              </div>
              <div className="w-fit flex items-center justify-center text-lg h-fit bg-dorrus-background-secondary p-4 rounded-xl text-white font-bold">
                {hotelDetails?.rateResults?.rating}
              </div>
            </div>
          </section>
          <section>
            <div className="grid grid-cols-10 gap-4">
              {hotelMainImage ? (
                <img
                  alt="hotel main"
                  className={`w-full h-auto max-h-[530px] rounded-2xl overflow-hidden object-cover col-span-10 ${hotelGuestRoomImage ? 'lg:col-span-7' : 'lg:col-span-10'}`}
                  src={hotelMainImage ?? 'https://placehold.co/2000'}
                />
              ) : (
                <img
                  alt="hotel main"
                  className="w-full max-h-[530px] rounded-2xl overflow-hidden object-cover col-span-10 lg:col-span-10"
                  src="https://placehold.co/2000x2000?text=Image+Not+Available"
                />
              )}
              {hotelGuestRoomImage && (
                <div className="col-span-10 lg:col-span-3 flex gap-4 flex-col max-h-[530px]">
                  {hotelGuestRoomImage.slice(0, 2).map((image, index) => (
                    <div key={index} className="relative h-full overflow-hidden">
                      {index === 1 && (
                        <div className="absolute bottom-2 right-2 rounded-2xl overflow-hidden flex justify-center items-center cursor-pointer object-center">
                          <button
                            className="text-white bg-dorrus-button-border rounded-full p-2"
                            onClick={() => setShowAllPhotos(!showAllPhotos)}
                          >
                            Show all photos
                          </button>
                        </div>
                      )}
                      <img
                        alt="hotel guest room"
                        className="w-full h-full rounded-2xl  object-cover "
                        src={image.ImageItems?.Image[0].Url ?? 'https://placehold.co/2000'}
                      />
                    </div>
                  ))}
                </div>
              )}
            </div>
          </section>
          <section className="grid grid-cols-1 lg:grid-cols-12 gap-6 ">
            <div className="lg:col-span-9 space-y-4">
              <h4 className="text-5xl font-bold">Overview</h4>
              <div className="bg-white rounded-3xl p-6">
                <p>{getPolicies(hotelDetails?.rateResults?.descriptions, 'ShortDescription')}</p>
              </div>
            </div>
            <div className="lg:col-span-3 bg-white rounded-3xl shadow-lg">
              {hotelDetails?.rateResults?.hotelLocation && (
                <Map
                  id="map"
                  mapId={hotelDetails?.sourceHotelCode}
                  defaultZoom={18}
                  defaultCenter={{
                    lat: Number(hotelDetails?.rateResults?.hotelLocation?.latitude),
                    lng: Number(hotelDetails?.rateResults?.hotelLocation?.longitude),
                  }}
                  gestureHandling={'greedy'}
                  className="rounded-xl overflow-hidden w-full h-full"
                />
              )}
            </div>
          </section>
          <section className="space-y-4">
            <h4 className="text-5xl font-bold">Availability</h4>
            <RoomAvailabilityChanger refetch={mutate} isFetching={isPending} />
            {(isPending || isFetching) && (
              <div className="flex items-center justify-center gap-2">
                <Loading width={30} height={30} />
                <p>Looking for new rates</p>
              </div>
            )}
            {!isPending && !isFetching && (
              <div className="grid grid-cols-1 gap-4">
                {hotelRates?.map((roomsAvailable: HotelRoomInterface, index: number) => (
                  <RoomCard
                    key={index}
                    image={getRoomCardImage(String(roomsAvailable.roomTypeCode))}
                    handleClick={handleRoomSelection}
                    roomName={roomsAvailable.roomName}
                    inDate={roomsAvailable.inDate}
                    outDate={roomsAvailable.outDate}
                    ratesAvailable={roomsAvailable.ratesAvail}
                    hotelAmenities={hotelDetails?.rateResults?.amenities}
                  />
                ))}
              </div>
            )}
          </section>
          <section className="space-y-4">
            <h4 className="text-5xl font-bold">Guest reviews</h4>
            {!hotelFeedback && (
              <p className="font-bold my-4">
                This property still don&apos;t have any review, be the first and book a stay now!
              </p>
            )}
            {hotelFeedback?.feedbacks && hotelFeedback?.feedbacks?.length > 0 && (
              <>
                <div className="flex justify-between items-center">
                  <div className="rounded-full bg-white flex px-4 py-2 gap-4">
                    <div className="flex flex-col">
                      <span className="font-bold">Very Good</span>
                      <span className="text-sm text-dorrus-text-secondary">
                        {hotelFeedback?.feedbacks?.length} review{hotelFeedback?.feedbacks?.length > 1 ? 's' : ''}
                      </span>
                    </div>
                    <div className="w-10 h-10 bg-dorrus-background-secondary flex items-center justify-center text-white rounded-xl">
                      {hotelFeedback?.hotelRating?.toFixed(1)}
                    </div>
                  </div>
                  <HookedForm formHook={feedbackForm} mode="onChange" className="w-fit">
                    <Select
                      icon={<SortIcon width={15} />}
                      placeholder="Sort by"
                      name="orderBy"
                      triggerClassname="border-0  bg-white !min-w-28 !rounded-full !px-4 !py-2 text-center"
                      options={[
                        { label: 'Rating: Highest', value: 'HIGHEST_RATING' },
                        { label: 'Rating: Lowest', value: 'LOWEST_RATING' },
                        { label: 'Most Recent', value: 'NEWEST' },
                      ]}
                    />
                  </HookedForm>
                </div>
                <div className="bg-white rounded-3xl p-6">
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 gap-x-20">
                    <div className="flex justify-between items-center">
                      <p>Cleanliness</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgClean ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgClean?.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Accuracy</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgAccuracy ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgAccuracy?.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Communication</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgCommunication ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgCommunication?.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Location</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgLocation ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgLocation?.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Check-in</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgCheckIn ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgCheckIn?.toFixed(1)}</p>
                      </div>
                    </div>
                    <div className="flex justify-between items-center">
                      <p>Value</p>
                      <div className="flex flex-row items-center gap-4">
                        <span className="min-w-[100px] bg-black/50 block w-full h-1  rounded-full overflow-hidden">
                          <span
                            style={{
                              width: `${Math.floor((Number(hotelFeedback?.avgValue ?? 0) / 10) * 100)}%`,
                            }}
                            className="bg-black h-full block"
                          ></span>
                        </span>
                        <p className="font-bold w-[30px]">{hotelFeedback?.avgValue?.toFixed(1)}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {hotelFeedback?.feedbacks
                    ?.slice(0, 4)
                    .map((feedback, index) => (
                      <ReviewCard
                        key={index}
                        id={String(feedback.id)}
                        date={format(feedback.createdUTC, 'MMM dd, yyyy')}
                        fullName={`${feedback?.createdBy?.firstName} ${feedback?.createdBy?.lastName}`}
                        rating={(
                          (feedback?.accuracy +
                            feedback?.checkIn +
                            feedback?.cleanLines +
                            feedback?.communication +
                            feedback?.location +
                            feedback?.value / 6) /
                          10
                        )?.toFixed(1)}
                        review={feedback.note?.slice(0, 250) ?? ''}
                        room={feedback?.order?.orderItems?.[0]?.services?.[0]?.lodgingRooms?.[0]?.roomName}
                      />
                    ))}
                </div>
                <div className="flex items-center justify-center">
                  {hotelFeedback && hotelFeedback?.feedbacks.length > 4 && (
                    <Button kind="secondary" className="!w-fit" onClick={() => setShowMoreReviews(!showMoreReviews)}>
                      Show more
                    </Button>
                  )}
                </div>
              </>
            )}
          </section>
          <section className="space-y-4">
            <h4 id="amenities" className="text-5xl font-bold">
              Property amenities
            </h4>
            <div className="bg-white rounded-3xl p-6">
              <div className="grid grid-cols-1 md:grid-cols-4 xl:grid-cols-6 gap-6">
                {hotelDetails?.rateResults?.amenities?.map(
                  (amenity: { code?: string | number; description?: string }, index: number) => {
                    const Icon = getAmenityIcon(String(amenity.code));
                    return (
                      <p key={index} data-code={amenity.code} className="flex gap-2 items-start">
                        <Icon width={20} height={20} />
                        {amenity.description}
                      </p>
                    );
                  },
                )}
              </div>
            </div>
          </section>
          <section className="space-y-4">
            <h4 className="text-5xl font-bold">Policies</h4>
            <div className="bg-white rounded-3xl p-6 space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <h5 className="font-bold text-lg">Check-in</h5>
                  <p>Check-in from {getPolicies(hotelDetails?.rateResults?.policies, 'CheckIn')}</p>
                  <p>Late check-in subject to availability</p>
                  <p>Minimum check-in age: 18</p>
                </div>
                <div>
                  <h5 className="font-bold text-lg">Check-out</h5>
                  <p>Check-out at {getPolicies(hotelDetails?.rateResults?.policies, 'CheckOut')}</p>
                  <p>Late check-in subject to availability</p>
                  <p>Minimum check-in age: 18</p>
                </div>
              </div>
              {getPolicies(hotelDetails?.rateResults?.descriptions, 'CancellationPolicy') && (
                <div>
                  <h5 className="font-bold text-lg">Policies</h5>
                  <span className="whitespace-break-spaces">
                    {getPolicies(hotelDetails?.rateResults?.descriptions, 'Policies')}
                  </span>
                </div>
              )}
              <div>
                <h5 className="font-bold text-lg">Pets</h5>
                <p>
                  Restrictions apply; for more information contact the property at the number on the booking
                  confirmation
                </p>
              </div>
              <div>
                <h5 className="font-bold text-lg">Cancellation/prepayment</h5>
                <p>{getPolicies(hotelDetails?.rateResults?.descriptions, 'CancellationPolicy')}</p>
              </div>
            </div>
          </section>
        </div>
      )}
      {/* <BaseModal
        size="max-w-sm"
        header="Price Summary"
        open={Boolean(openRateModal)}
        onClose={() => {
          handleRoomSelection(undefined);
          setPaymentType(undefined);
        }}
      >
        <div className="text-left space-y-4">
          <div className="flex justify-between">
            <p>
              1 room x{' '}
              {getTotalOfNights(
                userSearchParams?.inDate ?? new Date().toDateString(),
                userSearchParams?.outDate ?? addDays(new Date(), 1).toDateString(),
              )}{' '}
              nights
            </p>
            <p className="font-bold">{getPriceFormat(openRateModal?.totalRateWithTax ?? 0)}</p>
          </div>
          <div className="flex justify-between">
            <p>Taxes and fees</p>
            <p className="font-bold">
              {getPriceFormat((openRateModal?.totalRateWithTax ?? 0) - (openRateModal?.totalRateNoTax ?? 0))}
            </p>
          </div>
          <hr />
          <div className="flex justify-between">
            <p className="font-bold">Total</p>
            <p className="font-bold text-2xl">
              {getPriceFormat(
                (openRateModal?.totalRateWithTax ?? 0) +
                  ((openRateModal?.totalRateWithTax ?? 0) - (openRateModal?.totalRateNoTax ?? 0)),
              )}
            </p>
          </div>
          <Button kind="secondary" onClick={proceedToPayment}>
            Proceed to Payment
          </Button>
        </div>
      </BaseModal> */}
      {hotelImages && (
        <HotelImagesModal open={showAllPhotos} images={hotelImages} onClose={() => setShowAllPhotos(!showAllPhotos)} />
      )}
      {hotelFeedback && (
        <ReviewsModal
          feedbackForm={feedbackForm}
          hotelReviews={hotelFeedback}
          handleModal={() => setShowMoreReviews(!showMoreReviews)}
          showModal={showMoreReviews}
        />
      )}
    </div>
  );
};
