import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const PrivacyPolicyDocument = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById('cookies');
        if (element) {
          const cookiesPlace = element.getBoundingClientRect();
          window.scrollTo({
            top: cookiesPlace.top,
            behavior: 'smooth',
          });
        }
      }, 1000);
    }
  }, [location]);

  return (
    <>
      <section id="privacy-content">
        Last Modified: 23 May 2024
        <h5>1. This Privacy Policy</h5>
        <p>
          All information described in this Privacy Policy is processed by Dorrus, Inc. (“Dorrus”, “we”, “us”, or “our”)
          as the data controller.
        </p>
        <p>
          This Privacy Policy outlines our practices related to the use, storage, and disclosure of personal information
          collected from or about you when you interact with us through our commercial travel agency services, ancillary
          services, or other services provided by us or on our behalf, including when you use our website Dorrus.com
          (“website”) or our Dorrus App (“App”).
        </p>
        <p>
          This Privacy Policy also details our processing of personal data from individuals representing our business
          customers and suppliers. The processing of information depends on the type and purpose of the information and
          may include collection, organization, storage, alteration, retrieval, consultation, disclosure, restriction,
          erasure, or destruction.
        </p>
        <p>
          Dorrus reserves the right to modify this Privacy Policy at any time without prior notice. Any changes will be
          posted on our website. This Privacy Policy is not a contract and does not create any contractual rights or
          obligations.
        </p>
        <h5>2. How to contact us</h5>
        <p>
          Dorrus has a Privacy Team, including a Data Protection Officer, dedicated to protecting your privacy. If you
          have any questions or comments regarding this Privacy Policy or any complaints about our adherence to it,
          please contact our Data Protection Officer, Jane Thibault-Smith, at privacy@dorrus.com, or contact our
          Customer Care Center by telephone or mail.
        </p>
        <p>
          We comply with applicable notification requirements and reporting obligations to supervising authorities
          and/or data subjects regarding violations of this Privacy Policy as required by law. The substance and time
          frames applicable to these remedies are set forth in the relevant laws. Additional remedies available in the
          event of a perceived violation of your privacy rights are discussed in Sections 9.7 and 12 regarding
          information security safeguards and data breach notification procedures.
        </p>
        <h5>3. How does Dorrus collect and receive your information</h5>
        <h5>3.1 We collect information from you</h5>
        <p></p>We may collect and receive information from you, including when you:
        <ul>
          <li>
            Use or access our Website or App, such as when you enter information during the course of a booking, even if
            you do not complete a booking.
          </li>
          <li>
            Register, create, or modify an online or in-app account with us, including your Dorrus Partners account and
            Promotional Partners accounts, or any account to use our online corporate travel tools, or communicate with
            us by email, including when you email us at privacy@dorrus.com, by telephone, in writing, through our
            customer service pages, or social media.
          </li>
          <li>
            Our Dorrus Partners include hotels, car rental companies, shopping, dining, vacation, and other businesses
            with whom you can earn, transfer, or redeem points in the Dorrus Rewards Program or otherwise qualify for
            discounts or benefits in connection with Dorrus Rewards membership or Elite Status. Our Promotional Partners
            are businesses with whom you may be offered a discount or benefit as a result of your transactions with
            Dorrus.
          </li>
          <li>Through cookies and other tracking technologies on our Website and App (see Section 6).</li>
          <li>Are associated with a company we do business with.</li>
        </ul>
        <h5>3.2 Automatic Access and Data Collection from our App</h5>
        <p>Our App may automatically access certain device information and/or components.</p>
        <h5>3.3 We collect information from other sources</h5>
        <p>
          We may receive your personal information from other travel providers, travel agencies, and your employer or
          company in connection with your travel booking. We may also receive your personal information from our Dorrus
          Partners and Promotional Partners in connection with our Dorrus Rewards Program. Our suppliers and other
          businesses we deal with provide us with personal information of their representatives.
        </p>
        <p>
          This Privacy Policy applies to information we receive from these sources, as well as to information we collect
          from you or generate about you. Dorrus is committed to limiting the information collected to what is necessary
          for the purposes for which it is collected.
        </p>
        <h5>4. How does Dorrus use your data</h5>
        <h5>4.1 Information about you and how we use it</h5>
        <p>
          This section describes the personal information we collect about you, the purposes for which we use it, and
          the legal basis under applicable law pursuant to which we process it. Please also see Section 4.2 regarding
          information collected through our App, and Section 4.6 regarding our use of sensitive types of information.
        </p>
        <h5>5. Data retention</h5>
        <p>
          We store your information for as long as legally required or necessary to support the business purposes
          described in Section 4 and consistent with our record retention policies. In general, this storage will be (i)
          at least for the duration of our relationship, (ii) for as long as you can bring a claim against us and for us
          to be able to defend ourselves, and (iii) for any period required by tax, aviation, and other applicable laws
          and regulations.
        </p>
        <p>
          Our App will store user-provided data for as long as you use the related feature of the App, unless you choose
          to delete the App. Only information provided for travel clearance, purchases, and related activities will be
          stored in our operational systems as needed to provide your requested services.
        </p>
        <h5>6. How Dorrus uses Cookies, Tags, and similar Technologies</h5>
        <h5 id="cookies">6.1 Cookies</h5>
        <p>
          Dorrus uses cookies, tags, and other similar technologies. For simplicity, we refer to all these technologies
          as “cookies.” These technologies allow us to recognize your preference information, keep track of your
          purchases, remember your Dorrus Rewards number, and facilitate effective website administration. We use the
          information we collect to enhance your visit to the website and to provide you with information tailored to
          your needs.
        </p>
        <p>
          Your internet browser(s) will offer you the option to refuse cookies, but doing so may affect your use of some
          portions of Dorrus&apos; and our Dorrus Rewards Partners&apos; and Promotional Partners&apos; web services.
          Please refer to your browser options to learn more about cookie management.
        </p>
        <p>
          Dorrus has engaged third-party tracking and advertising providers to act on Dorrus&apos; behalf to track and
          analyze your usage of our website through the use of cookies, pixel tags/web beacons, and similar
          technologies. At our request, these third parties collect and share with us usage information about visits to
          our website, measure and research the effectiveness of our advertisements, track page usage and paths followed
          during visits to our website, help us target our internet banner advertisements on our website and on other
          sites, and track use of our internet banner advertisements and other links from our marketing partners&apos;
          sites to our website. To learn about the available mechanisms for opting out of cookies and technologies
          related to internet-based advertising, see the descriptions and links in the table below.
        </p>
        <p>
          Our website and App are not configured to read or respond to “do not track” settings or signals in your
          browser headings or mobile device settings, which vary by provider. In lieu of a browser-based or device-based
          opt-out solution, we have identified in the table below certain Digital Advertising Alliance and other
          approved methods for placement of “opt-out” cookies. Our website and those of our third-party advertisers or
          other vendors are configured to read and honor these opt-out cookies, so long as they are present on your
          computer or device. If you delete all cookies, you will need to reset your opt-out cookies.
        </p>
        <p>Please be aware that Dorrus does not control these opt-out processes.</p>
        <h5>7. With Whom does Dorrus Share your Information</h5>
        <h5>7.1 In general</h5>
        <p>
          We do not sell your name or other personal information to third parties and do not intend to do so in the
          future. We may disclose the information we collect or receive:
        </p>
        <ul>
          <li>
            To third parties to process your information on our behalf, or to assist us in providing our services and/or
            products, such as our customer care center operators and providers or technical services such as data
            centers, biometric verification services, and online tools.
          </li>
          <li>
            To the party providing the service or product when you request or purchase services or products through
            Dorrus that are to be provided by another party (for example, a travel segment on a third-party carrier,
            hotel accommodations, or rental car).
          </li>
          <li>
            To you or those acting on your behalf. Where local regulations require, we may obtain your consent in
            writing to allow someone else to act on your behalf.
          </li>
          <li>
            To our Dorrus Rewards Partners, Promotional Partners, and other Adamo Systems, Inc. Group Companies for the
            purposes described in and in accordance with this Privacy Policy. Further information regarding Adamo
            Systems, Inc. Group Companies is available upon request.
          </li>
          <li>
            To third-party airlines for the purpose of servicing your travel, servicing your flight reservation,
            recognizing your loyalty program status, rebooking your flight in the event of an unexpected disruption in
            your travel, or protecting crewmembers, customers, and the overall safety and security of flight operations
            and those of our partners and suppliers.
          </li>
          <li>
            Where your Dorrus Rewards account is associated with your employer&apos;s or corporate business account, or
            when you use your employer&apos;s corporate account name, number, or a corporate form of payment to book
            your travel, to that employer or company, to third-party expenses administration providers, to suppliers of
            corporate travel assistance services, to travel agencies and corporate travel managers through which your
            travel was arranged, and to duty of care providers.
          </li>
          <li>To banks, financial firms, and payment services for processing payments and refunds.</li>
          <li>
            To government agencies and authorities, law enforcement officials, law courts, or to third parties: (a) if
            we believe disclosure is required by applicable law, regulation, or legal process (such as pursuant to a
            subpoena or judicial order); (b) to protect and defend our rights or the rights, health, or safety of third
            parties, including to establish, make, or defend against legal claims; or (c) in the interest of public
            health and safety.
          </li>
          <li>
            To customs and immigration authorities, which require by law access to booking and travel itinerary
            information including “Advance Passenger Information” (passport and associated personal information) for all
            customers/passengers prior to travel.
          </li>
          <li>To customers traveling under the same booking as you.</li>
          <li>
            To persons providing services to Dorrus, including its professional advisers (e.g., auditors, lawyers, and
            technical consultants).
          </li>
          <li>
            To persons with whom we are discussing selling any part of our business or to whom we sell any part of our
            business.
          </li>
        </ul>
        <p>
          We may share with third parties anonymous, aggregated information about all our users. If your order or ticket
          purchase is made pursuant to a corporate incentive agreement, we may disclose information concerning your
          travel to your employer or corporate travel manager.
        </p>
        <p>
          When you use the App, a unique ID (which varies by device manufacturer and operating system) is read from or
          associated with your device to be used as an anonymous identifier for analytics and performance purposes. This
          ID might be generated by or shared with third parties providing us with analytics services, such as Adobe.
        </p>
        <p>
          If you have questions about third parties with whom Dorrus may disclose information, please email
          privacy@dorrus.com.
        </p>
        <h5>7.2 Social Media and Messaging Platforms</h5>
        <p>
          You may wish to share information regarding your travel or other activities with Dorrus on social media and
          messaging platforms provided by third parties. To utilize social media and message-sharing features, you will
          be prompted to grant permissions within those third-party platforms, as you choose. For example, you will need
          to allow account login and publishing permissions.
        </p>
        <p>
          If you choose to contact or interact with us on a third-party social media or messaging platform, you
          understand that you are responsible for reviewing and understanding the terms and conditions, information
          security practices, and privacy policy applicable to the third-party platform, and that Dorrus is not
          responsible for how third-party social media and messaging platforms use, share, or protect your information.
        </p>
        <p>
          The activity on third-party social media and messaging platforms, and the data processed, stored, and provided
          on them, including data regarding interactions with Dorrus, is governed by the terms and conditions,
          information security practices, and privacy policy of those third-party platforms.
        </p>
        <h5>7.3 Disclosure of personal information to customers traveling under the same booking</h5>
        <p>
          When you make a booking, you will be provided with a booking reference such as a confirmation number, record
          locator, or order reference. Your booking reference should be kept confidential at all times. Disclosing your
          booking reference to other customers may allow them to access your booking details through our systems. If you
          are traveling with others under the same booking and would prefer your individual booking details not to be
          disclosed to them, you may prefer to have each person make and pay for separate bookings.
        </p>
        <h5>7.4 Contact Tracing</h5>
        <p>
          To promote healthy travel and prevent the spread of COVID-19, all Dorrus customers entering the United States,
          including U.S. passport holders, are encouraged to provide contact tracing information. Participation in
          contact tracing is voluntary unless specified by a specific flight, country, city, or region. The Center for
          Disease Control and Prevention (CDC) in collaboration with the U.S. Customs and Border Protection (CBP)
          supports domestic COVID-19 control efforts by making contact information for customers traveling
          internationally available to state and local health departments to contact you and provide follow-up
          instructions for testing and/or potential quarantine. Dorrus shares this information with the CDC via CBP
          utilizing established channels for the Advance Passenger Information System (APIS). For mor information, go to
          www.CDC.gov or www.CBP.gov, or see CBP&apos;s Privacy Impact Assessment for the Advance Passenger Information
          System for details about how CBP uses APIS information.
        </p>
        <h5>7.5 Unaccompanied Minors</h5>
        <p>
          When children travel alone on flights for which we sell tickets, we take every step to ensure the child&apos;s
          safety. Dorrus may disclose information pertaining to an unaccompanied minor&apos;s itinerary to ensure the
          safety and security of the child traveling on Dorrus-ticketed flights, comply with applicable laws and
          regulations, and coordinate transportation and other services with the child&apos;s parents or guardian.
        </p>
        <h5>8. Correcting or Updating Your Information or Marketing Preferences; Withdrawal Consent</h5>
        <p>
          If you believe that any information we hold about you is incorrect or incomplete, or if you want to change
          your marketing preferences, you have a number of options:
        </p>
        <ul>
          <li>
            You can log in and visit our Dorrus Rewards Partners and Promotional Partners on our Website if you want to
            modify your subscription, email or contact preferences.
          </li>
          <li>
            You can withdraw consent you have given us to process your information at any time by opting out in your
            Dorrus Rewards Profile, or by contacting us by using the Email Us page.
          </li>
          <li>
            You may contact us by using the Email Us page if you have questions about removing your name from our
            subscription lists.
          </li>
          <li>
            Assistance in changing contact preference for those with disabilities is available at +1-612-555- 5555.
          </li>
        </ul>
        <p>
          We will make reasonable efforts to revise any information that is incorrect, or update or change your
          information or preferences as we are required to do by applicable law.
        </p>
        <p>Please see section 6 regarding consents to cookies and tracking.</p>
        <h5>9. Your Rights</h5>
        <p>
          This section 9 applies only to individuals in the European Union, United Kingdom and other countries which
          grant the rights described here. These rights will apply only in certain circumstances.
        </p>
        <h5>9.1 Requesting a copy of your information</h5>
        <p>
          To the extent you are legally entitled, you can request confirmation of whether or not we process your
          personal information, and details of the information that we hold about you and how we use it.
        </p>
        <p>You also have a right to access your personal information and to be provided with a copy.</p>
        <h5>9.2 Right to rectification of personal data</h5>
        <p>
          If you believe that the personal data, we hold about you is inaccurate, you may request that we correct it.
          You may also request us to complete personal data about you which is incomplete.
        </p>
        <h5>9.3 Right to restrict processing of personal data</h5>
        <p>
          You have the right to request that we restrict processing of your personal information where one of the
          following applies:
        </p>
        <ul>
          <li>
            You claim that the personal data is not accurate. The restriction will apply until we have taken steps to
            ensure the accuracy of the personal data.
          </li>
          <li>The processing is unlawful, but you do not want us to erase the personal data.</li>
          <li>
            We no longer require the personal data for the purposes of processing, but you still need it in connection
            with a legal claim.
          </li>
          <li>
            You have exercised your right to object to the processing. The restriction will apply until we have taken
            steps to verify whether we have compelling legitimate grounds to continue processing.
          </li>
        </ul>
        <h5>9.4 Right to request deletion of personal data (“right to be forgotten”)</h5>
        <p>
          You may request the erasure, suspension of processing or anonymization of your personal information in certain
          circumstances. For example, you may request erasure, suspension of processing or anonymization if:
        </p>
        <ul>
          <li>
            The personal information is no longer necessary in relation to the purposes for which it was collected or
            otherwise processed.
          </li>
          <li>
            You have withdrawn your consent, and we have no other legal basis for processing the personal information.
          </li>
          <li>
            You have exercised your right to object to processing the personal information, and we have no overriding
            legitimate grounds to continue processing it.
          </li>
          <li>
            The personal information is excessive for the purposes for which it was collected or otherwise processed.
          </li>
        </ul>
        <p>
          Under certain circumstances, we may refuse a request for erasure, suspension of processing or anonymization,
          for example, where we need to use the personal data to comply with a legal obligation or to establish, make or
          defend legal claims.
        </p>
        <p>
          If you exercise your right to erasure or anonymization, this will potentially remove records which we hold for
          your benefit, such as your Dorrus Rewards account information (including points that may be in your Dorrus
          Rewards account) and your presence on a marketing suppression list.
        </p>
        <h5>9.5 Right to object to processing of personal data</h5>
        <p>
          You have a right, at any stage, to object to our using your personal information to send you marketing
          information.
        </p>
        <p>
          You also have the right to object to our using your personal information where our reason is based on our
          legitimate interests. We will have to stop processing until we can establish that we have compelling
          legitimate grounds which override your interests, rights, and freedoms, or that we need to continue using it
          for the establishment, exercise, or defense of legal claims.
        </p>
        <h5>9.6 Right to data portability</h5>
        <p>This right applies where:</p>
        <ul>
          <li>
            Our reason for using your personal information is either that you have given consent or that the processing
            is necessary for us to perform a contract with you; and
          </li>
          <li>We process the personal information by automated means.</li>
        </ul>
        <p>
          This is a right to receive all the personal information which you have provided us in a structured, commonly
          used, and machine-readable format and to transmit this to another controller directly, where this is
          technically feasible.
        </p>
        <h5>9.7 Complaints to regulator</h5>
        <p>
          You have a right to complain to a supervisory authority (i.e., a regulator which oversees data protection law
          compliance), for example, in the U.K. or the European Union country where you live or work or where you
          believe we have infringed your privacy rights.
        </p>
        <h5>9.8 Rights Applicable Only in Brazil</h5>
        <h5>9.8.1 Right to request information concerning data transfer</h5>
        <p>
          You have the right to request information identifying the public and private entities with which your personal
          information has been shared.
        </p>
        <h5>9.8.2 Information on Right to Deny Consent</h5>
        <p>You have the right to request information concerning the consequences of not giving your consent.</p>
        <h5>9.9 Exercising these rights</h5>
        <p>
          We may charge you a small administrative fee to respond to your request as allowed by applicable law. In
          general, we do not charge any fee where the right is based on European Union law, U.K. law, the Brazilian
          General Data Protection law, and other applicable laws. (We can charge an administrative fee for extra copies
          of your information and in certain exceptional circumstances.)
        </p>
        <p>
          Where we receive a request to exercise one of these rights, we will provide information on the action we take
          on the request without undue delay and in any event within one month of receipt of the request. This time may
          be extended by a further two months in certain circumstances, for example, where requests are complex or
          numerous.
        </p>
        <p>
          Where we do not carry out your request, we will tell you without delay and in any event within one month of
          receipt of the request, and we will explain our reasons for not takin the action requested.
        </p>
        <p>
          Any request you make must be in writing and include your name and address and any other information that may
          identify you, such as where you request relates to a travel booking, please provide your booking reference
          (e.g., confirmation number, record locator number or order reference), Dorrus Rewards Account number if you
          have one, the dates on which the travel took place, and any other relevant information that will assist us to
          identify your booking.
        </p>
        <p>
          You must also provide a copy of your passport of driver&apos;s license so we can verify your identity. Please
          send your written requests to:
        </p>
        <p>
          Data Protection Officer
          <br />
          229 Minnetonka Ave. S #772
          <br />
          Wayzata, MN 55391
          <br />
          United States
        </p>
        <p>
          Or you can submit this request to our Data Protection Officer by emailing us at{' '}
          <a href="mailto:privacy@dorrus.com">privacy@dorrus.com</a>.
        </p>
        <h5>9.10 Representation</h5>
        <p>
          As regards Dorrus&apos; personal data processing activities regulated by the U.K. G.D.P.R., Dorrus has
          appointed its branch at xxxx, London as its UK representative.
        </p>
        <p>
          As regards Dorrus&apos; personal data processing activities regulated by the G.D.P.R., Dorrus has appointed
          its branch at xxx, Paris, France as its European Union representative.
        </p>
        <h5>10.Minors</h5>
        <p>
          Other than information required to complete a booking, Dorrus does not knowingly collect personal identifiable
          information from children under the age of 13. If a child under 13 has provided us with personal information
          without parental or guardian consent, the parent or guarding may contact us by emailing us at
          <a href="mailto:privacy@dorrus.com">privacy@dorrus.com</a>.
        </p>
        <p>
          We will remove the information and unsubscribe the child from any of our electronic marketing lists. Where
          required by applicable law, we may ask children for consent form their parents or guardians before we book
          their flights or provide a product or service to them.
        </p>
        <h5>11.Links</h5>
        <p>
          Our Website contains links to other sites. We are not responsible for the content or privacy practices of
          those sites, and Dorrus&apos; Privacy Policy does not apply to information collected from you by those sites.
          We encourage you to read the privacy statements of each site that collects information from you. When you are
          leaving our Website via a link to interact with a site that is not governed by this Privacy Policy, a new
          browser window will open.
        </p>
        <h5>12.Information Security</h5>
        <p>
          Information Security is important to Dorrus. We have established appropriate physical, electronic, and
          managerial safeguard to protect the information we collect in accordance with this Privacy Policy.{' '}
        </p>
        <p>
          These safeguards are regularly reviewed to protect against unauthorized access, disclosure, and improper use
          of your information, and to maintain the accuracy and integrity of that data.
        </p>
        <p>
          In the event of a data breach, we adhere to data breach notification requirements and incident reporting
          obligations to supervising authorities and/or data subjects, as required by applicable laws. The substance and
          time frames applicable to these remedies are set forth in applicable laws. Please note that your information
          may be processed on third-party infrastructure that is not owned by Dorrus or its affiliates.
        </p>
        <h5>13.Data Transfers</h5>
        <p>
          Dorrus is based in Wayzata, Minnesota, United States, and we may transfer personal information in compliance
          with applicable law to the United States and other jurisdictions where Dorrus provides services.
        </p>
        <p>
          Some of the countries where Dorrus operates or where third parties operate on behalf of Dorrus may not have
          the equivalent level of data protection laws as those in your location. If we need to transfer personal data
          from your location to such countries, we will take steps to make sure your personal data continues to be
          protected and safeguarded.
        </p>
        <p>
          In particular, we may require parties to whom we transfer your data to agree to abide by suitable contractual
          obligations, such as, in the case of transfers from the E.U. or U.K., the Model Clauses approved by the
          European Commission and permitted under Article 46 of the European Union General Data Protection Regulation
          (“GDPR”) or the U.K. secretary of state or under other relevant body.
        </p>
        <p>
          If you would like to obtain the details of such safeguards, you can request them from the Data Protection
          Officer at <a href="mailto:privacy@dorrus.com">privacy@dorrus.com</a>. In some limited circumstances, we may
          also transfer your information from your location to other countries, where permitted by applicable law (for
          example under Article 49, G.D.P.R. in the case of transfers from the European Economic Area). This includes
          where it is necessary for the performance of a contract between us and you and where the transfer is necessary
          in connection with legal proceedings.
        </p>
        <p>
          To exercise your rights with respect to personal data transferred to third parties, you may submit your
          request directly to Dorrus in accordance with Section 9.
        </p>
        <h5>14.Promotional Partners</h5>
        <p>
          We want to give you more opportunities to earn points in the Dorrus Rewards Program. One of the ways we do
          this is by sharing your information with Promotional Partners. Typically, these promotional offerings are
          limited in quantity and geographic reach and targeted to specific demographic audiences. If you fall within
          the audience a Promotional Partner is trying to reach, and (where required under applicable law) you have
          consented to receive information about their services, you may receive a promotional e-mail or direct mail.
          Although we select our partners with care, these partners have their own privacy policies that apply to the
          way they use your personal data.
        </p>
        <p>
          If you do not want us to share your information with one of these partners, you can always opt-out by
          contacting us by using the Email Us page or by calling our Dorrus Rewards Service Center at{' '}
          <a href="tel:+18001231234">+1-800-123-1234</a>
        </p>
        <h5>15.Dorrus Rewards Program</h5>
        <p>
          In addition to the Data Transfers explained in section 13, information you provide in connection with the
          Dorrus Rewards Program (as summarized in the table at section 4.1.c.), may be transferred to Dorrus entities
          in other jurisdictions, including the Cayman Islands. This may include sensitive information as defined under
          the Data Protection Law, 2017 of the Cayman Islands (“DPL”), the G.D.P.R., the U.K. G.D.P.R., or other
          applicable law if you have provided such information to Dorrus in connection with your Dorrus Rewards account.
          Where your information is shared with a Dorrus entity established in the Cayman Islands, you may have various
          rights under the DPL. These rights are similar to the rights described in section 9. Where your information is
          shared with a Dorrus entity established in the Cayman Islands, you also may have the right to lodge a
          complaint with the Cayman Islands Ombudsman. Any onward transfer of your information by any Dorrus entities in
          the Cayman Islands shall be in accordance with the requirements of the DPL and may include measures similar to
          those described in section 13 above. If you have any questions regarding this section, or if you would like to
          exercise your data protection rights under the DPL, please contact our Data Protection Officer at
          <a href="mailto:cayman.privacy@dorrus.com">cayman.privacy@dorrus.com</a> or contact our Customer Care by
          telephone or mail.
        </p>
      </section>
    </>
  );
};
