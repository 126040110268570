import { DatePicker, Input, Phone, Searchable } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { useGuestBookingPage } from './GuestBooking.hooks';
import { add, format } from 'date-fns';
import { Button } from '@app/components/Button';
import {
  formatCard,
  getAddressFromFields,
  getGuestsText,
  getTotalOfNights,
  sourceRatingToStars,
} from '@app/utils/utils';
import { Loading } from '@app/components/Loading/Loading';
import { hotelCodes, yearAndMonthMask } from '@app/consts/consts';

export const GuestBooking = () => {
  const {
    formHook,
    getPriceFormat,
    completeBooking,
    userSelection,
    hotelImage,
    isFetching,
    isBooking,
    bookingError,
    searchContext,
    cardFieldLimit,
  } = useGuestBookingPage();

  return (
    <div className="grid grid-cols-1 md:grid-cols-12 gap-10">
      {isFetching && (
        <div className="col-span-1 lg:col-span-4">
          <div className="flex justify-center items-center flex-col">
            <Loading width={50} height={50} />
            Fetching booking details...
          </div>
        </div>
      )}
      {!isFetching && (
        <div className="order-last md:order-first col-span-1 md:col-span-4 flex gap-6 flex-col">
          <div className="bg-white rounded-2xl p-6 shadow-lg space-y-4">
            <div className="relative h-28 overflow-hidden rounded-xl">
              <img
                src={hotelImage ?? 'https://placehold.co/2000x2000?text=Image+Not+Available'}
                alt="hotel front"
                className="absolute inset-0 object-cover object-center h-full w-full"
              />
            </div>
            <div>
              <h5 className="font-bold text-2xl">Hotel details</h5>
              <p>{userSelection?.hotelName}</p>
              {sourceRatingToStars(userSelection?.propertyQuality)}
              {getAddressFromFields(userSelection?.address)}
            </div>
          </div>
          <div className="bg-white rounded-2xl p-6 shadow-lg space-y-4">
            <h5 className="font-bold text-2xl">Booking details</h5>
            <table>
              <tbody>
                <tr>
                  <td>
                    <p className="font-bold">Check-in:</p>
                  </td>
                  <td>
                    <p>
                      {userSelection?.room?.inDate && format(userSelection?.room?.inDate ?? '', 'eee, MMM dd, yyyy')}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="font-bold">Check-out:</p>
                  </td>
                  <td>
                    <p>
                      {userSelection?.room?.outDate && format(userSelection?.room?.outDate ?? '', 'eee, MMM dd, yyyy')}
                    </p>
                  </td>
                </tr>
                <tr>
                  <td>
                    <p className="font-bold">Booking details:</p>
                  </td>
                  <td>
                    <p>
                      {getGuestsText(searchContext)}, {userSelection?.room?.roomName}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="bg-white rounded-2xl p-6 shadow-lg space-y-4">
            <h5 className="font-bold text-2xl">Price summary</h5>
            <div className="text-left space-y-4">
              <div className="flex justify-between">
                <p>1 room x {getTotalOfNights(userSelection?.room?.inDate, userSelection?.room?.outDate)} nights</p>
                <p className="font-bold">
                  {getPriceFormat(userSelection?.room?.totalRateNoTax ?? 0, true, userSelection?.room?.currencyCode)}
                </p>
              </div>
              <div className="flex justify-between">
                <p>Taxes and fees</p>
                <p className="font-bold">
                  {getPriceFormat(userSelection?.room?.taxTotalAmount ?? 0, true, userSelection?.room?.currencyCode)}
                </p>
              </div>
              <hr />
              <div className="flex justify-between">
                <p className="font-bold w">Total</p>
                <p className="font-bold text-2xl">
                  {getPriceFormat(userSelection?.room?.totalRateWithTax ?? 0, true, userSelection?.room?.currencyCode)}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-span-1 md:col-span-8 space-y-8">
        <h3 className="text-4xl md:text-5xl font-bold">Enter your details</h3>
        <HookedForm id="bookingForm" formHook={formHook} onSubmit={completeBooking} className="space-y-8">
          <div className="bg-white rounded-2xl p-6 flex flex-col gap-4">
            <div className="flex flex-col md:flex-row gap-4">
              <Input
                name="firstName"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                containerClass="w-full"
                placeholder="First Name"
              />
              <Input
                name="lastName"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
                containerClass="w-full"
                placeholder="Last Name"
              />
            </div>
            <Input
              name="email"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              placeholder="Email address"
            />
            <Phone name="phone" placeholder="Mobile phone number" />
          </div>
          <div className="flex items-start md:items-center justify-between flex-col md:flex-row gap-2 md:gap-0">
            <h3 className="text-4xl md:text-5xl font-bold">Payment Details</h3>
          </div>
          <div className="bg-white rounded-2xl p-6 flex flex-col gap-4">
            <Input
              name="cardholderName"
              placeholder="Cardholder's Name"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
            <Input
              name="card"
              placeholder="Card Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              format={formatCard}
              maxlength={cardFieldLimit}
            />
            <DatePicker
              showPreviousMonths={false}
              minDate={new Date()}
              maxDate={add(new Date(), { years: 15 })}
              showMonthYearPicker
              monthsShown={1}
              mask={yearAndMonthMask as unknown as string}
              dateFormat="MM/yyyy"
              name="expDate"
              placeholder="Expiration date"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
          </div>
          <div className="flex items-start md:items-center justify-between flex-col md:flex-row gap-2 md:gap-0">
            <h3 className="text-4xl md:text-5xl font-bold">Loyalty Affiliations</h3>
          </div>
          <div className="bg-white rounded-2xl p-6 flex flex-col gap-4">
            <Searchable
              name="providerCode"
              placeholder="Provider"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
              options={Object.keys(hotelCodes)
                .sort()
                .map((key) => ({
                  label: hotelCodes[key as keyof typeof hotelCodes],
                  value: hotelCodes[key as keyof typeof hotelCodes],
                }))}
            />
            <Input
              name="providerAccountValue"
              placeholder="Account Number"
              className="border border-dorrus-button-border rounded-lg w-full p-4"
            />
          </div>

          {bookingError && (
            <p className="text-red-500">
              There was an error while booking your reservation. Check your information and try again.
            </p>
          )}
          <div className="flex justify-end">
            <Button type="submit" loading={isBooking} kind="secondary" form="bookingForm" className="!w-fit">
              Complete Booking
            </Button>
          </div>
        </HookedForm>
      </div>
    </div>
  );
};
