import { withoutNavigationRoutes } from '@app/consts/consts';
import { useUser } from '@app/context/user';
import { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

export const useUserGuard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated, user, fetchingUserData } = useUser();
  const [hideNavigation, setHideNavigation] = useState(false);
  const hasPadding = matchRoutes(
    [{ path: '/login' }, { path: '/registration/details' }, { path: '/registration/phone' }, { path: '/verify-email' }],
    location.pathname,
  );

  useEffect(() => {
    const match = matchRoutes(withoutNavigationRoutes, location.pathname);
    setHideNavigation(Boolean(match));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (!user && !fetchingUserData) {
      navigate('/login');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, fetchingUserData]);

  return { hideNavigation, isAuthenticated, user, fetchingUserData, hasPadding };
};
