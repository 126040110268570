import { RateRadio, TextArea } from '@app/components/Fields';
import { HookedForm } from '@app/components/HookedForm';
import { useBookingReview } from './BookingReview.hooks';
import { getGuestsTextFromRoom, sourceRatingToStars } from '@app/utils/utils';
import { format } from 'date-fns';
import { Button } from '@app/components/Button';
import GreenTickIcon from '@app/assets/icons/green-tick.svg?react';
import { BaseModal } from '@app/components/BaseModal';

export const BookingReview = () => {
  const { bookingDetails, isLoading, user, formHook, feedbackSubmit, navigate, handleChangeDateModal, successModal } =
    useBookingReview();
  const orderLodgingBooking = bookingDetails?.order?.orderItems?.[0]?.lodgingBookings?.[0];
  const orderLodgingRoom = bookingDetails?.order?.orderItems?.[0]?.services?.[0]?.lodgingRooms?.[0];

  if (bookingDetails?.feedback) {
    return (
      <>
        <div className="bg-white rounded-2xl flex p-6 items-center justify-between mb-10">
          <div className="flex gap-4 items-center">
            <GreenTickIcon width={45} height={45} />
            <div>
              <h6 className="font-bold text-2xl">Thank you, {user?.firstName}</h6>
              <h6 className="font-bold text-2xl">It looks like you already left a review for this booking.</h6>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <Button onClick={() => navigate('/my-trips')} kind="secondary" className="!w-[400px]">
            Go to My Trips
          </Button>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="space-y-6">
        <h3 className="text-5xl font-bold">Leave your Review</h3>
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          <HookedForm formHook={formHook} onSubmit={feedbackSubmit} className="space-y-6 col-span-8 xl:col-span-9">
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Cleanliness</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="cleanLines" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Communication</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="communication" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Check-in</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="checkIn" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Accuracy</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="accuracy" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Location</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="location" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Value</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <RateRadio name="value" />
            </div>
            <div className="p-6 bg-white rounded-xl space-y-2">
              <h6 className="text-2xl font-bold">Care to share more?</h6>
              <p className="text-lg text-dorrus-text-secondary">How was your overall experience?</p>
              <TextArea name="note" />
            </div>
            <div className="flex justify-end">
              <Button type="submit" kind="secondary" className="!w-fit">
                Leave a review
              </Button>
            </div>
          </HookedForm>
          <div className="col-span-4 xl:col-span-3">
            <div className="bg-white rounded-2xl p-6 shadow-lg space-y-4">
              <div className="relative overflow-hidden h-32 rounded-2xl">
                <img
                  src="/images/placeholder-hotel.png"
                  alt="room"
                  className="absolute object-cover object-center w-full h-full"
                />
              </div>

              {!isLoading && (
                <div className="flex flex-col gap-4">
                  <div className="flex items-start gap-2 flex-col">
                    <h5 className="text-2xl font-bold capitalize">
                      {orderLodgingBooking?.propertyName?.toLocaleLowerCase()}
                    </h5>
                    <span>{sourceRatingToStars(bookingDetails?.propertyQuality)}</span>
                  </div>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p className="font-bold">Check-in:</p>
                        </td>
                        <td>
                          <p>
                            {orderLodgingBooking?.checkInUTC &&
                              format(orderLodgingBooking?.checkInUTC ?? '', 'eee, MMM dd, yyyy')}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-bold">Check-out:</p>
                        </td>
                        <td>
                          <p>
                            {orderLodgingBooking?.checkOutUTC &&
                              format(orderLodgingBooking?.checkOutUTC ?? '', 'eee, MMM dd, yyyy')}
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p className="font-bold">Booking details:</p>
                        </td>
                        <td>
                          {orderLodgingRoom && (
                            <p>
                              {getGuestsTextFromRoom(orderLodgingRoom)}, {orderLodgingRoom?.roomName}
                            </p>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <BaseModal header="Successful" size="max-w-xl" open={successModal} onClose={handleChangeDateModal}>
        <p>Thank you, your feedback has been sent successfuly.</p>
        <Button onClick={handleChangeDateModal} kind="primary">
          OK
        </Button>
      </BaseModal>
    </>
  );
};
