import { createContext } from 'react';

import type { BookingsContextInterface, State } from './types';

export const BookingsStateContext = createContext<BookingsContextInterface | undefined>(undefined);

export const initialValues = {
  bookings: [],
  pastBookings: [],
  canceledBookings: [],
  currentBooking: undefined,
  fetchingBookings: true,
} as unknown as State;
