import { ComponentProps, ComponentType, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const RadioButton = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  defaultChecked?: boolean;
  id?: string;
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number;
}) => {
  const {
    register,
    getFieldState,
    watch,
    setValue,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);
  const selected = watch(componentProps.name);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(componentProps.name, e.target.value);
  };

  useEffect(() => {
    if (error?.message && document.getElementById('radioInputError') === null) {
      const errorElement = document.createElement('p');
      errorElement.innerHTML = error.message;
      errorElement.classList.add('text-red-500', 'text-sm', 'block');
      errorElement.id = 'radioInputError';
      document?.getElementById('radioInput')?.parentElement?.after(errorElement);
    } else {
      if (document.getElementById('radioInputError')) {
        document.getElementById('radioInputError')?.remove();
      }
    }
  }, [error]);

  return (
    <div className="flex flex-row gap-2 items-center" id="radioInput">
      {componentProps.label && (
        <label
          htmlFor={componentProps.id}
          className={`${selected === String(componentProps.value) ? 'bg-dorrus-background-secondary text-white' : 'bg-transparent text-dorrus-background-secondary'} cursor-pointer relative w-full text-center rounded-lg border border-dorrus-button-border focus:outline-none focus:ring-transparent p-2 `}
        >
          <input
            {...componentProps}
            {...register(componentProps.name, {
              ...(componentProps.value && { value: componentProps.value }),
              ...(componentProps.isRequired && { required: 'This field is required' }),
              ...(componentProps.validation && { pattern: componentProps.validation }),
            })}
            value={componentProps.value}
            onChange={handleChange}
            type="radio"
            className="w-full h-full opacity-0 absolute inset-0 cursor-pointer"
          />
          {componentProps.label}
        </label>
      )}
    </div>
  );
};
