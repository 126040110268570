import Visa from '@app/assets/icons/VISA.svg?react';
import Mastercard from '@app/assets/icons/Mastercard.svg?react';
import GenericCard from '@app/assets/icons/GenericCard.svg?react';
import AMEXCard from '@app/assets/icons/AMEX.svg?react';
import DiscoverCard from '@app/assets/icons/discover.svg?react';
import JCBCard from '@app/assets/icons/jcb.svg?react';
import UATPCard from '@app/assets/icons/UATP.svg?react';
import Star from '@app/assets/icons/star.svg?react';
import {
  HotelImageCategoryInterface,
  HotelResponseInterface,
  HotelRoomInterface,
  HotelRoomPlanInterface,
  HotelRoomSelectionInterface,
  ImageSizes,
  SearchParamsInterface,
  SearchResultsInterface,
} from '@app/models/search';
import { ReactNode } from 'react';
import { parseISO } from 'date-fns';
import creditCardType from 'credit-card-type';
import { find } from 'lodash';
import { RoomCardProps } from '@app/components/RoomCard/RoomCard.interfaces';
import { HotelImageCategories, HotelImageCategoryCode } from '@app/consts/enums';
import { LodgingRoomInterface } from '@app/models/booking';
import { AlertInterface } from '@app/models/general';

export const getFullNameFromFields = ({
  firstName,
  middleName,
  lastName,
}: {
  firstName?: string;
  middleName?: string;
  lastName?: string;
}) => {
  return `${firstName ?? ''} ${middleName ?? ''} ${lastName ?? ''}`.trim();
};

export const getAddressFromFields = (address?: Partial<SearchResultsInterface['propertyAddress']>) => {
  if (!address) return '';
  return [...new Set(Object.values(address))]
    .filter((x) => x)
    .join(', ');
};

export const getNumberOfStars = (code: number) => {
  switch (code) {
    case 2001: {
      return 4;
    }
    case 4: {
      return 5;
    }
    case 5: {
      return 3;
    }
    case 6: {
      return 2;
    }
    default: {
      return 1;
    }
  }
};

export const sourceRatingToStars = (rating: SearchResultsInterface['propertyQuality']) => {
  const codeNumber = Array.isArray(rating)
    ? typeof rating === 'string'
      ? JSON.parse(rating)?.[0]?.Code
      : rating[0]?.Code
    : (rating as unknown as { Code: number })?.Code;
  const DivBase = ({ children }: { children: ReactNode }) => <div className="flex gap-1">{children}</div>;
  const stars = [];
  const totalStars = getNumberOfStars(codeNumber ?? 0);
  for (let i = 0; i < totalStars; i++) {
    stars.push(<Star key={i} />);
  }
  return <DivBase>{stars}</DivBase>;
};

export const getCardTypeLogo = (cardType: string) => {
  switch (cardType.toLocaleLowerCase()) {
    case 'visa':
      return Visa;

    case 'mastercard':
      return Mastercard;

    case 'american express':
      return AMEXCard;

    case 'discover':
      return DiscoverCard;

    case 'jcb':
      return JCBCard;

    case 'uatp':
      return UATPCard;
    default:
      return GenericCard;
  }
};

const amexReplacer = () => (_regex: string, $1: string, $2: string, $3: string) =>
  [$1, $2, $3].filter((group) => !!group).join(' ');
const defaultReplacer = () => (_regex: string, $1: string, $2: string, $3: string, $4: string) =>
  [$1, $2, $3, $4].filter((group) => !!group).join(' ');

export const formatCard = (value: string) => {
  const cardsOptions = creditCardType(value);

  if (cardsOptions && cardsOptions.length > 0) {
    const cardType = cardsOptions[0].type;
    const isAmex = cardType === 'american-express';
    const isUATP = cardType === 'uatp';
    let regex;
    if (isAmex) {
      regex = /^(\d{0,4})(\d{0,6})(\d{0,5})$/g;
    } else if (isUATP) {
      regex = /^(\d{0,4})(\d{0,5})(\d{0,6})$/g;
    } else {
      regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
    }
    const onlyNumbers = value.replace(/[^\d]/g, '');

    return onlyNumbers.replace(regex, isAmex || isUATP ? amexReplacer() : defaultReplacer());
  }

  return value;
};

export const getCurrentYear = () => new Date().getFullYear();

export const generateYearsFromYear = (year: number, maxYear?: number) => {
  const limitYear = maxYear ?? new Date().getFullYear();
  const years = [];

  for (let i = year; i <= limitYear; i++) {
    years.push(i);
  }
  return years;
};

export const generateYearsFromNow = () => {
  const nowYear = new Date().getFullYear();
  const endDate = new Date().getFullYear() + 2;
  const years = [];

  for (let i = nowYear; i <= endDate; i++) {
    years.push(i);
  }
  return years;
};

export const getTotalOfNights = (start?: string, end?: string) => {
  if (!start || !end) return 0;
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());
  const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
  return diffDays;
};

export const localStorageHandler = {
  set: (key: string, value: string) => {
    localStorage.setItem(key, value);
    window.dispatchEvent(new Event('storage'));
  },
  get: (key: string) => {
    if (!key) return;
    const result = localStorage.getItem(key);
    if (!result) return undefined;
    return result;
  },
};

export const preventDefault = (e: React.MouseEvent, callback?: () => void) => {
  e.preventDefault();
  if (callback) callback();
};

export const getUserBookingSelection = (
  currentBooking: Partial<HotelRoomPlanInterface & RoomCardProps>,
  currentRate: HotelResponseInterface,
): HotelRoomSelectionInterface | undefined => {
  const roomInfo = find(currentRate?.rateResults?.roomsAvail, (o) =>
    o?.ratesAvail?.some((e: HotelRoomPlanInterface) => e?.productCode === currentBooking?.productCode),
  ) as HotelRoomInterface;
  if (roomInfo) {
    const hotelDetails = {
      chainCode: currentRate?.rateResults?.chainCode,
      chainName: currentRate?.rateResults?.chainName,
      hotelLogo: currentRate?.rateResults?.hotelLogo,
      hotelName: currentRate?.rateResults?.hotelName,
      rating: currentRate?.rateResults?.rating,
      propertyQuality: currentRate?.rateResults?.propertyQuality,
      room: { ...roomInfo, ...roomInfo?.ratesAvail?.find((e) => e.productCode === currentBooking?.productCode) },
    } as HotelRoomSelectionInterface;

    return hotelDetails;
  }
  return undefined;
};

export const getHotelImageFromCategory = (
  hotelImages?: HotelImageCategoryInterface[],
  category?: string,
  size?: ImageSizes,
) => {
  if (!hotelImages || !category) return { total: 0, images: [] };

  const categoryCode = HotelImageCategoryCode.get(HotelImageCategories.EXTERIOR);
  const images = hotelImages
    .filter((imageObject) => imageObject?.Category.CategoryCode === categoryCode)
    .flatMap((image) => image?.ImageItems?.Image);
  if (images.length === 0 && hotelImages.length > 0) {
    return { total: hotelImages.length, images: hotelImages[0]?.ImageItems?.Image ?? [] };
  }
  return { total: images?.length ?? 0, images: (size ? images.filter((image) => image.Type === size) : images) ?? [] };
};

export const getInOutDatesFromRate = (
  rate?: Partial<HotelRoomInterface & HotelRoomPlanInterface & HotelRoomSelectionInterface>,
) => {
  if (!rate) return { inDate: '', outDate: '' };
  if (rate?.nightlyRateDetails && rate?.nightlyRateDetails.length > 0) {
    return {
      inDate: rate?.nightlyRateDetails[0].rateStartDate,
      outDate: rate?.nightlyRateDetails[rate?.nightlyRateDetails.length - 1].rateEndDate,
    };
  }
  if (rate?.room?.inDate && rate?.room?.outDate) {
    return { inDate: rate?.room?.inDate, outDate: rate?.room?.outDate };
  }
  if (rate?.inDate && rate?.outDate) {
    return { inDate: rate?.inDate, outDate: rate?.outDate };
  }
};

export const getPolicies = (policiesObject: { text?: { type?: string; value?: string } }[], key?: string) => {
  if (!policiesObject) return '';

  return policiesObject
    .filter((policy) => !key || policy.text?.type === key)
    .map((policy) => policy.text?.value)
    .join(' ')
    .split(/\r?\n|\r|\n/g)
    .filter((text) => !text?.toLocaleLowerCase().includes('commission'))
    .filter((text) => !text?.toLocaleLowerCase().includes('pct'))
    .join('\n');
};

export const getGuestsText = (params?: Partial<SearchParamsInterface>) => {
  if (!params) return '';
  if (params?.adults && !params?.children) {
    return params?.adults === 1 ? '1 Adult' : `${params?.adults} Adults`;
  }
  if (params?.adults && params?.children) {
    return params?.adults === 1 && params?.children === 1
      ? '1 Adult, 1 Child'
      : `${params?.adults} Adults, ${params?.children} Children`;
  }
};

export const getGuestsTextFromRoom = (room?: Partial<LodgingRoomInterface>) => {
  if (!room) return '';
  const adults = Number(room?.qtyAdults);
  const children = Number(room?.qtyChildren);

  if (adults && !children) {
    return adults === 1 ? '1 Adult' : `${adults} Adults`;
  }
  if (adults && children) {
    return adults === 1 && children === 1 ? '1 Adult, 1 Child' : `${adults} Adults, ${children} Children`;
  }
};

export const getRatingText = (rating?: number) => {
  const ratingNumber = Math.round(Number(rating ?? 0));
  switch (ratingNumber) {
    case 1:
    case 2:
      return 'Pleasant';
    case 3:
      return 'Good';
    case 4:
      return 'Very Good';
    case 5:
      return 'Excellent';
    default:
      return 'Unknown';
  }
};

export const getAlertStyleBySeverity = (severity: number) => {
  switch (severity) {
    case 1:
      return { background: '#dbdbdb', color: '#000000' };
    case 2:
      return { background: '#ffe7a3', color: '#000000' };
    case 3:
      return { background: '#d51517', color: '#FFFFFF' };
    default:
      return { background: '#dbdbdb', color: '#000000' };
  }
};

export const cleanUpTopic = (string?: string | null) => {
  const newString = string?.toLowerCase().replace(/[^A-Z]+/gi, '-') ?? String(`topic-${Math.random() * 300}`);
  if (newString.startsWith('-')) return newString.slice(1);
  return newString;
};

export const getAlertDisplay = (alert: AlertInterface, user?: boolean, alertsLocal?: { alertId: number }[]) => {
  if (alertsLocal && alertsLocal?.length > 0) {
    if (alertsLocal.some((a: { alertId: number }) => a.alertId === alert.alertID)) {
      return 'none';
    }
  }
  if (alert.isInternal && alert.isExternal) {
    return 'flex';
  }
  if (alert.isExternal && !user) {
    return 'flex';
  } else if (alert.isExternal && user) {
    return 'none';
  }

  if (alert.isInternal && user) {
    return 'flex';
  } else if (alert.isInternal && !user) {
    return 'none';
  }

  return 'none';
};

export const LoyaltyTransactionsStatusMap = new Map([
  [0, 'COMPLETED'],
  [1, 'PENDING'],
  [2, 'SUSPENDED'],
  [3, 'VOIDED'],
]);
