import { AutoCompletePlaceInterface } from '@app/models/places';
import { useEffect, useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { useSearch } from '@app/context/search';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { formatISO } from 'date-fns';
import { searchValidation } from '@app/utils/validations';
import { getAllowedParams } from '@app/context/search/utils';

export const useBanner = (clearFields?: boolean, refetch?: () => void) => {
  const placesLibrary = useMapsLibrary('places');
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [autocompleteService, setAutocompleteService] = useState<google.maps.places.AutocompleteService | null>(null);
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [placesPredictions, setPlacesPredictions] = useState<google.maps.places.AutocompletePrediction[]>();
  const [searchParams] = useSearchParams();
  const { searchDispatch, searchParams: searchParamsContext } = useSearch();
  const [totalGuests, setTotalGuests] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState<AutoCompletePlaceInterface>();
  const [showDropdown, setShowDropdown] = useState(false);
  const [recentSearches, setRecentSearches] = useState<{ label: string; value: string }[]>([]);
  const navigate = useNavigate();

  const formHook = useForm({
    defaultValues: {
      adults: clearFields ? '1' : String(searchParams.get('adults') ?? searchParamsContext?.adults ?? 1),
      children: clearFields ? '0' : String(searchParams.get('children') ?? searchParamsContext?.children ?? 0),
      dateIn: clearFields
        ? undefined
        : new Date(searchParams.get('inDate') ?? searchParamsContext?.inDate ?? Date.now()).toISOString() ?? '',
      dateOut: clearFields
        ? undefined
        : new Date(searchParams.get('outDate') ?? searchParamsContext?.outDate ?? Date.now()).toISOString() ?? '',
      location: clearFields ? undefined : searchParams.get('location') ?? searchParamsContext?.location ?? '',
      childAges: searchParamsContext?.ChildAges ?? undefined,
      locationGeometry: {
        lat: clearFields
          ? undefined
          : searchParams.get('searchLatitude')
            ? Number(searchParams.get('searchLatitude'))
            : searchParamsContext?.searchLatitude ?? undefined,
        lng: clearFields
          ? undefined
          : searchParams.get('searchLatitude')
            ? Number(searchParams.get('searchLongitude'))
            : searchParamsContext?.searchLongitude ?? undefined,
      },
    },
    resolver: searchValidation,
  });

  const dateInValue = formHook.watch('dateIn');
  const adultGuests = formHook.watch('adults');
  const childGuests = formHook.watch('children');
  const childAges = formHook.watch('childAges');

  useEffect(() => {
    if (dateInValue) {
      document.getElementById('dateOut')?.click();
    }
  }, [dateInValue]);

  useEffect(() => {
    if (!placesLibrary) return;

    setAutocompleteService(new placesLibrary.AutocompleteService());
    setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));
    setSessionToken(new placesLibrary.AutocompleteSessionToken());

    return () => setAutocompleteService(null);
  }, [placesLibrary]);

  const getTotalGuests = () => {
    const adults = adultGuests ?? 0;
    const children = childGuests ?? 0;

    setTotalGuests(Number(adults) + Number(children));
  };

  const getPlaces = async (values: FieldValues) => {
    const params = {
      adults: Number(values?.adults),
      children: Number(values?.children ?? 0),
      searchLatitude: Number(values?.locationGeometry?.lat),
      searchLongitude: Number(values?.locationGeometry?.lng),
      inDate: values?.dateIn ? formatISO(values.dateIn, { representation: 'date' }) : '',
      outDate: values?.dateOut ? formatISO(values.dateOut, { representation: 'date' }) : '',
      location: values?.location ?? '',
      placeID: selectedLocation?.place_id ?? '',
      ...(Number(childGuests) > 0 && { ChildAges: values.childAges }),
    };

    searchDispatch({
      type: 'updateSearchParams',
      clearFilter: true,
      updateSearchParams: {
        ...params,
      },
    });

    return params;
  };

  const handleFormSubmit = async (values: FieldValues) => {
    const params = await getPlaces(values);

    if (refetch) {
      refetch();
    } else {
      navigate(`/results?${getAllowedParams({ ...params, location: values.location }, true, true)}`);
    }
  };

  const handleAdd = (field: 'adults' | 'children', limit?: number) => {
    const value = formHook.getValues(field);

    if (limit && Number(value) < limit) {
      formHook.setValue(field, String(Number(value) + 1));
      if (field === 'children') {
        formHook.setValue(
          'childAges',
          Array.from({ length: Number(value ?? 0) + 1 }).map(() => 0),
        );
      }
      return;
    }
    formHook.setValue(field, String(Number(value) + 1));
    if (field === 'children' && childGuests && Number(childGuests) === 0) {
      formHook.setValue(
        'childAges',
        Array.from({ length: Number(value ?? 0) + 1 }).map(() => 1),
      );
    } else {
      formHook.setValue('childAges', childAges ? [...childAges, 1] : [1]);
    }
    return;
  };

  const handleRemove = (field: 'adults' | 'children') => {
    const value = formHook.getValues(field);

    if (Number(value) > 0) {
      formHook.setValue(field, String(Number(value) - 1));
      if (field === 'children') {
        formHook.setValue('childAges', childAges?.slice(0, -1));
      }
      return;
    }
  };

  const handleRemoveChildAge = (index: number) => {
    const value = formHook.getValues(`childAges.${index}`);

    if (Number(value) > 0) {
      formHook.setValue(`childAges.${index}`, Number(value) - 1);
      return;
    }
  };

  const handleAddChildAge = (index: number, limit?: 18) => {
    const value = formHook.getValues(`childAges.${index}`);

    if (limit && Number(value) < limit) {
      formHook.setValue(`childAges.${index}`, Number(value) + 1);
      return;
    }
    formHook.setValue(`childAges.${index}`, Number(value) + 1);
    return;
  };

  const clearLocation = () => {
    searchDispatch({
      type: 'updateSearchParams',
      updateSearchParams: {
        ...searchParamsContext,
        searchLatitude: undefined,
        searchLongitude: undefined,
        location: '',
      },
    });
    formHook.setValue('location', '');
    formHook.setValue('locationGeometry', { lat: 0, lng: 0 });
  };

  const getPlacesDetails = (placeId: string, description: string) => {
    placesService?.getDetails({ placeId: placeId }, (place) => {
      formHook.setValue('location', description);
      formHook.setValue('placeID', placeId);
      formHook.setValue('locationGeometry', place?.geometry?.location?.toJSON() as { lat: number; lng: number });
      setShowDropdown(false);
    });
  };

  useEffect(() => {
    getTotalGuests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adultGuests, childGuests]);

  const location = formHook.watch('location');

  useEffect(() => {
    if (location) {
      autocompleteService
        ?.getPlacePredictions({ input: location, sessionToken })
        ?.then((results) => setPlacesPredictions(results?.predictions))
        ?.catch((e) => console.error(e));
    } else {
      setSelectedLocation(undefined);
      setPlacesPredictions([]);
    }

    if (selectedLocation && selectedLocation.place_id) {
      getPlacesDetails(selectedLocation.place_id, selectedLocation?.description);
    }

    if (!selectedLocation?.place_id && !location) {
      formHook.setValue('location', '');
      formHook.setValue('locationGeometry', { lat: 0, lng: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, selectedLocation]);

  useEffect(() => {
    const localRecentSearches = localStorage.getItem('dSearchSuggestions');
    if (localRecentSearches) {
      const parsedRecentSearches = JSON.parse(localRecentSearches) as { location: string; placeID: string }[];
      setRecentSearches(parsedRecentSearches.map((search) => ({ label: search.location, value: search.placeID })));
    }
  }, []);

  return {
    title: 'Welcome to Dorrus',
    description:
      'Dorrus is a platform that allows you to manage your tasks and projects in a simple and efficient way.',
    formHook,
    handleAdd,
    handleRemove,
    handleRemoveChildAge,
    handleAddChildAge,
    totalGuests,
    adultGuests,
    childGuests,

    handleFormSubmit,
    setSelectedLocation,
    showDropdown,
    placesPredictions,
    setShowDropdown,
    isPlacePredictionsLoading: false,
    clearLocation,
    recentSearches,
  };
};
