import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';

export const getAlerts = async () => {
  return apiInstance.get(ApiRoutes.ALERTS);
};

export const postContactUs = async (data: {
  sender: { contactId?: string; name: string; handle: string };
  subject?: string;
  body: string;
  metadata: { headers: { orderId?: string; phoneNumber?: string } };
}) => {
  return apiInstance.post(
    '/contact/send',
    { ...data, body_format: 'html' }
  );
};
