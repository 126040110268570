import ComparisonCheck from '@app/assets/icons/comparison-check.svg?react';
import ComparisonNot from '@app/assets/icons/comparison-not.svg?react';
import { ReactNode } from 'react';

export const BenefitsTable = ({
  highlightColumn,
  header,
  tableBody,
}: {
  highlightColumn?: boolean;
  header: ReactNode[];
  tableBody: { feature: string; includes?: (string | boolean)[] }[];
}) => {
  return (
    <table
      cellSpacing={0}
      cellPadding={0}
      className="border-collapse !border-hidden block overflow-x-auto whitespace-nowrap z-50 relative"
    >
      <thead>
        <tr>
          {header.map((item, index) => (
            <th key={index}>
              <div className="w-full flex justify-center p-4">{item}</div>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableBody?.map((item, index) => (
          <tr className="group" key={index}>
            <td>
              <span className="font-bold ">{item.feature}</span>
            </td>
            {item.includes &&
              item?.includes?.map((value, subIndex) => (
                <td className="lg:w-40 xl:w-64" key={subIndex}>
                  <div
                    className={`group-last-of-type:rounded-b-xl w-full flex justify-center p-4 ${highlightColumn && subIndex === 0 ? 'bg-black' : ''}`}
                  >
                    {value ? typeof value === 'string' ? <span>{value}</span> : <ComparisonCheck /> : <ComparisonNot />}
                  </div>
                </td>
              ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
