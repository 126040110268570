import { Controller, useFormContext } from 'react-hook-form';

import PhoneInput from 'react-phone-number-input';

export const Phone = ({
  name,
  label,
  placeholder,
  disabled = false,
  defaultValue,
}: {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  isRequired?: boolean;
  disabled?: boolean;
  defaultValue?: string;
}) => {
  const {
    getFieldState,
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(name);

  return (
    <div>
      {label && (
        <label htmlFor={name} className="font-bold">
          {label}
        </label>
      )}

      <Controller
        name={name}
        control={control}
        {...(defaultValue && { defaultValue })}
        render={({ field: { onChange, onBlur, value } }) => (
          <PhoneInput
            disabled={disabled}
            className="border border-dorrus-button-border rounded-lg w-full px-4 py-2 focus:outline-none focus:ring-0 disabled:bg-gray-200/50 disabled:border-dorrus-text-secondary disabled:text-dorrus-text-secondary"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            defaultCountry="US"
            placeholder={placeholder ?? 'Enter phone number'}
            numberInputProps={{ className: '!border-0 !outline-0 !ring-0' }}
          />
        )}
      />

      <p className="font-semibold text-red-600">{error?.message}</p>
    </div>
  );
};
