import { useSearch } from '@app/context/search';
import { useUser } from '@app/context/user';
import { HotelRoomPlanInterface } from '@app/models/search';
import { getGuestsText } from '@app/utils/utils';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

export const useRoomCard = () => {
  const { searchParams } = useSearch();
  const { getPriceFormat } = useUser();
  const [moreDetailsModal, setMoreDetailsModal] = useState<HotelRoomPlanInterface | undefined>();
  const formHook = useForm();

  const handleRoomDetails = (room?: HotelRoomPlanInterface) => {
    if (room) {
      setMoreDetailsModal(room);
    } else {
      setMoreDetailsModal(undefined);
    }
  };

  return {
    formHook,
    searchParams,
    numberOfNights: 8,
    guests: getGuestsText(searchParams),
    getPriceFormat,
    handleRoomDetails,
    moreDetailsModal,
  };
};
