import { withoutNavigationRoutes } from '@app/consts/consts';
import { useUser } from '@app/context/user';
import { useEffect, useState } from 'react';
import { matchRoutes, useLocation, useNavigate } from 'react-router-dom';

export const useNonAuthGuard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated, user, fetchingUserData } = useUser();
  const [hideNavigation, setHideNavigation] = useState(false);
  const hasPadding = matchRoutes(
    [
      { path: '/login' },
      { path: '/registration/details' },
      { path: '/registration/phone' },
      { path: '/verify-email' },
      { path: '/forgot-password' },
    ],
    location.pathname,
  );
  const shouldSkipToDashboard = matchRoutes([{ path: '/login' }, { path: '/registration/details' }], location.pathname);

  useEffect(() => {
    const match = matchRoutes(withoutNavigationRoutes, location.pathname);
    setHideNavigation(Boolean(match));

    if (shouldSkipToDashboard && user && !fetchingUserData) {
      navigate('/');
    }

    if (user && !user.emailVerified) {
      navigate('/login');
    }

    if (user && !user?.firstName && !user?.lastName && !fetchingUserData) {
      navigate('/registration/details');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  return { hideNavigation, isAuthenticated, user, hasPadding };
};
