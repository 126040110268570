/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { HookedForm } from '@app/components/HookedForm/HookedForm';
import { useRegistration } from './Registration.hooks';
import { Checkbox, Input } from '@app/components/Fields';
import { Button } from '@app/components/Button';
import { FormPhoneInitialState } from './Registration.interfaces';
import { Phone } from '@app/components/Fields/Phone';
import { Link, useSearchParams } from 'react-router-dom';

export const RegistrationPhone = () => {
  const [searchParams] = useSearchParams();
  const { formHookPhone, handleRegistrationPhone, gettingOTP, showOtp, changeNumber } = useRegistration();
  const phoneNumber = formHookPhone.watch('phoneNumber');
  const otp = formHookPhone.watch('otp');
  const skipPhone = formHookPhone.watch('skipPhone');
  const isUpdatingPhone = searchParams.get('updating');

  const getButtonLabel = () => {
    if (skipPhone) {
      return 'Confirm';
    }
    if (phoneNumber && !showOtp && !skipPhone) {
      return 'Send OTP';
    }
    if (phoneNumber && showOtp && otp && !skipPhone) {
      return 'Confirm';
    }

    return 'Confirm';
  };
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="rounded-3xl md:bg-white md:w-[670px]  p-4 md:p-10 space-y-6">
        <div className="max-w-[70%] mx-auto space-y-6">
          <h1 className="text-5xl text-dorrus-text-primary font-bold mb-2 text-center">
            {isUpdatingPhone
              ? 'Add your phone number to unlock the best of Dorrus'
              : 'Join to unlock the best of Dorrus.'}
          </h1>
          {!isUpdatingPhone && <p className="text-center text-lg uppercase">Step 3/3</p>}
          <p className="text-lg text-dorrus-text-primary text-center">
            Once verified, your number will secure your account and unlock redemption options.
          </p>
        </div>
        <div className="space-y-4 flex items-center flex-col justify-center md:w-2/3 mx-auto">
          <HookedForm<FormPhoneInitialState>
            formHook={formHookPhone}
            onSubmit={handleRegistrationPhone}
            className="w-full space-y-4"
          >
            <div>
              <Phone
                disabled={showOtp || Boolean(skipPhone)}
                placeholder="Enter cell phone number"
                name="phoneNumber"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
              {showOtp && (
                <p
                  className={`font-bold text-xs text-right ${!skipPhone ? 'cursor-pointer' : 'cursor-not-allowed'}`}
                  onClick={() => {
                    if (!skipPhone) {
                      changeNumber();
                    }
                  }}
                >
                  Wrong number?
                </p>
              )}
            </div>

            {showOtp && (
              <Input
                placeholder="Enter the received OTP"
                name="otp"
                className="border border-dorrus-button-border rounded-lg w-full p-4"
              />
            )}

            {gettingOTP && <p>Sending OTP code</p>}

            <Checkbox name="skipPhone" label={`I don't have a cell phone number.`} value="true" />

            <Button
              type="submit"
              disabled={(!skipPhone && showOtp && phoneNumber && !otp) || (!phoneNumber && !showOtp && !skipPhone)}
              kind="secondary"
            >
              {getButtonLabel()}
            </Button>
            <small className="text-dorrus-text-secondary text-justify block">
              By providing your mobile number, you consent to receive transactional messages from Dorrus. Message and
              data rates may apply. Your information will be handled according to our{' '}
              <Link to="/legal/privacy-policy" className="underline">
                Privacy Policy
              </Link>
              .
            </small>
          </HookedForm>
        </div>
      </div>
    </div>
  );
};
