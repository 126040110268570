import { ComponentProps, ComponentType, SyntheticEvent } from 'react';
import { useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const Checkbox = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  placeholder?: string;
  name: string;
  isRequired?: boolean;
  type?: string;
  className?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string;
  disabled?: boolean;
  hidden?: boolean;
  onFocus?: (e: SyntheticEvent) => void;
  onBlur?: (e: SyntheticEvent) => void;
  format?: (value: string) => string;
}) => {
  const {
    register,
    getFieldState,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex gap-2 items-center">
      <input
        className="focus:outline-none focus:ring-0 !accent-dorrus-background-secondary text-dorrus-background-secondary"
        {...(componentProps.onFocus && { onFocus: componentProps.onFocus })}
        {...componentProps}
        {...register(componentProps.name, {
          ...(componentProps.value && { value: componentProps.value }),
          ...(componentProps.isRequired && { required: 'This field is required' }),
          ...(componentProps.validation && { pattern: componentProps.validation }),
          ...(componentProps.onBlur && { onBlur: componentProps.onBlur }),
          ...(componentProps.format && { onChange: (e) => (e.target.value = componentProps.format!(e.target.value)) }),
        })}
        type="checkbox"
      />
      {componentProps.label && <label htmlFor={componentProps.name}>{componentProps.label}</label>}
      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
