import { BookingProvider } from '@app/context/booking';
import { SearchProvider } from '@app/context/search';
import { TravellersProvider } from '@app/context/travellers';
import { UserProvider } from '@app/context/user';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ModalProvider } from 'mui-modal-provider';
import { ReactNode } from 'react';

const queryClient = new QueryClient();

export const Providers = ({ children }: { children: ReactNode }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalProvider>
        <UserProvider>
          <SearchProvider>
            <BookingProvider>
              <TravellersProvider>{children}</TravellersProvider>
            </BookingProvider>
          </SearchProvider>
        </UserProvider>
      </ModalProvider>
    </QueryClientProvider>
  );
};
