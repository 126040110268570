import { useUser } from '@app/context/user';
import { LoyaltyTransaction, RedemptionDetailsInterface } from '@app/models/loyalty';
import { getLoyaltyTransactions, getRedemptionDetails } from '@app/services/loyalty';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useLoyalty = () => {
  const navigate = useNavigate();
  const { user, getPriceFormat, loyaltyLevels } = useUser();
  const [userRedemptions, setUserRedemptions] = useState<RedemptionDetailsInterface[]>([]);
  const [loyaltyTransactions, setLoyaltyTransactions] = useState<LoyaltyTransaction[]>([]);
  const userLevel = loyaltyLevels?.find((level) => user?.programLevelCode === level.levelCode);
  const userNextLevel = loyaltyLevels.findIndex((level) => user?.programLevelCode === level.levelCode) + 1;

  const getUserRedemptions = () => {
    getRedemptionDetails()
      .then((res) => setUserRedemptions(res?.data?.details))
      .catch((e) => console.error(e));
  };

  const getUserTransactions = () => {
    getLoyaltyTransactions()
      .then((res) => setLoyaltyTransactions(res?.data?.memberViewTrxDetailsRS))
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (user) {
      getUserRedemptions();
      getUserTransactions();
    }
  }, [user]);

  return {
    navigate,
    user,
    userRedemptions,
    loyaltyTransactions,
    getPriceFormat,
    loyaltyLevels,
    userLevel,
    userNextLevel: loyaltyLevels[userNextLevel],
  };
};
