import { Banner } from '@app/components/Banner';
import { CallToAction } from '@app/components/CallToAction';
import { CategoriesCarousel } from '@app/components/CategoriesCarousel';
import { CarouselSingleItemProps } from '@app/components/CategoriesCarousel/CategoriesCarousel';
import { RegisterNowBanner } from '@app/components/RegisterNowBanner';
import { useSearch } from '@app/context/search';
import { getAllowedParams } from '@app/context/search/utils';
import { useUser } from '@app/context/user';
import { useMapsLibrary } from '@vis.gl/react-google-maps';
import { addDays, format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PointsCalculator } from '@app/components/PointsCalculator/PointsCalculator';
import { WorldMap } from '@app/components/WorldMap/WorldMap';
import { CTAElements } from '@app/components/CTAElements/CTAElements';
import { ComparisonTable } from '@app/components/ComparisonTable/ComparisonTable';
import ArrowsHeadline from '@app/assets/icons/arrows-headline.svg?react';
import HotelIcon from '@app/assets/icons/hotel.svg?react';
import LuggageIcon from '@app/assets/icons/lugagge.svg?react';
import LocationsIcon from '@app/assets/icons/locations.svg?react';
import ComparisonBackground from '@app/assets/comparison-background.svg?react';

export const Dashboard = () => {
  const placesLibrary = useMapsLibrary('places');
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const { user } = useUser();
  const { searchDispatch } = useSearch();
  const navigate = useNavigate();

  const suggestedCitiesClick = (item?: CarouselSingleItemProps) => {
    if (item) {
      placesService?.getDetails({ placeId: item.id as string }, (place) => {
        searchDispatch({
          type: 'updateSearchParams',
          updateSearchParams: {
            adults: 1,
            children: 0,
            placeID: item.id as string,
            inDate: format(addDays(new Date(), 2), 'yyyy-MM-dd'),
            outDate: format(addDays(new Date(), 4), 'yyyy-MM-dd'),
            location: place?.formatted_address ?? item.label,
            searchLatitude: place?.geometry?.location?.lat(),
            searchLongitude: place?.geometry?.location?.lng(),
          },
        });

        navigate(
          `/results?${getAllowedParams(
            {
              adults: 1,
              children: 0,
              placeID: item.id as string,
              inDate: '',
              outDate: '',
              location: place?.formatted_address ?? item.label,
              searchLatitude: place?.geometry?.location?.lat(),
              searchLongitude: place?.geometry?.location?.lng(),
            },
            true,
            true,
          )}`,
        );
      });
    }
  };

  useEffect(() => {
    if (!placesLibrary) return;

    setPlacesService(new placesLibrary.PlacesService(document.createElement('div')));

    return () => setPlacesService(null);
  }, [placesLibrary]);

  return (
    <>
      <div className="pb-10">
        <Banner clearFields />
        <div className="container mx-auto py-16">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-y-10 md:gap-6">
            <div className="flex items-center justify-center flex-col text-center gap-2">
              <div>
                <HotelIcon />
              </div>
              <p className="font-medium text-2xl">Up to 80,000 Bonus points</p>
            </div>
            <div className="flex items-center justify-center flex-col text-center gap-2">
              <div>
                <LocationsIcon />
              </div>
              <p className="font-medium text-2xl">
                60,000+ Hotels Around the
                <br /> World
              </p>
            </div>
            <div className="flex items-center justify-center flex-col text-center gap-2">
              <div>
                <LuggageIcon />
              </div>
              <p className="font-medium text-2xl">
                Register for Exclusive
                <br /> Offers & Discounts
              </p>
            </div>
          </div>
        </div>

        {!user && (
          <div className="my-10">
            <RegisterNowBanner />
          </div>
        )}

        <div className="my-10">
          <CallToAction
            title="Win a $100 Delta Air Lines Gift Card!"
            image="/images/delta-promo.png"
            text={
              <p>
                Register and book to earn entries* for a chance to win in our Delta Air Lines™ Gift Card giveaway!
                <br />
                Hurry! Enter by Nov. 30th, 2024!
              </p>
            }
            actionText="Register"
            action={() => {
              navigate('/promoRegistration?promoCode=DORDALGC2409');
            }}
            actionLink="/promoTC?promoCode=DORDALGC2409"
            actionLinkText="Contest Rules"
          />
        </div>
        <div className="my-16">
          <PointsCalculator />
        </div>
        <div className="my-10 relative">
          <CategoriesCarousel
            title="Recent searches"
            handleChoice={suggestedCitiesClick}
            countries={[
              { id: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ', image: '/images/france.jpeg', name: 'Paris' },
              { id: 'ChIJKcEGZna4lxIRwOzSAv', image: '/images/spain.jpeg', name: 'Mallorca' },
              { id: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM', image: '/images/australia.jpg', name: 'Sydney' },
              { id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI', image: '/images/uk.jpeg', name: 'London' },
              { id: 'ChIJOwg_06VPwokRYv534QaPC8g', image: '/images/us.jpeg', name: 'New York' },
              { id: 'ChIJw0rXGxGKJRMRAIE4sppPCQM', image: '/images/italy.jpeg', name: 'Rome' },
              { id: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ', image: '/images/germany.jpeg', name: 'Berlin' },
            ]}
          />
        </div>
        <div className="my-10 relative">
          <CategoriesCarousel
            title="Suggested Cities"
            handleChoice={suggestedCitiesClick}
            countries={[
              { id: 'ChIJdd4hrwug2EcRmSrV3Vo6llI', image: '/images/uk.jpeg', name: 'London' },
              { id: 'ChIJOwg_06VPwokRYv534QaPC8g', image: '/images/us.jpeg', name: 'New York' },
              { id: 'ChIJw0rXGxGKJRMRAIE4sppPCQM', image: '/images/italy.jpeg', name: 'Rome' },
              { id: 'ChIJAVkDPzdOqEcRcDteW0YgIQQ', image: '/images/germany.jpeg', name: 'Berlin' },
              { id: 'ChIJD7fiBh9u5kcRYJSMaMOCCwQ', image: '/images/france.jpeg', name: 'Paris' },
              { id: 'ChIJKcEGZna4lxIRwOzSAv', image: '/images/spain.jpeg', name: 'Mallorca' },
              { id: 'ChIJP3Sa8ziYEmsRUKgyFmh9AQM', image: '/images/australia.jpg', name: 'Sydney' },
            ]}
          />
        </div>
      </div>
      <WorldMap />
      <div className="my-10">
        <CTAElements
          action={() => (user ? navigate('/loyalty') : navigate('/dorrus-beyond'))}
          content={
            <div className="flex justify-center items-center h-full w-full p-6">
              <ul className="text-white space-y-6 w-full relative flex flex-col">
                <li className="flex items-center gap-2">
                  <div className="h-10 w-10 md:h-14 md:w-14 flex-shrink-0">
                    <img src="/images/ellipse-1.png" />
                  </div>{' '}
                  <p className="text-lg md:text-2xl">$400 eligible stay</p>
                </li>

                <li className="flex items-center gap-2 ml-[10%]">
                  <ArrowsHeadline className="w-16 h-16 md:w-20 md:h-20 top-5 md:top-10 lg:ml-[5%]" />
                  <div className="hidden md:inline-block h-10 w-10 md:h-14 md:w-14 flex-shrink-0">
                    <img src="/images/ellipse-2.png" />
                  </div>{' '}
                  <p className="text-lg md:text-2xl">Up to 3,600 Beyond points</p>
                </li>

                <li className="flex flex-shrink-0 items-center gap-2 ml-[20%] lg:ml-[30%]">
                  <ArrowsHeadline className="w-16 h-16 md:w-20 md:h-20 flex-shrink-0" />
                  <div className="hidden md:inline-block h-10 w-10 md:h-14 md:w-14 relative flex-shrink-0">
                    <img src="/images/ellipse-3.png" className="w-full h-full object-cover" />
                  </div>
                  <p className="text-lg md:text-2xl">Convert to gift cards or transfer to our partners</p>
                </li>
              </ul>
            </div>
          }
        />
      </div>
      <div className="my-10">
        <div className="bg-white rounded-lg p-6 relative overflow-hidden flex items-center justify-center flex-col">
          <ComparisonBackground className="absolute inset-0 w-full z-10" />
          <h3 className="text-4xl md:text-5xl font-bold my-10">Why Dorrus Stands Out</h3>
          <div className="w-full flex justify-center">
            <ComparisonTable highlightColumn />
          </div>
        </div>
      </div>
    </>
  );
};
