import { IInfiniteScroller } from '@app/context/search/utils';
import { Loading } from '../Loading/Loading';

interface QueryLoaderInterface {
  scroller?: IInfiniteScroller;
}

export const QueryLoader = ({ scroller }: QueryLoaderInterface) => {
  if (!scroller) return null;

  return (
    <div ref={scroller.scrollerRef} className="flex justify-center items-center py-10">
      <Loading width={40} height={40} />
    </div>
  );
};
