import { ApiRoutes } from '@app/consts/enums';
import { apiInstance } from '@app/libs/api';
export interface FeedbackInterface {
  hotelId?: string;
  id?: number;
  cleanLines: number;
  communication: number;
  checkIn: number;
  accuracy: number;
  location: number;
  value: number;
  note?: string;
}

export const postFeedback = async (id: string, data: FeedbackInterface) => {
  return apiInstance.post(`${ApiRoutes.FEEDBACK}/${id}`, data);
};

export const getFeedbackByHotelId = async (hotelId: string, order?: { label: string; value: string }) => {
  return apiInstance.get(`${ApiRoutes.FEEDBACK}/hotel/${hotelId}${order ? `?orderBy=${order.value}` : ''}`);
};
