import { ComponentProps, ComponentType, ReactNode, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { Range as RangeSlider } from 'react-range';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const RawRange = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  placeholder?: string;
  name: string;
  type?: string;
  className?: string;
  triggerClassname?: string;
  label?: string;
  value?: string | number;
  icon?: ReactNode | JSX.Element;
  min?: number;
  max?: number;
}) => {
  const {
    getFieldState,
    control,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const [selectionValue, setSelectionValue] = useState<number[]>([0]);
  const { error } = getFieldState(componentProps.name);

  return (
    <div className="flex flex-col">
      {componentProps.label && <label htmlFor={componentProps.name}>{componentProps.label}</label>}
      <Controller
        control={control}
        name={componentProps.name}
        render={({ field: { onChange } }) => (
          <RangeSlider
            values={selectionValue}
            step={0.5}
            min={0}
            max={componentProps?.max ?? 15000}
            onChange={(values) => setSelectionValue(values)}
            onFinalChange={(values) => onChange(values)}
            renderTrack={({ props, children }) => (
              <div
                onMouseDown={props.onMouseDown}
                onTouchStart={props.onTouchStart}
                style={{
                  ...props.style,
                  height: '36px',
                  display: 'flex',
                  width: '100%',
                }}
              >
                <div
                  ref={props.ref}
                  style={{
                    height: '3px',
                    width: '100%',
                    borderRadius: '50px',
                    background: 'black',
                    alignSelf: 'center',
                  }}
                >
                  {children}
                </div>
              </div>
            )}
            renderThumb={({ props, isDragged }) => (
              <div
                {...props}
                key={props.key}
                style={{
                  ...props.style,
                  height: 'fit-content',
                  width: 'fit-content',
                  borderRadius: '50px',
                  backgroundColor: 'black',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '0px 2px 6px #AAA',
                }}
              >
                <div
                  style={{
                    padding: '5px 15px',
                    height: 'fit-content',
                    width: 'fit-content',
                    borderRadius: '50px',
                    backgroundColor: isDragged ? 'white' : 'black',
                    color: isDragged ? 'black' : 'white',
                    fontSize: 14,
                  }}
                >
                  ${new Intl.NumberFormat('en-US', {}).format(Number(selectionValue ?? 0))}
                </div>
              </div>
            )}
          />
        )}
      />

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
