/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { CSSProperties } from 'react';
import { useSnapCarousel } from 'react-snap-carousel';

const styles = {
  root: {},
  scroll: {
    position: 'relative',
    display: 'flex',
    overflow: 'auto',
    scrollSnapType: 'x mandatory',
  },
  item: {
    width: '325px',
    height: '200px',
    flexShrink: 0,
  },
  itemSnapPoint: {
    scrollSnapAlign: 'start',
  },
  controls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nextPrevButton: {},
  nextPrevButtonDisabled: { opacity: 0.3 },
  pagination: {
    display: 'flex',
  },
  paginationButton: {
    margin: '10px',
  },
  paginationButtonActive: { opacity: 0.3 },
  pageIndicator: {
    display: 'flex',
    justifyContent: 'center',
  },
} satisfies Record<string, CSSProperties>;

export interface CarouselSingleItemProps {
  id: number | string;
  label?: string;
  src?: string;
}

interface CarouselProps<T> {
  readonly items: T[];
  readonly renderItem: (props: CarouselRenderItemProps) => React.ReactElement<CarouselItemProps>;
  handleChoice: (item?: CarouselSingleItemProps) => void;
}

interface CarouselRenderItemProps {
  readonly item: CarouselSingleItemProps;
  readonly isSnapPoint: boolean;
  handleChoice: (item?: CarouselSingleItemProps) => void;
}

export const Carousel = <T extends CarouselSingleItemProps>({ items, renderItem, handleChoice }: CarouselProps<T>) => {
  const { scrollRef, snapPointIndexes } = useSnapCarousel();
  return (
    <div style={styles.root}>
      <ul style={styles.scroll} ref={scrollRef} className="flex gap-4">
        {items.map((item, i) =>
          renderItem({
            item,
            isSnapPoint: snapPointIndexes.has(i),
            handleChoice,
          }),
        )}
      </ul>
    </div>
  );
};

interface CarouselItemProps {
  readonly isSnapPoint: boolean;
  readonly children?: React.ReactNode;
  value: CarouselSingleItemProps;
  click: (item?: CarouselSingleItemProps) => void;
}

export const CarouselItem = ({ isSnapPoint, children, click, value }: CarouselItemProps) => (
  <li
    style={{
      ...styles.item,
      ...(isSnapPoint ? styles.itemSnapPoint : {}),
    }}
    className="rounded-2xl overflow-hidden relative cursor-pointer"
    onClick={() => click(value)}
  >
    {children}
  </li>
);

export const CategoriesCarousel = ({
  title,
  countries,
  handleChoice,
}: {
  title: string;
  countries: { id?: string | number; name: string; image: string }[];
  handleChoice: (item?: CarouselSingleItemProps) => void;
}) => {
  return (
    <section className="w-svw md:w-full">
      <h4 className="text-2xl font-bold mb-4">{title}</h4>
      <Carousel
        items={countries.map((country, i) => ({ id: country.id ?? i, label: country.name, src: country.image }))}
        handleChoice={handleChoice}
        renderItem={({ item, isSnapPoint, handleChoice }) => (
          <CarouselItem key={item.id} isSnapPoint={isSnapPoint} click={handleChoice} value={item}>
            <img
              src={item.src}
              alt={`${title} - carousel item`}
              width="326"
              height="200"
              className="absolute w-full h-full object-cover inset-0"
            />
            <div className="bg-dorrus-background-secondary/50 absolute inset-0 flex items-center justify-center text-white text-xl">
              {item.label}
            </div>
          </CarouselItem>
        )}
      />
    </section>
  );
};
