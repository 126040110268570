import type { Action, State } from './types';
import { initialValues } from './utils';

export function travellersReducer(state: State, action: Action) {
  switch (action.type) {
    case 'setData': {
      return {
        ...state,
        travellers: action.travellers,
        fetchingTravellers: true,
      };
    }
    case 'setFetching': {
      return {
        ...state,
        fetchingTravellers: action.fetching,
      };
    }
    case 'logout': {
      return { ...initialValues };
    }
    default: {
      throw new Error('Error - unknown action type');
    }
  }
}
