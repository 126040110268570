import { ComponentProps, ComponentType, ReactNode } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { UseFormRegisterReturn } from 'react-hook-form/dist/types/form';
import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { AnimatePresence, motion } from 'framer-motion';
import CheckIcon from '@app/assets/icons/check.svg?react';
import { isEqual } from 'lodash';
import { OptionType } from '@app/models/utils';

export type IHookedFieldComponent = UseFormRegisterReturn;

export const Select = <TComponent extends ComponentType<TProps>, TProps = ComponentProps<TComponent>>({
  ...componentProps
}: Partial<Omit<TProps, 'component'>> & {
  id?: string;
  placeholder?: string;
  name: string;
  type?: string;
  className?: string;
  triggerClassname?: string;
  validation?: { message: string; value: RegExp };
  label?: string;
  value?: string | number | OptionType;
  options?: { label: string; value: string | unknown }[];
  multiple?: boolean;
  icon?: ReactNode | JSX.Element;
  keepPlaceholder?: boolean;
}) => {
  const {
    getFieldState,
    control,
    watch,
    setValue,
    // @ts-expect-error this are need to update the input state on errors/etc
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
    formState: { errors, isDirty },
  } = useFormContext();

  const { error } = getFieldState(componentProps.name);
  const answer = watch(componentProps.name);

  const getDefaultValue = (defaultAnswer?: unknown) => {
    if (componentProps.multiple) {
      return answer;
    }
    if (typeof defaultAnswer === 'string') {
      const value = componentProps.options?.find((option) => option.value === defaultAnswer);
      if (value) {
        setValue(componentProps.name, value);
      }

      return defaultAnswer;
    }

    if (typeof defaultAnswer === 'object') {
      return defaultAnswer;
    }

    return '';
  };

  const getButtonLabel = () => {
    if (componentProps.keepPlaceholder && (answer?.length || answer?.value)) {
      return `${componentProps.placeholder} (${answer.length ? answer.length : 1})`;
    }

    if (componentProps.keepPlaceholder) {
      return componentProps.placeholder;
    }

    if (componentProps.multiple) {
      return `${componentProps.placeholder} (${answer?.length ?? 0})`;
    }

    if (answer?.label) {
      return answer.label;
    }

    if (answer) {
      const value = componentProps.options?.find((option) => option.value === answer);
      return value?.label ?? answer;
    }

    return componentProps.placeholder ?? 'Please choose';
  };

  return (
    <div className="relative flex flex-col">
      {componentProps.label && <label htmlFor={componentProps.name}>{componentProps.label}</label>}
      <Controller
        control={control}
        name={componentProps.name}
        render={({ field: { onChange, value } }) => (
          <Listbox
            by="value"
            value={value}
            defaultValue={getDefaultValue(value)}
            name={componentProps.name}
            onChange={(newValue) =>
              typeof newValue === 'object' && isEqual(newValue, value)
                ? onChange(undefined)
                : newValue === value
                  ? onChange(undefined)
                  : onChange(newValue)
            }
            multiple={componentProps.multiple}
          >
            {({ open }) => (
              <div className={componentProps.className}>
                <ListboxButton
                  {...(componentProps.id && { id: componentProps.id })}
                  className={`flex z-10 items-center gap-2 border relative  border-dorrus-button-border p-4 rounded-lg font-normal text-left min-w-fit w-full ${!value ? 'text-dorrus-text-secondary' : ''} ${componentProps.triggerClassname ?? ''}`}
                >
                  {componentProps.icon && componentProps.icon}
                  <span>{getButtonLabel()}</span>
                </ListboxButton>

                <AnimatePresence>
                  {open && (
                    <ListboxOptions
                      static
                      id="listbox-options"
                      as={motion.div}
                      initial={{ opacity: 0, scale: 0.95 }}
                      animate={{ opacity: 1, scale: 1 }}
                      exit={{ opacity: 0, scale: 0.95 }}
                      anchor="bottom start"
                      className="origin-top bg-white mt-2 rounded-xl shadow-md overflow-hidden min-w-fit w-[--button-width]   min-h-fit h-fit !max-h-[300px] overflow-y-scroll z-[9999]"
                    >
                      {componentProps.options?.map((option, index) => (
                        <ListboxOption
                          value={option}
                          className="text-base cursor-pointer select-none relative px-4 py-2 hover:bg-gray-200"
                          key={`${option.value ?? option.label}-${index}`}
                        >
                          {({ selected }) => {
                            return (
                              <div className="flex flex-row gap-2 justify-between">
                                <span className={`text-nowrap w-fit ${selected ? 'font-bold' : ''}`}>
                                  {option.label}
                                </span>
                                <span className="w-5">{selected && <CheckIcon width={20} />}</span>
                              </div>
                            );
                          }}
                        </ListboxOption>
                      ))}
                    </ListboxOptions>
                  )}
                </AnimatePresence>
              </div>
            )}
          </Listbox>
        )}
      />

      {error && <p className="font-semibold text-red-600">{error.message}</p>}
    </div>
  );
};
