import { useBooking } from '@app/context/booking';
import { useUser } from '@app/context/user';
import { postContactUs } from '@app/services/general';
import { contactUsValidation } from '@app/utils/validations';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

export const useContactUs = () => {
  const { user } = useUser();
  const formHook = useForm({
    defaultValues: {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phoneNumber: user?.phoneNumber,
    },
    resolver: contactUsValidation(Boolean(!user?.email)),
  });

  const { bookings, pastBookings } = useBooking();
  const { mutate, isPending } = useMutation({
    mutationKey: ['contact-us'],
    mutationFn: postContactUs,
    onSuccess: () => {
      toast.success('Message sent successfully');
      formHook.reset();
    },
    onError: () => {
      toast.error('Error while trying to send the message');
      toast.clearWaitingQueue();
    },
  });

  const submitContactUs = (values: FieldValues) => {
    mutate({
      sender: {
        name: `${values.firstName} ${values.lastName}`,
        handle: values.email,
      },
      subject: `${values.category?.label} - ${values?.orderNumber?.value ? `${values?.orderNumber?.value} -` : ''} ${values?.email ?? ''}`,
      body: `<ul style="padding: 0;">${values.category?.label ? `<li><b>Category</b>: ${values?.category?.label}</li>` : ''} 
            ${values?.orderNumber?.value ? `<li><b>Order ID</b>: ${values?.orderNumber?.value}</li>` : ''}
            ${values?.phoneNumber ? `<li><b>Phone Number</b>: ${values?.phoneNumber}</li>` : ''} 
            ${values?.email ? `<li><b>Email</b>: ${values?.email}</li>` : ''}</ul><br/>
            <p>${values.message}</p><br/><br/><small>Sent from Contact Us</small>`,
      metadata: {
        headers: {
          orderId: values?.orderNumber?.value,
          ...(values?.phoneNumber && { phoneNumber: values.phoneNumber }),
        },
      },
    });
  };

  useEffect(() => {
    if (user && user?.email) {
      formHook.setValue('firstName', user?.firstName ?? '');
      formHook.setValue('lastName', user?.lastName ?? '');
      formHook.setValue('email', user.email);
      formHook.setValue('phoneNumber', user.phoneNumber);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return {
    formHook,
    user,
    submitContactUs,
    bookings,
    pastBookings,
    isPending,
  };
};
